import axios from "axios";

export default {
  async saveTime(time, day) {
    return await axios.post("/panel/meetings/saveTime", {
      data: {
        time: time,
        day: day,
      },
    });
  },
  async reserveMeeting(payload) {
    return await axios.post("/meetings/reserve", payload);
  },
  async instructorTimePreview() {
    return await axios.get("/panel/meetings/");
  },
  async getReservations(from, to, day, status) {
    return await axios.get("/panel/meetings/reservations", {
      params: {
        from: from,
        to: to,
        day: day,
        status: status,
      },
    });
  },
  async getMeetingRequests(limit, offset) {
    return await axios.get("/panel/meetings/requests", {
      params: {
        limit: limit,
        offset: offset,
      },
    });
  },
  async sendDeleteTimeRequest(payload) {
    return await axios.post("/panel/meetings/deleteTime", payload);
  },

  async getInstructorMeetingList(id) {
    return await axios.get(`/panel/meetings/${id}/list`);
  },
  async saveUpdate(id, payload) {
    return await axios.post(`panel/meetings/${id}/update`, payload);
  },
  async addTime(payload) {
    return await axios.post(`/panel/meetings/saveTime`, payload);
  },
  async endMeeting(id) {
    return await axios.post(`/panel/meetings/${id}/finish`);
  },
  async sendMeetingLink(payload) {
    return await axios.post(`/panel/meetings/create-link`, payload);
  },
  async instructorAllowedMeeting(limit, offset, categories) {
    return await axios.get("/providers/consultations", {
      params: {
        limit: limit,
        offset: offset,
        categories: categories,
        sort: "random",
      },
    });
  },
  async getConsultationsHero() {
    return await axios.get("/providers/consultations-header", {
      params: { limit: 10, sort: "random" },
    });
  },
  async getConsultationsVideo() {
    return await axios.get("/image-video-section/consultation");
  },
  async getTestimonial(limit = 6) {
    return await axios.get("/testimonial", { params: { limit: limit } });
  },
  async getConsultationCategories() {
    return await axios.get("/consultation-categories");
  },
  async instructorCreateSessions(id, type = "agora") {
    return await axios.post(`/instructor/meetings/${id}/create-session`, {
      session_type: type,
    });
  },
  async instructorJoinSessions(id) {
    return await axios.post(`/panel/meetings/${id}/join`);
  },
  async updateReservingMeetingTime(id, payload) {
    return await axios.post(`/meetings/reserve/${id}/update`, payload);
  },
  async getTerms() {
    return await axios.get("/page/terms");
  },
  async saveToCalendar(courseId) {
    return await axios.get(`/courses/calendar-link/${courseId}`);
  },
};
