<template>
  <div>
    <div class="alert alert-success" v-if="successMessage">
      {{ successMessage }}
    </div>
    <form @submit.prevent="Reset" v-if="successMessage === ''">
      <div>
        <div v-for="(error, index) in errors" :key="index">
          <div
            class="text-danger"
            v-for="(_error, _index) in error"
            :key="_index"
          >
            {{ _error.toString() }}
          </div>
        </div>
        <div class="my-2 fw-bold">
          {{ form.email }}
        </div>

        <input-password-helper
          :label="'كلمة المرور الجديدة '"
          @input-data="setPassword"
        />
        <input-password-helper
          :label="'تكرار كلمة المرور'"
          @input-data="setRepeatPassword"
        />
        <button
          @click.prevent="handelResetPassword()"
          class="btn btn-coursestore d-flex align-items-center"
          type="submit"
        >
          <box-icon class="ms-2" color="#FFF" name="key"></box-icon>
          <span>تغيير كلمة المرور </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputPasswordHelper from "@/components/Helper/InputPasswordHelper";
import { mapActions } from "vuex";
export default {
  name: "ResetPasswordComponent",
  data() {
    return {
      token: "",
      errors: [],
      successMessage: "",
      form: { password: "", password_confirmation: "", email: "" },
    };
  },
  components: {
    InputPasswordHelper,
  },
  computed: {
    ...mapGetters("auth", ["isLogin"]),
  },
  created() {
    this.token = this.$route.params.token;
    this.form.email = this.$route.query.email;
  },
  methods: {
    ...mapActions("auth", ["doResetPassword"]),
    handelResetPassword() {
      this.$Progress.start();
      this.errors = [];
      this.doResetPassword({ token: this.token, form: this.form }).then(
        (res) => {
          if (res.status === 200 && res.data.success === false) {
            this.errors = res.data.data.errors;
          } else {
            this.successMessage = res.data.data;
          }
        }
      );
    },
    Reset() {
      console.log("token", this.token);
      console.log("password", this.form.password);
      console.log("password_confirmation", this.form.password_confirmation);
    },
    setRepeatPassword(value) {
      this.form.password_confirmation = value;
    },
    setPassword(value) {
      this.form.password = value;
    },
  },
  watch: {
    isLogin(newIsLogin) {
      if (newIsLogin === true) {
        //this.$router.replace({ name: "home" });
      }
    },
  },
};
</script>

<style>
.showPasswordBtn {
  top: -67px !important;
  left: 4px !important;
}
</style>
