<template>
  <div class="position-relative position-none">
    <a
      href="#"
      class="toggle-notifications position-relative mx-1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="badge" style="background-color: #1ebcc6">
        <small class="text-white">
          {{ list.count > 9 ? 9 + "+" : list.count }}
        </small>
      </span>
      <img
        class="svg"
        src="../../assets/icon/cs-bell.svg"
        alt="notifications"
      />
    </a>
    <div class="container">
      <div
        v-show="isLogin"
        class="dropdown-menu pb-0 notifications position-absolute"
        aria-labelledby="toggle-notifications"
      >
        <div class="container">
          <h5 class="pt-1 text-start notify-title-dropdown">الإشعارات</h5>
        </div>
        <hr class="mb-0" />
        <div class="notification-body p-2" v-if="list.count > 0">
          <div
            v-for="(notify, index) in list.notifications"
            :key="index"
            class="text-start mb-1"
          >
            <div class="border-bottom-1 border-bottom position-relative">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="my-1 h6 notify-title">{{ notify.title }}</h1>
                <small class="fs-10 notify-message">{{
                  notify.created_as_human
                }}</small>
              </div>

              <div class="mb-0 notify-message" v-html="notify.message"></div>
              <button
                @click.stop="deleteNotification(notify.id)"
                class="delete-notify"
              >
                <img src="../../assets/icon/cs-trash.svg" alt="delete" />
              </button>
            </div>
          </div>
        </div>
        <hr class="mb-0" />
        <div class="d-flex align-items-center">
          <router-link
            :to="{ path: '/panel/notifications' }"
            class="btn btn-coursestore-primary rounded-0 py-2 w-100 text-decoration-none d-flex justify-content-center btn-link-notify"
          >
            <span class="text-light"> تفقد جميع الإشعارات</span>
          </router-link>
          <button
            class="btn btn-danger rounded-0 py-2 w-100 text-decoration-none d-flex justify-content-center btn-link-notify"
          >
            <span class="text-light" @click.stop="deleteAllNotifications()">
              حذف جميع الإشعارات</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import notify_request from "@/api/notification_request";
export default {
  name: "NavNotification",
  data() {
    return {
      // overlayVisible: false,
    };
  },
  computed: {
    ...mapGetters("notification", ["list"]),
    ...mapGetters("auth", ["isLogin"]),
  },
  methods: {
    ...mapActions("notification", ["fetchNotifications", "delete"]),
    deleteAllNotifications() {
      notify_request.deleteAll().then((res) => {
        console.log(res);
      });
    },
    deleteNotification(id) {
      this.delete(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  position: absolute;
  top: -10px;
  left: -8px;
  border-radius: 50% !important;
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.badge small {
  display: block;
  margin: 2px 0;
}
.notification-body {
  max-height: 300px;
  overflow: auto;
}
.dropdown-menu::after {
  background-color: var(--nav-dropdown-bg) !important;
}
.notify-title {
  color: #8a8a8d;
}
.notify-message {
  font-size: 13px;
  color: #837e7e;
}
@media only screen and (max-width: 600px) {
  .notifications {
    max-width: 100%;
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
  .dropdown-menu.notifications {
    top: 95% !important;
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }
  .position-none {
    position: inherit !important;
  }
  .notifications::after {
    left: 15%;
  }
}
.notify-title-dropdown {
  font-size: 16px;
}
.notify-title {
  font-size: 14px;
}
.btn-link-notify {
  font-size: 12px;
}
.dark-theme .notify-title-dropdown {
  color: #fff;
}
.dark-theme .notify-message {
  color: #8a8a8d;
}
.dark-theme .notify-title {
  color: #ccc;
}
.delete-notify {
  position: absolute;
  left: 0;
  bottom: 2px;
  background: transparent;
  box-shadow: unset;
  img {
    width: 60% !important;
  }
}
</style>
