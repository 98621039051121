<template>
  <div class="certification-validation">
    <div
      class="header-validation-page"
      :style="{
        'background-image': `url(${config.pages_back_ground.certificate_validation})`,
      }"
    >
      <div class="title">
        <h2>التحقق من صلاحية الشهادة</h2>
      </div>
    </div>
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-6 col-md-10 mx-auto">
          <div class="d-flex justify-content-center flex-column">
            <h3 class="h4 text-light-dark-mode">صلاحية الشهادة</h3>
            <p>للتحقق من صلاحية الشهادة أدخل كود الشهادة واضغط علي تحقق</p>
            <form @submit.prevent="getCertificate()" class="py-4">
              <!-- <GlobalInput
                type="text"
                label=" معرف الشهادة"
                v-modal="certificate_id"
              /> -->
              <div class="input-form">
                <label
                  class="form-label text-light-dark-mode"
                  for="exampleInputEmail1"
                >
                  معرف الشهادة</label
                >
                <input
                  class="form-control"
                  type="text"
                  v-model="certificate_id"
                />
              </div>
              <div class="input-form">
                <div class="row">
                  <!-- <GlobalInput type="text" label=" التحقق " v-model="is_valid" /> -->
                  <!-- <label class="form-label" for="exampleInputEmail1">
                      التحقق</label
                    >
                    <input class="form-control" type="text" /> -->

                  <div class="col-6"></div>
                </div>
              </div>
              <button
                class="btn py-2 bg-secondary text-white btn-block w-100 text-light-dark-mode"
                type="submit"
              >
                <span> تحقق</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <VueModal
        v-model="showModal"
        modalStyle="max-width:400px;height: 150px; padding: 20px; border-radius: 5px"
        modalClass="play_course_popup show-certificate"
        title="هذه الشهادة صالحة"
      >
        <div class="d-flex flex-column align-items-center">
          <div
            class="d-flex justify-content-between text-center"
            v-if="userCertificate.file !== null"
          >
            <a
              style="margin-top: 20px"
              class="btn btn-secondary"
              :href="userCertificate.file"
              target="_blank"
            >
              عرض الشهادة
            </a>
          </div>
        </div>
      </VueModal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import certificate_request from "@/api/certificate_validation";
// import GlobalInput from "@/components/Helper/GlobalInput.vue";
import VueModal from "@kouts/vue-modal";
export default {
  name: "CertificateValidation",
  components: { VueModal },
  data() {
    return {
      certificate_id: "",
      userCertificate: {},
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("site", ["config"]),
  },
  methods: {
    async getCertificate() {
      await certificate_request
        .getCertificateId(this.certificate_id)
        .then((res) => {
          this.userCertificate = res.data.data.certificate;
          const isValid = res.data.data.is_valid;
          if (isValid == true) {
            this.showModal = true;
          } else {
            this.$swal({
              icon: "خطأ",
              title: "هذه الشهادة غير صالحة",
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.header-validation-page {
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: top; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.header-validation-page::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.header-validation-page .title h2 {
  color: #fff;
  position: relative;
  z-index: 22;
}
.vm {
  border-radius: 20px;
}
p {
  color: #818894;
}
.input-form {
  margin-bottom: 15px;
}
</style>
