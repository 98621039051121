import course_note_request from "@/api/course_note_request";

export default {
  namespaced: true,
  state: () => ({
    notes_list: [],
    notes_offset: 0,
    loading_notes: true,
  }),
  getters: {
    notes_list: (state) => {
      return state.notes_list;
    },
    notes_offset: (state) => {
      return state.notes_offset;
    },
    loading_notes: (state) => {
      return state.loading_notes;
    },
  },
  mutations: {
    SET_NOTES_LIST: (state, payload) => {
      state.notes_list.push(payload);
    },

    SET_NOTES_OFFSET: (state, payload) => {
      state.notes_offset = payload;
    },
    SET_LOADING_NOTES: (state, payload) => {
      state.loading_notes = payload;
    },
  },
  actions: {
    async getAllNotes({ commit }, payload) {
      commit("SET_LOADING_NOTES", true);
      await course_note_request
        .getNotesList(payload)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            // set sales details
            let list = response.data.data;
            list.forEach((item) => {
              commit("SET_NOTES_LIST", item);
            });
          }
        })
        .finally(() => {
          commit("SET_LOADING_NOTES", false);
        });
    },
    /*eslint no-unused-vars: ["error", { "vars": "local" }]*/
    /*global some_unused_var */
    async createNewNote(_, payload) {
      return await course_note_request.addNote(payload);
    },
    resetNotes({ state }) {
      if (Object.keys(state.notes_list).length > 0) {
        state.notes_list = [];
      }
    },
  },
};
