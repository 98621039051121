<template>
  <!-- Login Modal -->
  <div
    class="modal course-signup"
    @click.prevent="showModal = false"
    v-show="showModal"
    tabindex="0"
    :aria-hidden="!showModal"
    :style="{ display: showModal ? 'none' : 'block' }"
  >
    <div class="modal-dialog modal-xl" @click.stop>
      <div class="modal-content">
        <div
          class="modal-body"
          :class="[bgClass, 'position-relative', 'signModalBg']"
        >
          <svg
            @click.prevent="showModal = false"
            class="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            data-bs-dismiss="modal"
            height="24"
            viewBox="0 0 24 24"
            style="fill: #fff"
          >
            <path
              d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
            ></path>
          </svg>
          <div class="signModal">
            <div class="sign-in-up-text">
              <h1 class="ms-3 text-white">تابع شغفك</h1>
              <p class="fs-5 ms-3 text-white">
                تمتع بمشاهدة اكثر من 100 كورس تعليمي في شتى المجالات و التخصصات
              </p>
            </div>

            <div class="form" style="color: #fff !important">
              <div v-show="showForm === 'forget_password'">
                <div class="text-center mb-3">
                  <h5 class="text-white" style="color: #fff">
                    نسيت كلمة المرور
                  </h5>
                  <div>
                    <span class="text-white">لدي حساب بالفعل ؟</span>
                    <a
                      @click.prevent="changeTab('login')"
                      class="text-decoration-none text-white cursor-pointer"
                      style="color: #fff"
                    >
                      تسجيل الدخول</a
                    >
                  </div>
                </div>

                <forget-password-component> </forget-password-component>
              </div>
              <div v-show="showForm === 'login' || showForm === ''">
                <div class="text-center mb-3">
                  <h5>تسجيل الدخول</h5>
                  <span>لا تملك حساب؟</span>
                  <a
                    class="text-decoration-none cursor-pointer"
                    @click.prevent="changeTab('register')"
                  >
                    إنشاء حساب
                  </a>
                </div>
                <div class="login-gmail text-center">
                  <a
                    @click.prevent="loginWithGoogle()"
                    class="cursor-pointer btn bg-white w-100"
                  >
                    <svg
                      enable-background="new 0 0 24 24"
                      id="googleicon"
                      version="1.1"
                      viewBox="0 0 24 24"
                      class="mx-1"
                      xml:space="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g>
                        <path
                          d="M12,5c1.6167603,0,3.1012573,0.5535278,4.2863159,1.4740601l3.637146-3.4699707   C17.8087769,1.1399536,15.0406494,0,12,0C7.392395,0,3.3966675,2.5999146,1.3858032,6.4098511l4.0444336,3.1929321   C6.4099731,6.9193726,8.977478,5,12,5z"
                          fill="#F44336"
                        />
                        <path
                          d="M23.8960571,13.5018311C23.9585571,13.0101929,24,12.508667,24,12   c0-0.8578491-0.093689-1.6931763-0.2647705-2.5H12v5h6.4862061c-0.5247192,1.3637695-1.4589844,2.5177612-2.6481934,3.319458   l4.0594482,3.204834C22.0493774,19.135437,23.5219727,16.4903564,23.8960571,13.5018311z"
                          fill="#2196F3"
                        />
                        <path
                          d="M5,12c0-0.8434448,0.1568604-1.6483765,0.4302368-2.3972168L1.3858032,6.4098511   C0.5043335,8.0800171,0,9.9801636,0,12c0,1.9972534,0.4950562,3.8763428,1.3582153,5.532959l4.0495605-3.1970215   C5.1484375,13.6044312,5,12.8204346,5,12z"
                          fill="#FFC107"
                        />
                        <path
                          d="M12,19c-3.0455322,0-5.6295776-1.9484863-6.5922241-4.6640625L1.3582153,17.532959   C3.3592529,21.3734741,7.369812,24,12,24c3.027771,0,5.7887573-1.1248169,7.8974609-2.975708l-4.0594482-3.204834   C14.7412109,18.5588989,13.4284058,19,12,19z"
                          fill="#00B060"
                        />
                        <path
                          d="M12,23.75c-3.5316772,0-6.7072754-1.4571533-8.9524536-3.7786865C5.2453613,22.4378052,8.4364624,24,12,24   c3.5305786,0,6.6952515-1.5313721,8.8881226-3.9592285C18.6495972,22.324646,15.4981079,23.75,12,23.75z"
                          opacity="0.1"
                        />
                        <polygon
                          opacity="0.1"
                          points="12,14.25 12,14.5 18.4862061,14.5 18.587492,14.25  "
                        />
                        <path
                          d="M23.9944458,12.1470337C23.9952393,12.0977783,24,12.0493774,24,12   c0-0.0139771-0.0021973-0.0274658-0.0022583-0.0414429C23.9970703,12.0215454,23.9938965,12.0838013,23.9944458,12.1470337z"
                          fill="#E6E6E6"
                        />
                        <path
                          d="M12,9.5v0.25h11.7855721c-0.0157471-0.0825195-0.0329475-0.1680908-0.0503426-0.25H12z"
                          fill="#FFFFFF"
                          opacity="0.2"
                        />
                        <linearGradient
                          gradientUnits="userSpaceOnUse"
                          id="SVGID_1_"
                          x1="0"
                          x2="24"
                          y1="12"
                          y2="12"
                        >
                          <stop
                            offset="0"
                            style="stop-color: #ffffff; stop-opacity: 0.2"
                          />
                          <stop
                            offset="1"
                            style="stop-color: #ffffff; stop-opacity: 0"
                          />
                        </linearGradient>
                        <path
                          d="M23.7352295,9.5H12v5h6.4862061C17.4775391,17.121582,14.9771729,19,12,19   c-3.8659668,0-7-3.1340332-7-7c0-3.8660278,3.1340332-7,7-7c1.4018555,0,2.6939087,0.4306641,3.7885132,1.140686   c0.1675415,0.1088867,0.3403931,0.2111206,0.4978027,0.333374l3.637146-3.4699707L19.8414307,2.940979   C17.7369385,1.1170654,15.00354,0,12,0C5.3725586,0,0,5.3725586,0,12c0,6.6273804,5.3725586,12,12,12   c6.1176758,0,11.1554565-4.5812378,11.8960571-10.4981689C23.9585571,13.0101929,24,12.508667,24,12   C24,11.1421509,23.906311,10.3068237,23.7352295,9.5z"
                          fill="url(#SVGID_1_)"
                        />
                        <path
                          d="M15.7885132,5.890686C14.6939087,5.1806641,13.4018555,4.75,12,4.75c-3.8659668,0-7,3.1339722-7,7   c0,0.0421753,0.0005674,0.0751343,0.0012999,0.1171875C5.0687437,8.0595093,8.1762085,5,12,5   c1.4018555,0,2.6939087,0.4306641,3.7885132,1.140686c0.1675415,0.1088867,0.3403931,0.2111206,0.4978027,0.333374   l3.637146-3.4699707l-3.637146,3.2199707C16.1289062,6.1018066,15.9560547,5.9995728,15.7885132,5.890686z"
                          opacity="0.1"
                        />
                        <path
                          d="M12,0.25c2.9750366,0,5.6829224,1.0983887,7.7792969,2.8916016l0.144165-0.1375122   l-0.110014-0.0958166C17.7089558,1.0843592,15.00354,0,12,0C5.3725586,0,0,5.3725586,0,12   c0,0.0421753,0.0058594,0.0828857,0.0062866,0.125C0.0740356,5.5558472,5.4147339,0.25,12,0.25z"
                          fill="#FFFFFF"
                          opacity="0.2"
                        />
                      </g>
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                    </svg>
                    <span class="fs-12">تسجيل الدخول بحساب جوجل</span>
                  </a>
                </div>
                <div class="login-fb text-center mt-2 mb-5">
                  <a
                    @click.prevent="loginWithFacebook()"
                    class="btn w-100 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="fill: #fff"
                    >
                      <path
                        d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                      ></path>
                    </svg>
                    <span class="fs-12">تسجيل الدخول بحساب فيس بوك</span></a
                  >
                </div>
                <display-error-helper :errors="input_errors" />
                <div>
                  <GlobalInput
                    type="email"
                    label="البريد الإلكتروني"
                    v-model="form.username"
                  />
                </div>
                <div class="mt-4 position-relative">
                  <GlobalInput
                    :type="fieldType"
                    label="كلمة المرور"
                    v-model="form.password"
                  />
                  <button @click.prevent="showPassword()" class="show_icon">
                    <box-icon v-if="is_show_eye" name="hide"></box-icon>
                    <box-icon v-else name="show"></box-icon>
                  </button>
                  <a
                    class="text-decoration-none cursor-pointer"
                    @click.prevent="changeTab('forget_password')"
                    >نسيت كلمة المرور؟</a
                  >
                </div>
                <div class="form-group text-center">
                  <button
                    @click.prevent="handelLogin()"
                    @keyup.enter="handelLogin()"
                    type="button"
                    class="my-2 btn text-white bg-secondary btn-block w-100"
                  >
                    تسجيل الدخول
                  </button>
                </div>
              </div>
              <div v-show="showForm === 'register'">
                <div class="text-center mb-1">
                  <h5>إنشاء حساب</h5>
                  <div>
                    <span>لدي حساب بالفعل ؟</span>
                    <a
                      @click.prevent="changeTab('login')"
                      class="text-decoration-none cursor-pointer"
                      >تسجيل الدخول</a
                    >
                  </div>
                </div>
                <register-component></register-component>
              </div>

              <div class="d-flex justify-content-between mt-4">
                <a class="text-decoration-none cursor-pointer">
                  <page-link title="سياسة الخصوصية" slug="privacy"></page-link>
                </a>

                <a class="text-decoration-none cursor-pointer">
                  <page-link title=" الشروط والأحكام" slug="terms"></page-link>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueModal
      v-model="showFieldModal"
      modalStyle="max-width:1090px;height: 500px; padding: 0"
      modalClass="preview-field-modal"
      :enable-close="enableClose"
    >
      <div
        class="parent d-flex justify-content-end position-absolute bottom-10"
      >
        <div class="sign-in-up-text text-center">
          <h1 class="ms-3 text-white">تابع شغفك</h1>
          <p class="fs-5 ms-3 text-white">واختر المجالات المناسبة لك</p>
        </div>
        <div class="w-75">
          <div class="categories">
            <div class="d-flex flex-wrap gap-3 reset-margin">
              <div
                class="categories-child-box"
                v-for="(category, index) in categories"
                :key="index"
              >
                <label class="category-select">
                  <input
                    type="checkbox"
                    v-model="selectedCategories"
                    :value="category.id"
                  />
                  <div class="category-box">
                    <img :src="category.image" :alt="category.title" />
                    <p>{{ category.title }}</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <button @click="closeModals()" class="escape">تخطي</button>
            <button @click="saveCategories" class="continue">متابعة</button>
          </div>
        </div>
      </div>
    </VueModal>
    <VueModal
      v-model="showInstructorModal"
      modalStyle="max-width:900px;height: 500px; padding: 0"
      modalClass="preview-field-instructor-modal"
      :enable-close="enableClose"
    >
      <div class="parent row align-items-center position-absolute bottom-10">
        <div class="col-md-6">
          <div class="sign-in-up-text text-center">
            <h1 class="ms-3 text-white">تابع شغفك</h1>
            <p class="fs-5 ms-3 text-white">وتابع المدرب المفضل لديك</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="instructor-bg">
            <div class="follow-instructor">
              <div
                class="instructor"
                v-for="(instructor_item, index) in instructor_list"
                :key="index"
              >
                <div class="instructor-info">
                  <img :src="instructor_item.avatar" alt="image-instructor" />
                  <div class="instructor-des">
                    <p class="name">{{ instructor_item.full_name }}</p>
                    <p class="bio">{{ instructor_item.bio }}</p>
                  </div>
                </div>
                <div class="follow-btn">
                  <follow-button-component
                    :is_follow="instructor_item.auth_user_is_follower"
                    :id="instructor_item.id"
                  ></follow-button-component>
                </div>
              </div>
              <div class="text-center">
                <button
                  v-if="links < lastpage"
                  @click="loadMore"
                  class="btn btn-coursestore-primary btn-padding more-effect py-2 px-3"
                >
                  المزيد
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <button @click="closeModals()" class="escape">تخطي</button>
            <button @click="showInstructors()" class="continue">متابعة</button>
          </div>
        </div>
      </div>
    </VueModal>
    <VueModal
      v-model="showDataModal"
      modalStyle="max-width:900px;height: 500px; padding: 0"
      modalClass="preview-data-inputs-modal"
      :enable-close="enableClose"
    >
      <div v-if="errors.length > 0" class="alert alert-danger">
        <ul>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>
      <div class="parent row align-items-center position-absolute bottom-10">
        <div class="col-md-5">
          <div class="sign-in-up-text text-center">
            <h1 class="ms-3 text-white">تابع شغفك</h1>
            <p class="fs-5 ms-3 text-white">وأحصل على ما يخدم إهتماماتك</p>
          </div>
        </div>
        <div class="col-md-7">
          <form>
            <vue-tel-input
              v-model="formData.phone"
              v-on:country-changed="countryChanged"
              :autoFormat="false"
              :preferredCountries="['eg']"
              placeholder="رقم الهاتف"
              :inputOptions="inputOptions"
            ></vue-tel-input>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="nationality">الجنسية</label>
                  <b-form-select
                    class="form-control day"
                    v-model="formData.country_id"
                  >
                    <option v-for="country in countries" :key="country.id">
                      {{ country.name }}
                    </option>
                  </b-form-select>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label for="الإقامة/العنوان">الإقامة/العنوان</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    class="form-control"
                    placeholder=""
                    aria-describedby="helpId"
                    v-model="formData.address"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex align-items-end">
              <div class="form-check">
                <p>النوع</p>
                <label class="form-check-label button-checkbox">
                  <input
                    type="radio"
                    class="form-check-input visually-hidden"
                    name="gender"
                    id="male"
                    value="male"
                    v-model="formData.gender"
                  />
                  <span class="button-label">ذكر</span>
                </label>
                <span class="mx-2"></span>
                <label class="form-check-label button-checkbox">
                  <input
                    type="radio"
                    class="form-check-input visually-hidden"
                    name="gender"
                    id="female"
                    value="female"
                    v-model="formData.gender"
                  />
                  <span class="button-label">انثى</span>
                </label>
              </div>
              <div class="form-check">
                <div class="day">
                  <div class="form-group">
                    <p>تاريخ الميلاد</p>
                    <b-form-select
                      class="form-control day"
                      v-model="birthday.selectedDay"
                    >
                      <option v-for="day in days" :key="day" :value="day">
                        {{ day }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <!-- <input type="text" class="on-day" /> -->
              </div>
              <div class="form-check">
                <div class="day">
                  <div class="form-group">
                    <b-form-select
                      class="form-control day"
                      v-model="birthday.selectedMonth"
                    >
                      <option
                        v-for="(month, index) in months"
                        :key="index"
                        :value="index + 1"
                      >
                        {{ month }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <!-- <input type="text" class="on-day" /> -->
              </div>
              <div class="form-check">
                <div class="day">
                  <div class="form-group">
                    <!-- year -->
                    <b-form-select
                      class="form-control day"
                      v-model="birthday.selectedYear"
                    >
                      <option v-for="year in years" :key="year" :value="year">
                        {{ year }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-white">{{ date }}</div>
          </form>
          <div class="d-flex gap-2">
            <button @click="closeModals()" class="escape">تخطي</button>
            <button @click="submitForm()" class="continue">متابعة</button>
          </div>
        </div>
      </div>
    </VueModal>
  </div>
</template>

<script>
import FollowButtonComponent from "@/components/Instructors/FollowButtonComponent";
import RegisterComponent from "@/components/Auth/RegisterComponent";
import ForgetPasswordComponent from "@/components/Auth/ForgetPasswordComponent";
import DisplayErrorHelper from "@/components/Helper/DisplayErrorHelper";
import appConfig from "@/config";
import { mapActions, mapGetters } from "vuex";
import Auth from "@/helpers/auth_helper";
import PageLink from "@/components/Page/PageLink";
import authRequest from "@/api/auth_request";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import VueModal from "@kouts/vue-modal";

import axios from "axios";
export default {
  name: "SignInModal",
  components: {
    PageLink,
    RegisterComponent,
    ForgetPasswordComponent,
    DisplayErrorHelper,
    GlobalInput,
    VueModal,
    FollowButtonComponent,
  },
  props: {
    isShow: { type: Boolean, default: false },
  },
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const endYear = currentYear + 10;
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    const months = [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    return {
      years,
      months,
      days,
      showFieldModal: false,
      showInstructorModal: false,
      showDataModal: false,
      enableClose: false,
      showModal: this.isShow,
      showForm: "",
      form: {
        username: appConfig.USERNAME,
        password: appConfig.PASSWORD,
      },
      input_errors: [],
      errorMessage: "",
      bgClass: "login",
      fieldType: "password",
      is_show_eye: false,
      selectedCategories: [],
      errors: [],
      formData: {
        phone: "",
        country_id: "",
        address: "",
        gender: "",
      },
      birthday: {
        selectedDay: null,
        selectedMonth: null,
        selectedYear: null,
      },
      inputOptions: {
        placeholder: "رقم الهاتف",
        name: "phone",
      },
    };
  },
  computed: {
    ...mapGetters("instructors", [
      "instructor_list",
      "links",
      "lastpage",
      "isLoadData",
      "category",
    ]),
    ...mapGetters("auth", ["isLogin", "user", "userProgress", "countries"]),
    ...mapGetters("home", ["categories"]),
    selectedCategoryIds() {
      return this.selectedCategories.map((id) => id.toString()).join(",");
    },
    date() {
      const year = this.birthday.selectedYear?.toString().padStart(4, "0");
      const month = this.birthday.selectedMonth?.toString().padStart(2, "0");
      const day = this.birthday.selectedDay?.toString().padStart(2, "0");
      if (year && month && day) {
        return `${year}-${month}-${day}`;
      }
      return null;
    },
  },
  mounted() {
    const _this = this;
    this.$root.$on("switchNavbarSignTab", function (tab) {
      _this.showForm = tab;
    });
    this.$root.$on("showAuthModel", function (value) {
      _this.showModal = value;
    });
  },

  created() {
    this.getCountries();
    this.getAllInstructor({
      page: this.links,
      last_page: this.lastpage,
      category: [],
    });
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      (async () => {
        await this.waitForUserData();
      })();
    }
  },
  methods: {
    ...mapActions("auth", ["login", "updateProfile", "getCountries"]),
    ...mapActions("instructors", ["getAllInstructor"]),
    ...mapActions("home", ["getCategories"]),
    async waitForUserData() {
      while (!this.userProgress || this.userProgress.progress === undefined) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      if (this.userProgress.progress !== 100) {
        this.showFieldModal = true;
        localStorage.removeItem("isLoggedIn");
      } else {
        localStorage.removeItem("isLoggedIn");
      }
    },
    loadMore() {
      this.getAllInstructor({
        page: this.links,
        last_page: this.lastpage,
        category: [],
      });
    },
    async saveCategories() {
      try {
        await axios.post("/panel/profile-setting/categories", {
          categories: this.selectedCategories,
        });
        this.showField();
      } catch (error) {
        console.error(error); // handle error response
      }
    },
    countryChanged(country) {
      this.form.country_code = country.dialCode;
      this.form.country_iso = country.iso2;
    },
    showField() {
      this.showFieldModal = false;
      this.showInstructorModal = true;
    },
    showInstructors() {
      this.showDataModal = true;
      this.showInstructorModal = false;
    },
    showPassword() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
      this.is_show_eye = !this.is_show_eye;
    },
    async submitForm() {
      this.isLoading = true;
      const date = this.date;
      const payload = {
        UserData: this.formData,
        date: date,
      };
      try {
        const response = await this.updateProfile(payload);
        if (response.status === 200) {
          if (response.data.success === false) {
            if (response.data.status === "validation_error") {
              let _errors = response.data.data.errors;
              Object.values(_errors).forEach((val) => {
                val.forEach((error) => {
                  this.errors.push(error);
                });
              });
            }
          } else if (response.data.success === true) {
            this.$swal.fire({
              icon: "",
              title: response.data.message,
            });
            // this.$swal.fire(response.data.message);
            this.showDataModal = false;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    closeModals() {
      this.showFieldModal = false;
      this.showInstructorModal = false;
      this.showDataModal = false;
      // location.reload();
    },
    handelLogin() {
      this.input_errors = [];
      this.errorMessage = "";
      this.$Progress.start();
      this.login(this.form)
        .then((responses) => {
          const { status, data, response } = responses;
          if (status === 200) {
            if (data.success === true) {
              Auth.setAUthData(data.data.token);
              localStorage.setItem("isLoggedIn", "true");
              window.location.reload();
            } else if (data.success === false) {
              this.input_errors.push(" " + data.message);
            }
          } else {
            this.input_errors.push(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    setPassword(value) {
      this.form.password = value;
    },
    changeTab(value) {
      this.showForm = value;
      this.bgClass = value;
    },
    goToPage(link) {
      if (this.$route.path !== "/page/" + link) {
        this.$router.replace({ name: "page", params: { link: link } });
      }
      this.$root.$emit("showAuthModel", false);
    },
    loginWithFacebook() {
      this.$Progress.start();
      authRequest.handelLoginWithFacebook().then((response) => {
        location.href = response.data;
      });
    },
    async loginWithGoogle() {
      this.$Progress.start();
      await authRequest.handelLoginWithGoogle().then((response) => {
        location.href = response.data;
      });
    },
  },
  watch: {
    isLogin(newIsLogin) {
      if (newIsLogin === true) {
        // this.$router.replace({ name: "home" });
      }
    },
    isShow: function () {
      // watch it
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.categories-child-box {
  width: 25%;
  @media (max-width: 768px) {
    width: 45%;
  }
}
.signModal {
  display: flex !important;
  align-items: center;
}
.sign-in-up-text {
  width: 50%;
  color: #fff !important;
}
.form {
  width: 50%;
}
@media (max-width: 1024px) {
  .sign-in-up-text {
    display: none !important;
  }
  .form {
    width: 100%;
  }
}
.login-gmail a:hover {
  background-color: #ccc !important;
  color: var(--bs-white) !important;
}
.course-signup .login-fb a:hover {
  background-color: #597dc7 !important;
  color: var(--bs-white) !important;
}
input[type="password"] {
  font-family: Helvetica, Arial, sans-serif;
}

.signModalBg:after {
  z-index: -1;
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-size: cover;
}
.login:after {
  background-image: url("../../assets/images/auth/login-bg.jpg");
}
.register:after {
  background-image: url("../../assets/images/auth/login-bg.jpg");
}
.forget_password:after {
  background-image: url("../../assets/images/auth/forget_password-bg.jpg");
}
.show_icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 43px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  border: 1px solid #aaa;
  color: #fff;
}
@media (max-width: 768px) {
  .show_icon {
    height: 30px;
  }
}
// //////////////////////
.bottom-10 {
  // bottom: 10px;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.preview-field-modal {
  background: url("../../assets/modal/2.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: 10px;
}
.preview-field-modal::after {
  content: "";
  position: absolute;
  inset: 0;
  background: transparent linear-gradient(124deg, #000000 0%, #00000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 0.91;
}
.preview-field-modal .vm-titlebar,
.preview-data-inputs-modal .vm-titlebar,
.preview-field-instructor-modal .vm-titlebar {
  display: none;
}
.parent {
  padding-left: 50px;
  z-index: 2;
  .sign-in-up-text {
    width: 100% !important;
  }
}
.reset-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.categories {
  background-image: linear-gradient(to left, #6b5f53, #fff);
  border-radius: 8px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  height: 350px;
  padding: 10px;
  margin: auto;
  overflow-y: auto;
  width: 100%;
  .category-box {
    height: 80px;
    text-align: center;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    p {
      margin-top: 20px;
      margin-bottom: 0;
      color: #fff;
      font-size: 12px;
    }
  }
}
.continue {
  background: #1ecad3 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  padding: 8px 50px;
  margin-top: 20px;
}
.escape {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #000;
  padding: 8px 50px;
  margin-top: 20px;
}
// Select div
.category-select {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.category-select input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.category-select .category-box {
  border: 2px solid #1ecad3;
  border-radius: 10px;
  padding: 5px;
}

.category-select input[type="checkbox"]:checked + .category-box {
  background-color: #1ecad3;
}
// Start Instaructor tab
.preview-field-instructor-modal {
  background: url("../../assets/modal/1.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: 10px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: transparent linear-gradient(124deg, #000000 0%, #00000000 100%)
      0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 0.91;
  }
  .sign-in-up-text {
    width: 100% !important;
  }
}
.instructor-bg {
  background-image: linear-gradient(to lef, #384e40, #fff);
  border-radius: 8px;
  // width: 75%;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  height: 350px;
  overflow: auto;
  padding: 10px;

  .instructor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 5px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    .instructor-info {
      display: flex;
      gap: 10px;
    }
    img {
      width: 75px;
    }
    .instructor-des {
      padding: 5px 0;
      color: #471190;
      .name {
        font-size: 14px;
      }
      .bio {
        font-size: 11px;
      }
    }
  }
}

.preview-data-inputs-modal {
  background: url("../../assets/modal/3.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: 10px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: transparent linear-gradient(124deg, #000000 0%, #00000000 100%)
      0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 0.91;
  }
  .sign-in-up-text {
    width: 100% !important;
  }
  input,
  select {
    width: 100%;
    outline: none;
    border: none;
    padding: 3px 10px;
    border-radius: 3px;
  }
  .form-check {
    margin-top: 10px;
    padding-right: 0;
  }
  .button-checkbox input:checked + .button-label {
    background-color: #1ecad3;
    color: #fff;
  }

  .button-checkbox input:not(:checked) + .button-label {
    background-color: #fff;
    color: #000;
  }

  .button-label,
  .day {
    display: inline-block;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  label {
    color: #fff;
    margin-bottom: 10px;
  }
  p {
    color: #fff;
  }
  .vue-tel-input {
    margin-bottom: 20px;
  }
}
</style>
