<template>
  <div :class="['progress-user', size]">
    <svg
      :stroke-width="backWidth"
      class="progress__back"
      viewBox="0 0 100 100"
      :stroke="backColor"
      :fill="innerColor"
    >
      <circle cx="50" cy="50" r="45"></circle>
    </svg>
    <svg
      :stroke-linecap="rounded ? 'round' : ''"
      :stroke-width="frontWidth"
      :stroke-dashoffset="calcValue"
      :stroke="frontColor"
      class="progress__front"
      viewBox="0 0 100 100"
    >
      <circle cx="50" cy="50" r="45"></circle>
    </svg>
    <span :style="{ color: textColor }" class="progress__value"
      >{{ value.toFixed() }}%</span
    >
  </div>
</template>
<script>
export default {
  name: "CircularProgressBar",
  props: {
    value: {
      type: Number,
      default: 0,
      validator(value) {
        return value >= 0 && value <= 100;
      },
    },
    frontColor: {
      type: String,
      default: "#1EBCC6",
    },
    frontWidth: { type: Number, default: 8 },
    backColor: {
      type: String,
      default: "#773DBD",
    },
    backWidth: { type: Number, default: 8 },
    rounded: { type: Boolean, default: false },
    textColor: { type: String, default: "#737373" },
    innerColor: { type: String, default: "none" },
    size: {
      type: String,
      default: "medium",
      validator(value) {
        return ["small", "medium", "large"].includes(value);
      },
    },
  },
  computed: {
    calcValue() {
      return 283 - (283 * this.value) / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  width: 50px;
  height: 3rem;
  font-size: 0.7rem;
}

.medium {
  width: 7rem;
  height: 7rem;
  font-size: 1.6rem;
}

.large {
  width: 9rem;
  height: 9rem;
  font-size: 1.8rem;
}

.progress-user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: inherit;
    width: inherit;
  }

  .progress__back {
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress__front {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
    circle {
      fill: #fff;
      stroke-dasharray: 283;
    }
  }

  .progress__value {
    position: relative;
    font-size: inherit;
    font-weight: bold;
    pointer-events: none;
    z-index: 2;
    color: #000 !important;
  }
}
.dark-theme .progress__back {
  stroke: #ffffff !important;
}
.dark-theme .progress__front {
  stroke: #1a4b77 !important;
}
</style>
