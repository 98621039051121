<template>
  <div>
    <update-profile-component />
  </div>
</template>

<script>
import UpdateProfileComponent from "@/components/Auth/UpdateProfileComponent";

export default {
  name: "UpdateProfileView",
  components: { UpdateProfileComponent },
};
</script>

<style scoped></style>
