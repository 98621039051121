import PanelView from "@/views/Profile/PanelView";
import UserProfilePurchases from "@/views/Profile/UserProfilePurchases";
import ProfileIndexView from "@/views/Profile/ProfileIndexView";
import ProfileConsult from "@/views/Profile/ProfileConsult.vue";
import certificatePage from "@/views/Profile/CertificatePage.vue";
import HelpAndSupport from "@/views/Profile/HelpAndSupport.vue";
import UserProfileNotifications from "@/views/Profile/UserProfileNotifications";
import UserProfileUpdate from "@/views/Profile/UserProfileUpdate";
import SendWebinarsGift from "@/views/Gift/SendWebinarsGift";
import BundlesGift from "@/views/Gift/BundlesGift";
import MeetingsGift from "@/views/Gift/MeetingsGift.vue";
import UploadCourses from "@/views/Profile/UploadCourses";
import UserProfileFinancialSales from "@/views/Profile/UserProfileFinancialSales";
import subscriptions from "@/views/Subscription/SubscriptionPanel.vue";

export default [
  {
    path: "/panel",
    name: "panel",
    component: PanelView,
    children: [
      {
        path: "/panel",
        name: "panel",
        components: {
          default: PanelView,
          user_content: ProfileIndexView,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "purchases",
        name: "purchases",
        components: {
          default: PanelView,
          user_content: UserProfilePurchases,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "consultant",
        name: "Consultant",
        components: {
          default: PanelView,
          user_content: ProfileConsult,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "certificate",
        name: "certificate",
        components: {
          default: PanelView,
          user_content: certificatePage,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "subscriptions",
        name: "subscriptions",
        components: {
          default: PanelView,
          user_content: subscriptions,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "support",
        name: "support",
        components: {
          default: PanelView,
          user_content: HelpAndSupport,
        },
        meta: {
          auth: true,
        },
      },
      // {
      //   path: "favorites",
      //   name: "favorites",
      //   components: {
      //     default: PanelView,
      //     user_content: UserProfileFavorites,
      //   },
      //   meta: {
      //     auth: true,
      //   },
      // },
      {
        path: "notifications",
        name: "notifications",
        components: {
          default: PanelView,
          user_content: UserProfileNotifications,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "upload-courses",
        name: "UploadCourses",
        components: {
          default: PanelView,
          user_content: UploadCourses,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "financial",
        name: "financial",
        components: {
          default: PanelView,
          user_content: UserProfileFinancialSales,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "account-settings",
        name: "account-settings",
        components: {
          default: PanelView,
          user_content: UserProfileUpdate,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "webinars-gift",
        name: "WebinarGifts",
        components: {
          default: PanelView,
          user_content: SendWebinarsGift,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "bundles-gift",
        name: "BundlesGift",
        components: {
          default: PanelView,
          user_content: BundlesGift,
        },
        meta: {
          auth: true,
        },
      },
      {
        path: "meetings-gift",
        name: "MeetingGifts",
        components: {
          default: PanelView,
          user_content: MeetingsGift,
        },
        meta: {
          auth: true,
        },
      },
      // {
      //   path: "account-settings",
      //   name: "account-settings",
      //   components: {
      //     default: PanelView,
      //     user_content: UserProfileAccountSettings,
      //   },
      //   meta: {
      //     auth: true,
      //   },
      // },
    ],
    meta: {
      auth: true,
    },
  },
];
