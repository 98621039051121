<template>
  <div>
    <small-spring-loading
      :is-loading="loadSendGiftMeetings"
    ></small-spring-loading>
    <small-spring-loading
      :is-loading="loadReceivedGiftMeeting"
    ></small-spring-loading>
    <b-tabs content-class="mt-3">
      <b-tab title="الهدايا المرسلة" active>
        <div v-if="sendMeetingsGift.length > 0">
          <GlobalTable
            :data.sync="sendMeetingsGift"
            :fields="sendMeetingsGiftFields"
            :show-button="false"
          >
          </GlobalTable>
        </div>
        <p v-else class="text-center mt-4">لا توجد هدايا مرسلة</p>
      </b-tab>
      <b-tab title="الهدايا المستلمة">
        <div v-if="receivedMeetingsGift.length > 0">
          <GlobalTable
            :data.sync="receivedMeetingsGift"
            :fields="receivedMeetingGiftFields"
            :show-button="false"
          >
          </GlobalTable>
        </div>
        <p v-else class="text-center mt-4">لا توجد هدايا مستلمة</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import gift_request from "@/api/gift_request";
export default {
  name: "meetings-gift",
  components: {
    SmallSpringLoading,
  },
  data() {
    return {
      loadSendGiftMeetings: false,
      loadReceivedGiftMeeting: false,
      sendMeetingsGift: [],
      receivedMeetingsGift: [],
      sendMeetingsGiftFields: [
        {
          key: "type",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "الوصف",
          sortable: true,
        },
        {
          key: "buyer.full_name",
          label: "المهدى له",
          sortable: true,
        },
        {
          key: "buyer.email",
          label: "ايميل المهدى له",
          sortable: true,
        },
        {
          key: "amount",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "created_date",
          label: "التاريخ",
          sortable: true,
        },
      ],
      receivedMeetingGiftFields: [
        {
          key: "type",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "الوصف",
          sortable: true,
        },
        {
          key: "buyer.full_name",
          label: "المهدى له",
          sortable: true,
        },
        {
          key: "buyer.email",
          label: "ايميل المهدى له",
          sortable: true,
        },
        {
          key: "amount",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "created_date",
          label: "التاريخ",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getSendMeetingGift() {
      this.loadSendGiftMeetings = true;
      await gift_request.sendMeetings().then((res) => {
        this.sendMeetingsGift = res.data.data.sales;
      });
      this.loadSendGiftMeetings = false;
    },
    async getReceivedMeetingsGift() {
      this.loadReceivedGiftMeeting = true;
      await gift_request.receivedMeetings().then((res) => {
        this.receivedMeetingsGift = res.data.data.sales;
      });
      this.loadReceivedGiftMeeting = false;
    },
  },
  created() {
    this.getSendMeetingGift();
    this.getReceivedMeetingsGift();
  },
};
</script>

<style lang="scss" scoped></style>
