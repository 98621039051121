<template>
  <div id="app" dir="rtl">
    <vue-progress-bar></vue-progress-bar>
    <nav-bar />
    <div class="mt-6">
      <router-view />
    </div>
    <div v-if="this.$route.name && !this.$route.path.includes('/panel')">
      <new-footer></new-footer>
    </div>
    <!-- <footer-component></footer-component> -->
    <fast-pay-modal></fast-pay-modal>
    <show-gift-pay-modal></show-gift-pay-modal>
    <sign-in-modal></sign-in-modal>
  </div>
</template>
<script>
import NavBar from "@/views/NavBar/NavBar";
import NewFooter from "@/views/Layout/Footer";
import FastPayModal from "@/components/Helper/FastPayModal";
import showGiftPayModal from "@/components/Helper/GiftPayModal.vue";
import SignInModal from "@/components/Auth/SignInModal";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    NewFooter,
    NavBar,
    FastPayModal,
    SignInModal,
    showGiftPayModal,
  },
  data() {
    return {
      themesClass: "light-theme",
    };
  },
  computed: {
    // ...mapGetters("subscribe", ["subscribes", "subscribed", "loading"]),
    ...mapGetters("auth", ["isLogin"]),
    is_Login: {
      get() {
        return this.isLogin;
      },
      set(newName) {
        return newName;
      },
    },
  },
  methods: {
    // ...mapActions("subscribe", ["getSubscribes"]),
    ...mapActions("notification", ["fetchNotifications"]),
    ...mapActions("cart", ["getList"]),
    setThemes: function () {
      let theme = localStorage.getItem("coursestore-theme");
      if (theme !== "") {
        this.themesClass = theme;
      }
      if (theme === "dark-theme") {
        document.querySelector("html").classList.add("dark-theme");
        document.querySelector("html").classList.remove("light-theme");
      } else if (theme === "light-theme" || theme !== "") {
        document.querySelector("html").classList.add("light-theme");
        document.querySelector("html").classList.remove("dark-theme");
      }
    },
    disableF5(ev) {
      if ((ev.which || ev.keyCode) === 116 || ev.key === "f5") {
        ev.preventDefault();
        return false;
      }
    },
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.setThemes();
    let _this = this;
    this.$Progress.finish();
    this.$root.$on("onSetTheme", function () {
      _this.setThemes();
    });

    window.addEventListener("scroll", function () {
      localStorage.setItem("scrollHeight", window.pageYOffset);
      localStorage.setItem("route", _this.$route.path);
    });
    setTimeout(() => {
      let oldRoute = localStorage.getItem("route") || "";
      let top = localStorage.getItem("scrollHeight") || 0;
      if (_this.$route.path === oldRoute) {
        window.scrollTo(0, top);
      }
    }, 0);
  },
  created() {
    // this.getSubscribes();
    // console.log(this.$store.getters["subscribe/subscribed"]);

    let FB;
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });

    const playerScript = document.createElement("script");
    playerScript.src =
      "https://connect.facebook.net/ar_AR/sdk/xfbml.customerchat.js";

    document.head.appendChild(playerScript);
    window.fbAsyncInit = function () {
      if (FB !== undefined) {
        FB.init({
          xfbml: true,
          version: "v16.0",
        });
      }
    };
  },
  watch: {
    is_Login(newValue) {
      // Our fancy notification (2).
      if (newValue === true) {
        this.fetchNotifications(10);
        this.getList();
        this.$root.$emit("onLogin");
      } else {
        this.$store.commit("notification/RESET_SET_LIST");
        this.$store.commit("cart/RESET_LIST");
      }
    },
  },
};
</script>
<style lang="scss">
.container {
  max-width: 1500px !important;
  width: 100%;
  /* padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5); */
  margin-left: auto;
  margin-right: auto;
}
.name {
  font-size: 12px;
}
.dark-theme .price-night-mode {
  color: gray;
}
.swal2-styled.swal2-confirm {
  background-color: #410098 !important;
}
.discount {
  color: #fff;
  border-radius: 7px;
  position: absolute;
  top: 15px;
  left: 11px;
  padding: 4px 15px;
  font-size: 9px;
  z-index: 2;
}
.tags-webinar {
  position: absolute;
  top: 15px;
  left: 11px;
  font-size: 14px;
  z-index: 2;
  text-align: center;
  .my-tag {
    background-color: #f02d00;
    color: #fff;
    padding: 4px 15px;
    border-radius: 7px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
body {
  overflow-x: hidden !important;
}
@media (max-width: 768px) {
  .scroll-table-mobile {
    overflow-x: scroll;
    margin-bottom: 20px;
  }
}
.swal2-title {
  font-size: 20px !important;
}
.video-tabs .card {
  margin-top: 20px;
}
.video-tabs .card-header {
  border-bottom: none !important;
  border-color: transparent !important;
  background-color: #f0f0f0 !important;
  padding-bottom: 20px;
}
.dark-theme .video-tabs .card-header {
  background-color: #2f2f2f !important;
}
.video-tabs .card {
  border: none !important;
}
.video-tabs .card-body {
  padding: 20px 0 !important;
}
.video-tabs .tab-pane {
  max-height: 500px;
  overflow: auto;
  box-shadow: 0 7px 6px 0 rgb(0 0 0 / 12%) !important;
  border-radius: 7px;
}
.video-tabs .nav-link.active {
  background-color: transparent !important;
  border: none !important;
  border-color: transparent !important;
}
.video-tabs .nav-tabs .nav-link {
  border: none !important;
}
.dark-theme .name-light-dark-mode {
  color: #fff !important;
}
.modal {
  background: rgba(0, 0, 0, 0.4);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border: none !important;
}
.nav-tabs {
  border: none !important;
}
.nav-link:hover {
  // border: none !important;
}
.badge {
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
}
.name-underline::before {
  height: 2px !important;
}
.line .name-underline::before {
  height: 2px !important;
}
.flickity-prev-next-button.next,
.flickity-prev-next-button.previous {
  position: absolute;
  top: 50%;
  /* left: 0; */
  z-index: 1;
  transform: translateY(-50%);
  width: 35px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #688c9472;
  border-radius: 5px;
  margin-top: 0;
  color: #fff !important;
  font-size: 10px;
}
.flickity-prev-next-button.next {
  left: 16px !important;
}
.flickity-rtl .flickity-prev-next-button.previous {
  right: 2px !important;
}
.flickity-button {
  border: none !important;
  outline: none !important;
}
.flickity-prev-next-button.next:hover {
  background-color: #688c9472 !important;
}
.dark-theme .link-settings {
  color: #fff !important;
}
.vm {
  top: 130px !important;
}
.vm-content {
  padding: 15px 0 !important;
}
.dark-theme .vm-content,
.dark-theme .vm {
  background-color: #1c1c1c;
  color: #fff !important;
}
.dark-theme .vm-titlebar {
  background-color: #1c1c1c;
  color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (max-width: 768px) {
  .modal-classlist .vm-titlebar .vm-title {
    font-size: 12px !important;
  }
  .modal-classlist {
    height: 200px !important;
  }
  .modal-classlist iframe {
    height: 200px !important;
    top: 38px !important;
  }
  .fawry-modal {
    padding: 10px;
  }
  .fawry-modal .vm-title {
    font-size: 12px;
  }
  .fawry-modal .vm-titlebar {
    padding: 5px;
  }
}
.dark-theme .text-light-dark-mode {
  color: #fff;
}
.dark-theme .text-gray-dark-mode {
  color: gray;
}
.dark-theme .text-muted {
  color: gray !important;
}
.dark-theme .card-course {
  background-color: #2f2f2f;
}
.card-course {
  box-shadow: rgb(0 0 0 / 15%) 0 5px 15px 0;
  margin-bottom: 20px;
  border-bottom: none !important;
}
.dark-theme .card-course {
  box-shadow: rgb(255 255 255 / 15%) 0 5px 15px 0;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-10 {
  font-size: 10px;
}
.dark-theme .input-dark-mode {
  background-color: #2f2f2f !important;
  color: #fff !important;
}
.dark-theme .btn-dark-mode {
  background-color: #000;
  color: #fff;
}
.dark-theme .modal-title-mode {
  color: #fff;
}
.dark-theme .modal-content-mode {
  background-color: #2f2f2f;
}
.dark-theme .close {
  color: #fff !important;
}
#shakaVid {
  object-fit: cover !important;
}
tr {
  line-height: 30px;
}
.dark-theme svg {
  fill: #fff !important;
}
label {
  border: 0 !important;
}
iframe {
  position: absolute;
  top: 51px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
#embedBox iframe {
  top: 0 !important;
}
.form-box-width-mobile {
  width: 50%;
}
.dark-theme .linkable:hover {
  background-color: #73767d;
  color: #fff !important;
}
.dark-theme .all-link {
  color: #fff !important;
}

@media (max-width: 576px) {
  .vm-content iframe {
    height: 200px !important;
  }
  .play_takes_popup {
    height: 200px !important;
  }

  .margin-mobile .swiper-slide:nth-child(1) {
    margin-right: 5px !important;
  }
  /* .sub-title {
    margin-right: 15px;
  } */
  .my-courses .swiper-slide:nth-child(1) {
    margin-right: 15px !important;
  }
  #free-videos .swiper-slide:nth-child(1),
  .soon-mobile .swiper-slide:nth-child(1) {
    margin-right: 15px !important;
  }
  .all-link {
    margin-left: 15px !important;
    margin-bottom: 0;
  }
  .carousel-cell:nth-child(1) {
    margin-right: 58px !important;
  }
  #instructors .flickity-slider {
    right: 25px !important;
  }
  #discover-and-learn .flickity-slider {
    right: 6px !important;
  }
  .form-box-width-mobile {
    width: 100% !important;
  }
  .hero-form {
    width: 100% !important;
  }
}
.nav-tabs .nav-link {
  border: none !important;
}
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border: none !important;
}
.show-ipad {
  display: none;
}
.dark-theme .about-course-description p {
  color: #c2c2c288 !important;
}
@media (max-width: 822px) {
  .show-ipad {
    display: block !important;
  }
  .hide-ipad {
    display: none;
  }
  .prerequisite .height-250 {
    height: 275px !important;
  }
}
.fast-Pay .vm-titlebar .vm-btn-close {
  background: #410098 !important;
  border-radius: 15px !important;
  padding: 0 !important;
  font-size: 20px;
  color: #fff !important;
}
.dark-theme .fast-Pay .vm-titlebar .vm-btn-close {
  background-color: #fff !important;
  color: #410098 !important;
}
.fast-Pay .margin-price {
  margin-left: 40px !important;
}
.fawry-modal {
  border-radius: 15px;
  padding: 10px 20px;
}
.fawry-modal .vm-titlebar .vm-btn-close {
  background: #410098 !important;
  border-radius: 15px !important;
  padding: 0 !important;
  font-size: 20px;
  color: #fff !important;
}
.dark-theme .fawry-modal .vm-titlebar .vm-btn-close {
  background-color: #fff !important;
  color: #410098 !important;
}
.page-item .page-link {
  border: none !important;
  outline: none !important;
}
.dark-theme .page-item .page-link {
  background-color: #2f2f2f !important;
}
.table {
  font-size: 13px !important;
  margin-bottom: 0 !important;
}
.dark-theme .table {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-theme .table > thead,
.dark-theme .perPage {
  color: #fff !important;
}
.dark-theme tbody {
  color: #aaaaaa !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #aaaaaa !important;
}
.active > .page-link,
.page-link.active {
  background-color: #410098 !important;
}
.btn-action button {
  background-color: #2f2f2f !important;
  font-size: 13px;
}
// .dark-theme .show svg {
//   fill: #2f2f2f !important;
// }
.dark-theme .router-link-active {
  color: #fff !important;
}
.consult-settings {
  .vc-date {
    display: none !important;
  }
}
</style>
