import { render, staticRenderFns } from "./NavUserPanel.vue?vue&type=template&id=997e8bc4&scoped=true"
import script from "./NavUserPanel.vue?vue&type=script&lang=js"
export * from "./NavUserPanel.vue?vue&type=script&lang=js"
import style0 from "./NavUserPanel.vue?vue&type=style&index=0&id=997e8bc4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997e8bc4",
  null
  
)

export default component.exports