import cartRequest from "@/api/cart_request";
import Swal from "sweetalert2";
import auth_helper from "@/helpers/auth_helper";
import cart_request from "@/api/cart_request";
import store from "@/store";

export default {
  namespaced: true,
  state: () => ({
    list: { items: [] },
    info: {},
    is_do_get_data: false,
  }),
  getters: {
    list: (state) => {
      return state.list;
    },
    info: (state) => {
      return state.info;
    },
    is_do_get_data: (state) => {
      return state.is_do_get_data;
    },
  },
  mutations: {
    SET_LIST(state, payload) {
      if (payload != null) {
        state.list = payload;
      }
    },
    SET_INFO(state, payload) {
      state.info = payload;
    },
    RESET_LIST(state) {
      state.list = { items: [] };
    },
    SET_ID_DO_GET_DATA(state, payload) {
      if (payload != null) {
        state.is_do_get_data = payload;
      }
    },
  },
  actions: {
    async getList({ commit }) {
      commit("SET_ID_DO_GET_DATA", true);
      await cartRequest
        .fetchAll()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              commit("SET_LIST", response.data.data.cart);
            } else {
              commit("SET_LIST", null);
            }
          } else {
            commit("SET_LIST", null);
          }
        })
        .finally(() => {
          commit("SET_ID_DO_GET_DATA", false);
        });
    },
    async delete({ dispatch, state, commit }, id) {
      await cartRequest.delete(id).then(() => {
        // remove the deleted item from the list state
        const updatedList = state.list.items.filter((item) => item.id !== id);
        commit("SET_LIST", { items: updatedList });

        // dispatch the getList action to refresh the cart list
        dispatch("getList");
      });
    },
    async empty({ commit }) {
      await cartRequest.empty().then((response) => {
        if (response.status === 200 && response.data.success === true) {
          commit("RESET_LIST");
        }
      });
    },
    async add({ dispatch }, payload) {
      await cartRequest.add(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            Swal.fire({
              icon: "success",
              title: "تمت الإضافة للعربة",
              confirmButtonText: "موافق",
            });
            dispatch("getList");
          } else if (
            response.data.success === false &&
            response.data.status === "unauthorized"
          ) {
            // do logout
            this.$root.$emit("onLogOut");
            auth_helper.logOut();
            store.commit("auth/SET_IS_LOGIN", false);
            this.$swal.fire({
              icon: "error",
              title: "هذة الخاصية متاحة للاعضاء فقط",
            });
          } else if (response.data.status === "already_bought") {
            this.$swal.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        }
      });
    },
    async couponValidate(_, payload) {
      return await cart_request.couponValidate(payload);
    },
    async getInfo(_, payload) {
      return await cart_request.getInfo(payload);
    },
  },
};
