import axios from "axios";

export default {
  async getMyPurchases(_limit = 9) {
    return await axios.get("/panel/webinars/purchases", {
      params: { limit: _limit },
    });
  },
  async getPurchases(_limit = 5, _pageNumber = 0) {
    const _offset = _limit * _pageNumber;
    return await axios.get("/panel/webinars/purchases", {
      params: { limit: _limit, offset: _offset },
    });
  },
  async getFavorites(_limit = 4, _offset = 0) {
    return await axios.get("/panel/favorites", {
      params: { limit: _limit, offset: _offset },
    });
  },
};
