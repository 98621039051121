import axios from "axios";
export default {
  async fetchAllOffers() {
    return await axios.get("/check-for-offers");
  },
  async getOffers(limit = 10, offset = 0) {
    return await axios.get("/offers", {
      params: { limit: limit, offset: offset },
    });
  },
};
