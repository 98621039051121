import { render, staticRenderFns } from "./NavNotofication.vue?vue&type=template&id=6429fffc&scoped=true"
import script from "./NavNotofication.vue?vue&type=script&lang=js"
export * from "./NavNotofication.vue?vue&type=script&lang=js"
import style0 from "./NavNotofication.vue?vue&type=style&index=0&id=6429fffc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6429fffc",
  null
  
)

export default component.exports