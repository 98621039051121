<template>
  <div class="scroll-table-mobile">
    <h6 class="head fs-5 mb-4 text-light-dark-mode">
      الإشعارات
      <span class="mx-1">({{ all.count }})</span>
    </h6>
    <small-spring-loading :is-loading="Loading"></small-spring-loading>
    <table class="table">
      <tbody>
        <tr
          v-for="(item, index) in all.notifications"
          :key="index"
          class="cart shadow-sm rounded-3 align-middle"
        >
          <td>
            <svg
              v-if="item.status === 'unread'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: var(--bg-cs-primary)"
            >
              <path
                d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7-8 5.334L4 8.7V6.297l8 5.333 8-5.333V8.7z"
              ></path>
            </svg>
            <svg
              v-if="item.status === 'read'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: var(--bg-cs-secoundry)"
            >
              <path
                d="m21.555 8.168-9-6a1 1 0 0 0-1.109 0l-9 6A.995.995 0 0 0 2.004 9H2v11a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9h-.004a.997.997 0 0 0-.441-.832zM20 12.7 12 17l-8-4.3v-2.403l8 4.299 8-4.299V12.7z"
              ></path>
            </svg>
          </td>
          <td>
            <div class="d-flex justify-content-start gap-3 align-items-center">
              <div class="align-items-center">
                <h6 class="h6 text-light-dark-mode">
                  {{ item.title }}
                </h6>
                <p class="small text-gray-dark-mode" v-html="item.message"></p>
              </div>
            </div>
          </td>
          <td>
            <h6 class="fs-12 text-gray-dark-mode">
              {{ item.created_as_human }}
            </h6>
          </td>
          <td>
            <span
              class="cursor-pointer"
              @click.stop="deleteNotification(item.id)"
            >
              <img src="../../assets/icon/cs-trash.svg" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserProfileNotifications",
  components: { SmallSpringLoading },
  data() {
    return {
      Loading: true,
    };
  },
  computed: {
    ...mapGetters("notification", ["all"]),
  },
  methods: {
    ...mapActions("notification", ["fetchAllNotifications", "delete"]),
    deleteNotification(id) {
      this.delete(id);
    },
  },
  created() {
    this.fetchAllNotifications().finally(() => {
      this.Loading = false;
    });
  },
};
</script>

<style scoped></style>
