import axios from "axios";
import appConfig from "../config";
export default {
  async fetchAll() {
    return await axios.get("/subscribe");
  },
  async makeSubscribe(subscribe_id, platform) {
    return await axios.post("/panel/subscribe", {
      subscribe_id: subscribe_id,
      platform: platform,
    });
  },
  async checkSubscribe(order_id, gateway_id, PaymentMethodId, class_name) {
    return await axios.post("/panel/subscribe/checkout", {
      order_id: order_id,
      gateway_id: gateway_id,
      PaymentMethodId: PaymentMethodId,
      back_url: appConfig.VERIFY_URL + class_name,
    });
  },
  async getRecommendedCourses() {
    return await axios.get("/courses/recommended");
  },
  async getTopVideosSubscriptions() {
    return await axios.get("/courses/hero-recommended-subscriber");
  },
  async getRecentlyCourses() {
    return await axios.get("/panel/recent-courses");
  },
  async getPrevVideo(id) {
    return await axios.get("/courses/hero-recommended-subscriber", {
      params: { current_id: id },
    });
  },
};
