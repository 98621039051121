import axios from "axios";
export default {
  namespaced: true,
  state: {
    certificate: [],
  },
  getters: {
    certificates: (state) => {
      return state.certificate;
    },
  },
  mutations: {
    ALL_CERTIFICATES: (state, payload) => {
      state.certificate = payload;
    },
  },
  actions: {
    async getCertificates({ commit }) {
      return await new Promise((resolve, reject) => {
        axios
          .get("/panel/certificates/achievements")
          .then((res) => {
            commit("ALL_CERTIFICATES", res.data.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // async getCertificates({ commit }) {
    //   await achievements_request.getCertificates().then((response) => {
    //     if (response.status === 200) {
    //       if (response.data.success === true) {
    //         commit("ALL_CERTIFICATES", response.data.data);
    //       } else {
    //         commit("ALL_CERTIFICATES", null);
    //       }
    //     } else {
    //       commit("ALL_CERTIFICATES", null);
    //     }
    //   });
    // },
  },
};
