import notify_request from "@/api/notification_request";

export default {
  namespaced: true,
  state: () => ({
    list: {
      count: 0,
      notifications: {},
    },
    all: {
      count: 0,
      notifications: {},
    },
  }),
  getters: {
    list: (state) => {
      return state.list;
    },
    all: (state) => {
      return state.all;
    },
  },

  mutations: {
    SET_LIST(state, payload) {
      if (payload) {
        state.list = payload;
      }
    },
    RESET_SET_LIST(state) {
      state.list = { count: 0, notifications: {} };
    },
    SET_ALL(state, payload) {
      if (payload) {
        state.all = payload;
      }
    },
    RESET_SET_ALL(state) {
      state.all = { count: 0, notifications: {} };
    },
  },
  actions: {
    async fetchNotifications({ commit }, limit = 10) {
      await notify_request.fetchAll("unread", limit).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          commit("SET_LIST", response.data.data);
        }
      });
    },
    async fetchAllNotifications({ commit }) {
      await notify_request.fetchAll("", 0).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          commit("SET_ALL", response.data.data);
        }
      });
    },
    async delete({ commit }, id) {
      await notify_request.delete(id).then((response) => {
        if (response.status === 200) {
          if (response.data.success === true) {
            commit("SET_LIST", response.data.data["unread"]);
            commit("SET_ALL", response.data.data["all"]);
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        }
      });
    },
  },
};
