<template>
  <div class="mx-auto col-lg-3 my-5">
    <change-password-component />
  </div>
</template>

<script>
import ChangePasswordComponent from "@/components/Auth/ChangePasswordComponent";

export default {
  name: "ChangePasswordView",
  components: { ChangePasswordComponent },
};
</script>

<style scoped></style>
