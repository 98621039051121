import axios from "axios";

export default {
  async getReviews(payload) {
    return await axios.post("/courses/reviews", {
      webinar_id: payload.webinar_id,
      limit: payload.limit,
      offset: payload.offset,
    });
  },
  async getBundleReview(payload) {
    return await axios.get(`/bundles/reviews/${payload.bundle_id}`, {
      params: {
        limit: payload.limit,
        offset: payload.offset,
      },
    });
  },
};
