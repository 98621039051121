import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    page: {},
    notfound_message: "",
  }),
  getters: {
    page: (state) => {
      return state.page;
    },
    notfound_message: (state) => {
      return state.notfound_message;
    },
  },
  mutations: {
    SET_PAGE(state, payload) {
      state.page[payload.link] = payload.data;
    },
    SET_NOT_FOUND(state, payload) {
      state.notfound_message = payload;
    },
  },
  actions: {
    async getPage({ commit }, link) {
      commit("SET_NOT_FOUND", "");
      await axios
        .get("/page/" + link)
        .then((response) => {
          if (response.status === 200) {
            commit("SET_PAGE", { link: link, data: response.data.data });
          } else {
            commit("SET_NOT_FOUND", "الصفحة غير موجودة");
          }
        })
        .catch(() => {
          commit("SET_NOT_FOUND", "الصفحة غير موجودة");
        });
    },
  },
};
