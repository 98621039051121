import axios from "axios";

export default {
  async fetchAll(page, category) {
    page = page + 1;
    if (page === "NaN" || !page) {
      page = 1;
    }
    return await axios.get("/providers/instructors", {
      params: {
        paginate: true,
        limit: 8,
        page: page,
        categories: category,
      },
    });
  },
  async GetInstructor(id) {
    return await axios.get(`users/${id}/profile`);
  },
  async follow(id, status = true) {
    return await axios.post(`panel/users/${id}/follow`, { status: status });
  },
  async becomeInstructor(payload) {
    return await axios.post(`/panel/become_instructor`, payload);
  },
};
