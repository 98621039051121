<template>
  <form @submit.prevent="handelSendCode">
    <display-error-helper v-bind:errors="errors" />
    <div class="input-form" v-if="showForm">
      <div class="mb-3">
        <div class="input-box">
          <GlobalInput
            type="email"
            label="البريد الإلكتروني"
            v-model="form.email"
          />
          <!-- <input
            id="exampleInputEmail1"
            v-model="form.email"
            aria-describedby="emailHelp"
            required
            type="email"
          />
          <label for="exampleInputEmail1"> </label> -->
        </div>
      </div>
      <button class="btn-block w-100 btn btn-info text-white" type="submit">
        <span>إرسال</span>
      </button>
    </div>
    <div v-else>
      <div class="alert alert-success" v-if="successMessage !== null">
        {{ successMessage }}
      </div>
      <div class="alert btn-danger" v-if="errorMessage !== null">
        {{ errorMessage }}
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DisplayErrorHelper from "@/components/Helper/DisplayErrorHelper";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
export default {
  name: "ForgetPasswordComponent",
  components: { DisplayErrorHelper, GlobalInput },
  data() {
    return {
      successMessage: null,
      errorMessage: null,
      showForm: true,
      errors: [],
      form: { email: "" },
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogin"]),
  },
  methods: {
    ...mapActions("auth", ["sendForgetCode"]),

    handelSendCode() {
      this.$Progress.start();
      this.errors = [];
      this.sendForgetCode(this.form.email)
        .then((response) => {
          this.showForm = false;
          let { status, data } = response;
          if (status === 200) {
            this.successMessage = data.message;
          } else {
            this.errorMessage = data.message;
          }
        })
        .catch((error) => {
          this.errors.push(error.response.data.message);
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
  },
  watch: {
    isLogin(newIsLogin) {
      if (newIsLogin === true) {
        //this.$router.replace({ name: "home" });
      }
    },
  },
};
</script>

<style scoped></style>
