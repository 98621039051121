<template>
  <div>
    <div
      class="footer-apps d-flex flex-column align-items-center justify-content-center gap-2 mt-3"
    >
      <a href="https://appgallery.huawei.com/app/C107470739" target="_blank">
        <!-- <span class="text-white d-none d-lg-block fs-6">App Store</span>
        <img
          width="30"
          class="text-white"
          src="../../assets/icon/cs-apple.svg"
        /> -->
        <img
          class="image-apps"
          src="../../assets/images/Huawei_Gallery_CourseStore.png"
          alt="Huawei Gallery CourseStore"
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.online.coursestore"
        target="_blank"
      >
        <!--<span class="text-white d-none d-lg-block fs-6">Google Play</span>
        <img
          width="30"
          class="text-white"
          src="../../assets/icon/cs-google-store.svg"
        />-->
        <img
          class="image-apps"
          src="../../assets/images/Google_Play_CourseStore.png"
          alt="Google Play CourseStore"
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/course-store/id1668808090"
        target="_blank"
      >
        <img
          class="image-apps"
          src="../../assets/images/Apple_App_CourseStore.png"
          alt="apple Play CourseStore"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileApplicationComponent",
};
</script>

<style scoped>
@media (max-width: 1268px) {
  .footer-apps {
    flex-direction: row !important;
  }
}

.image-apps {
  width: 90px;
}
</style>
