<template>
  <div>
    <small-spring-loading
      :is-loading="loadSendGiftWebinars"
    ></small-spring-loading>
    <small-spring-loading
      :is-loading="loadReceivedGiftWebinars"
    ></small-spring-loading>
    <b-tabs content-class="mt-3">
      <b-tab title="الهدايا المرسلة" active>
        <div v-if="sendWebinarsGift.length > 0">
          <GlobalTable
            :data.sync="sendWebinarsGift"
            :fields="sendWebinarsGiftFields"
            :show-button="false"
          >
          </GlobalTable>
        </div>
        <p v-else class="text-center mt-4">لا توجد هدايا مرسلة</p>
      </b-tab>
      <b-tab title="الهدايا المستلمة">
        <div v-if="receivedWebinarsGift.length > 0">
          <GlobalTable
            :data.sync="receivedWebinarsGift"
            :fields="receivedWebinarsGiftFields"
            :show-button="false"
          >
          </GlobalTable>
        </div>
        <p v-else class="text-center mt-4">لا توجد هدايا مستلمة</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import gift_request from "@/api/gift_request";
export default {
  name: "send-webinars-gift",
  components: {
    SmallSpringLoading,
  },
  data() {
    return {
      loadSendGiftWebinars: false,
      loadReceivedGiftWebinars: false,
      sendWebinarsGift: [],
      receivedWebinarsGift: [],
      sendWebinarsGiftFields: [
        {
          key: "webinar.title",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "الوصف",
          sortable: true,
        },
        {
          key: "buyer.full_name",
          label: " اسم المهدى له",
          sortable: true,
        },
        {
          key: "buyer.email",
          label: "ايميل المهدى له",
          sortable: true,
        },
        {
          key: "webinar.price_string",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "created_date",
          label: "التاريخ",
          sortable: true,
        },
      ],
      receivedWebinarsGiftFields: [
        {
          key: "webinar.title",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "الوصف",
          sortable: true,
        },
        {
          key: "buyer.full_name",
          label: "المهدى له",
          sortable: true,
        },
        {
          key: "buyer.email",
          label: "ايميل المهدى له",
          sortable: true,
        },
        {
          key: "webinar.price_string",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "created_date",
          label: "التاريخ",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getSendWebinarsGift() {
      this.loadSendGiftWebinars = true;
      await gift_request.sendWebinar().then((res) => {
        this.sendWebinarsGift = res.data.data.sales;
      });
      this.loadSendGiftWebinars = false;
    },
    async getReceivedWebinarsGift() {
      this.loadReceivedGiftWebinars = true;
      await gift_request.receivedWebinar().then((res) => {
        this.receivedWebinarsGift = res.data.data.sales;
      });
      this.loadReceivedGiftWebinars = false;
    },
  },
  created() {
    this.getSendWebinarsGift();
    this.getReceivedWebinarsGift();
  },
};
</script>

<style lang="scss" scoped></style>
