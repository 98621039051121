<template>
  <div class="container-newsletter news-letters">
    <small-spring-loading :is-loading="isLoading"></small-spring-loading>
    <div class="row align-items-center">
      <div class="col-md-6">
        <p class="title">اشترك في القائمة البريدية</p>
      </div>
      <div class="col-md-6">
        <div class="m-md-0">
          <div class="text-danger" v-show="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="text-white" v-show="successMessage">
            {{ successMessage }}
          </div>
          <div class="comment-form mb-2">
            <input v-model="email" placeholder="أدخل  البريد الإلكتروني" />
            <button type="email" @click.prevent="doRegister()">
              اشترك الآن
            </button>
            <small-spring-loading
              :is-loading="isLoading"
            ></small-spring-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import home_request from "@/api/home_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading.vue";

export default {
  name: "NewsletterComponent",
  components: { SmallSpringLoading },
  data() {
    return {
      isLoading: false,
      email: "",
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    async doRegister() {
      this.successMessage = "";
      this.errorMessage = "";
      this.isLoading = true;
      await home_request
        .setNewsletter({ email: this.email })
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data.success === false &&
              response.data.status === "validation_error"
            ) {
              this.errorMessage = response.data.data.errors.email.join(" , ");
            }
            if (response.data.success === true) {
              this.successMessage = response.data.message;
            }
          }
          console.log(response);
        })
        .catch((error) => {
          this.errorMessage = error.getMessage();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #fff;
  font-size: 1rem;
}
.comment-form,
.rates-form {
  position: relative;
  margin-bottom: 20px;
  input {
    padding: 25px 20px;
    background-color: #fff;
    border: none;
    outline: none;
    box-shadow: 0 4px 12px #00000029;
    border-radius: 8px;
    opacity: 1;
    color: #707070;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  button {
    position: absolute;
    left: 15px;
    top: 15%;
    height: 48px;
    font-size: 12px;
    width: 95px;
    border: none;
    box-shadow: 0 4px 12px #00000029;
    background-color: #773dbd;
    color: #fff;
    border-radius: 8px;
    opacity: 1;
  }
}
.news-letters {
  background-color: #773dbd;
  border-radius: 8px;
  padding: 1rem;
  max-width: 1300px;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: -61px;
}
.dark-theme .comment-form,
.rates-form {
  button {
    background-color: #707070;
    color: #fff !important;
  }
}
.dark-theme .news-letters {
  background-color: #2f2f2f;
  border: none;
}
</style>
