<template>
  <div>
    <small-spring-loading :is-loading="loadGiftBundles"></small-spring-loading>
    <small-spring-loading
      :is-loading="loadReceivedGiftBundles"
    ></small-spring-loading>
    <b-tabs content-class="mt-3">
      <b-tab title="الهدايا المرسلة" active>
        <div v-if="sendBundlesGift.length > 0">
          <GlobalTable
            :data.sync="sendBundlesGift"
            :fields="sendBundlesGiftField"
            :show-button="false"
          >
          </GlobalTable>
        </div>
        <p v-else class="text-center mt-4">لا توجد هدايا مرسلة</p>
      </b-tab>
      <b-tab title="الهدايا المستلمة">
        <div v-if="receivedBundlesGift.length > 0">
          <GlobalTable
            :data.sync="receivedBundlesGift"
            :fields="receivedBundlesGiftFields"
            :show-button="false"
          >
          </GlobalTable>
        </div>
        <p v-else class="text-center mt-4">لا توجد هدايا مستلمة</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import gift_request from "@/api/gift_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
export default {
  name: "send-webinars-gift",
  components: {
    SmallSpringLoading,
  },
  data() {
    return {
      loadGiftBundles: false,
      loadReceivedGiftBundles: false,
      sendBundlesGift: [],
      sendBundlesGiftField: [
        {
          key: "bundle.title",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "الوصف",
          sortable: true,
        },
        {
          key: "buyer.full_name",
          label: "المهدى له",
          sortable: true,
        },
        {
          key: "buyer.email",
          label: "ايميل المهدى له",
          sortable: true,
        },
        {
          key: "bundle.price_string",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "created_date",
          label: "التاريخ",
          sortable: true,
        },
      ],
      receivedBundlesGift: [],
      receivedBundlesGiftFields: [
        {
          key: "bundle.title",
          label: "العنوان",
          sortable: true,
        },
        {
          key: "payment_method",
          label: "الوصف",
          sortable: true,
        },
        {
          key: "buyer.full_name",
          label: "المهدى له",
          sortable: true,
        },
        {
          key: "buyer.email",
          label: "ايميل المهدى له",
          sortable: true,
        },
        {
          key: "bundle.price_string",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "created_date",
          label: "التاريخ",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getSendBundlesGift() {
      this.loadGiftBundles = true;
      await gift_request.sendBundles().then((res) => {
        this.sendBundlesGift = res.data.data.sales;
      });
      this.loadGiftBundles = false;
    },
    async getReceivedBundlesGift() {
      this.loadReceivedGiftBundles = true;
      await gift_request.receivedBundles().then((res) => {
        this.receivedBundlesGift = res.data.data.sales;
      });
      this.loadReceivedGiftBundles = false;
    },
  },
  created() {
    this.getSendBundlesGift();
    this.getReceivedBundlesGift();
  },
};
</script>

<style lang="scss" scoped></style>
