<template>
  <div class="scroll-table-mobile">
    <table class="table">
      <tbody>
        <tr
          v-for="(item, index) in summary_list"
          :key="index"
          class="cart shadow-sm rounded-3 align-middle"
        >
          <td>
            <h6 class="fs-12 text-light-dark-mode">#</h6>
            <p class="text-gray-dark-mode">{{ item.id }}</p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">المحتوى</h6>
            <div v-if="item.type === 'webinar'">
              <p class="text-gray-dark-mode fs-10">{{ item.webinar.title }}</p>
            </div>
            <div v-if="item.type === 'meeting'">
              <p class="text-gray-dark-mode fs-10 text-light-dark-mode">
                اجتماع
              </p>
            </div>
            <div v-if="item.type === 'subscribe'">
              <p class="text-gray-dark-mode fs-10">
                {{ item.subscribe.title }}
              </p>
            </div>
            <div v-if="item.type === 'promotion'">
              <p class="text-gray-dark-mode fs-10">
                {{ item.promotion.title }}
              </p>
            </div>
            <div v-if="item.type === 'registration_package'">
              <p class="text-gray-dark-mode fs-10 text-light-dark-mode">
                الاشتراك في باقه
              </p>
            </div>
            <div v-if="item.type === 'manual_document'">
              <p class="text-gray-dark-mode fs-10">وثيقة يدوية</p>
            </div>
            <div v-if="item.type === 'charge_account'">
              <p class="text-gray-dark-mode fs-10">شحن رصيد</p>
            </div>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">المبلغ</h6>
            <p
              class="fw-bold fs-10"
              :class="{
                'text-success': item.balance_type === 'addition',
                'text-danger': item.balance_type === 'deduction',
              }"
            >
              <span v-if="item.balance_type === 'addition'">+</span>
              <span v-if="item.balance_type === 'deduction'">-</span>
              {{ item.amount }}
              <span v-if="item.currency != undefined">{{
                item.currency.code
              }}</span>
            </p>
          </td>

          <td>
            <h6 class="fs-12 text-light-dark-mode">النوع</h6>
            <p class="text-gray-dark-mode fs-10">{{ item.type }}</p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">تاريخ الشراء</h6>
            <p class="text-gray-dark-mode fs-10">{{ item.created_date }}</p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">وصف</h6>
            <p class="text-gray-dark-mode fs-10">{{ item.description }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex align-items-center justify-content-center">
      <button
        :disabled="btn_disabled"
        class="btn btn-coursestore-primary"
        @click.prevent="_getAllSummary"
      >
        المزيد
      </button>
      <small-spring-loading
        :is-loading="loading_summary"
      ></small-spring-loading>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";

export default {
  name: "SummaryComponent",
  components: { SmallSpringLoading },
  data() {
    return {
      btn_disabled: false,
    };
  },
  computed: {
    ...mapGetters("financial", [
      "summary_list",
      "loading_summary",
      "summary_offset",
    ]),
  },
  methods: {
    ...mapActions("financial", ["getAllSummary"]),
    async _getAllSummary() {
      let _this = this;
      _this.btn_disabled = true;
      await this.getAllSummary({}).finally(() => {
        _this.btn_disabled = false;
      });
    },
  },
  created() {
    if (this.summary_list.length <= 0) {
      this._getAllSummary();
    }
  },
};
</script>

<style scoped></style>
