<template>
  <div class="pt-4">
    <div id="user-profile" class="d-flex">
      <div
        class="overlay"
        @click="showSideBar = !showSideBar"
        :class="showSideBar ? 'show-overlay' : ''"
      ></div>
      <div
        class="d-block d-lg-none show-side"
        @click="showSideBar = !showSideBar"
      >
        <span></span><span></span><span></span>
      </div>
      <div class="sidebar-parent">
        <div class="sidebar-content">
          <div class="profile-side" :class="showSideBar ? 'show' : ''">
            <profile-side-component></profile-side-component>
          </div>
        </div>
      </div>
      <!-- start user profile content -->
      <div class="panel-content pr-4">
        <router-view name="user_content" />
      </div>

      <!-- end user profile content -->
    </div>
  </div>
</template>

<script>
import ProfileSideComponent from "@/components/Profile/ProfileSideComponent";

export default {
  name: "PanelView",
  components: {
    ProfileSideComponent,
  },
  data() {
    return {
      showSideBar: false,
    };
  },
};
</script>

<style>
:root {
  --bg-cs-primary: #410099;
  --bg-cs-secoundry: #1ecad3;
  --bg-cs-assent: #773dbd;
}
a {
  text-decoration: none;
}
.bg-cs-assent {
  background: var(--bg-cs-assent) !important;
}
.bg-cs-primary {
  background: var(--bg-cs-primary);
}
.bg-cs-secondary {
  background: var(--bg-cs-secoundry);
}
.profile-side {
  background: var(--bg-cs-primary);
  position: absolute;
  height: 100%;
  bottom: 0;
  z-index: 55;
  overflow: auto;
}

.profile-avatar img {
  border: var(--bs-white) solid 3px;
}
.profile-side .profile-menu {
  color: #fff !important;
}
.profile-side .profile-menu a {
  color: #fff !important;
  text-decoration: none;
}
.profile-side .profile-menu a:hover,
.profile-side .profile-menu a.active {
  color: var(--nav-active-1) !important;
}
.btn-coursestore-primary {
  color: #fff !important;
  background-color: var(--nav-active-1);
  border-color: var(--nav-active-1);
}

.btn-coursestore-primary:hover {
  color: #fff;
  background-color: var(--bg-cs-primary);
  border-color: var(--bg-cs-primary);
}
.tabs-breadcrumb .breadcrumb li:after {
  content: "";
}
.tabs-breadcrumb .profile-tab a {
  margin-top: 4px;
}
.tabs-breadcrumb .profile-tab a.active,
.tabs-breadcrumb .profile-tab a:hover {
  border-bottom: #410099 solid 5px;
  color: #410099 !important;
}
.dark-theme .tabs-breadcrumb .profile-tab a.active,
.dark-theme .tabs-breadcrumb .profile-tab a:hover {
  border-bottom: #fff solid 5px !important;
  color: #fff !important;
}
.shamel-bold {
  font-family: FFShamelSansOne, sans-serif !important;
}
.shamel-bold img {
  border-radius: var(--bs-border-radius) !important;
}
.form-control {
  padding: 0;
}
.profile-alert-count:before {
  content: attr(data-count);
  position: absolute;
  background-color: var(--bg-cs-secoundry);
  color: var(--bs-white);
  padding: 6px;
  border-radius: 50%;
  top: -9px;
  right: -14px;
  width: 26px;
  height: 26px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
#user-profile {
  position: relative;
  min-height: 100vh;
}
.profile-side,
.panel-content {
  display: inline-block;
}
.profile-side {
  width: 240px !important;
}
.panel-content {
  width: calc(100vw - 248px) !important;
  padding: 0 10px;
  margin-right: 240px;
}
.show-side {
  text-align: center;
  position: absolute;
  /* top: 66px; */
  left: 0;
  color: #fff;
  width: 45px;
  padding: 5px 10px !important;
}
.show-side span {
  display: block;
  width: 20px;
  height: 3px;
  margin-right: 5px;
  margin-bottom: 3px;
  background-color: #410099;
  cursor: pointer;
}
.dark-theme .show-side span {
  background-color: #fff !important;
}
@media (min-width: 768px) {
}
/* @media (min-width: 992px) {
  .profile-content {
    margin-top: 100px;
  }
} */
.overlay {
  background: transparent;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;
}
@media (max-width: 991px) {
  .overlay {
    background: transparent;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2;
  }
  .show-overlay {
    inset: 0;
  }
  #user-profile {
    min-height: 100vh;
  }
  .profile-side {
    width: 250px !important;
    overflow: scroll !important;
    position: fixed;
    top: 0;
    padding: 20px 0;
    bottom: 0;
    height: 100%;
    overflow: scroll;
    z-index: 9;
    transform: translate(120%, 50px);
    transition: 0.4s;
  }
  .profile-side.show {
    transform: translate(0, 50px);
    margin-top: 0;
  }
  .panel-content {
    width: 100% !important;
    margin: 0;
  }
}
</style>
