<template>
  <div>
    <small-spring-loading :is-loading="true"></small-spring-loading>
  </div>
</template>

<script>
import marketers_request from "@/api/marketers_request";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading.vue";

export default {
  name: "global-marketers",
  components: {
    SmallSpringLoading,
  },
  created() {
    this.getMarketers();
  },
  methods: {
    getMarketers() {
      marketers_request
        .getMarketerLink(this.$route.params.code, "g")
        .then((res) => {
          if (res.status === 200 && res.data.success === true) {
            this.$router.push("/");
          } else if (res.status === 200 && res.data.success === false) {
            this.$swal.fire({
              icon: "error",
              title: res.data.message,
            });
            this.$router.push("/");
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: error.message,
          });
          this.$router.push("/");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
