import axios from "axios";

export default {
  async getHeroSections(_limit = 5) {
    return await axios.get("/home/hero-sections", {
      params: { limit: _limit },
    });
  },
  async getSoon() {
    return await axios.get("/home/soon");
  },
  async getPodcast() {
    return await axios.get("/home/podcast");
  },
  async getFaq() {
    return await axios.get("/faq");
  },
  async getInstructors() {
    return await axios.get("/home/instructors");
  },
  async setNewsletter(payload) {
    return await axios.post("newsletter", payload);
  },
  async search(payload) {
    return await axios.get("search", { params: payload });
  },
  async getTakes(params) {
    return await axios.get("/home/takes", {
      params: {
        limit: params.limit,
        offset: params.offset * params.limit,
      },
    });
  },
  async getPopularCourses() {
    return await axios.get("/home/popular-courses", {
      params: {
        limit: 4,
      },
    });
  },
  async getCategories() {
    return await axios.get("/categories");
  },
  async getCategoryWebinar(id, offset, limit = 10, isLessData = false) {
    return await axios.get(`categories/${id}/webinars`, {
      params: {
        limit: limit,
        offset: offset * limit,
        type: "course",
        is_less_data: isLessData,
      },
    });
  },
  async getAllWebinar(offset = 0, limit = 10, isLessData = false) {
    return await axios.get(`courses`, {
      params: {
        limit: limit,
        offset: offset * limit,
        type: "course",
        is_less_data: isLessData,
        sort: "newest",
      },
    });
  },
};
