import axios from "axios";

export default {
  async completeData(payload) {
    return await axios.post("/complete-user-data", payload);
  },
  async checkGift(payload) {
    return await axios.post("/panel/cart/check-gift", payload);
  },
  async sendWebinar() {
    return await axios.get("/panel/gifts/webinar/send");
  },
  async receivedWebinar() {
    return await axios.get("/panel/gifts/webinar/received");
  },
  // bundles
  async sendBundles() {
    return await axios.get("/panel/gifts/bundle/send");
  },
  async receivedBundles() {
    return await axios.get("/panel/gifts/bundle/received");
  },
  // meetings
  async sendMeetings() {
    return await axios.get("/panel/gifts/meeting/send");
  },
  async receivedMeetings() {
    return await axios.get("/panel/gifts/meeting/received");
  },
};
