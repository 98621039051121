import ResetPasswordView from "@/views/Auth/ResetPasswordView";
import UpdateProfileView from "@/views/Auth/UpdateProfileView";
import ForgetPasswordView from "@/views/Auth/ForgetPasswordView";
import ChangePasswordView from "@/views/Auth/ChangePasswordView";
import RegisterView from "@/views/Auth/RegisterView";
import GoogleCallback from "@/components/Auth/GoogleCallback";
import FacebookCallback from "@/components/Auth/FacebookCallback";
import CertificateValidation from "@/views/CertificateValidation/CertificateValidation.vue";
export default [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: LoginView,
  //   meta: {
  //     guest: true,
  //   },
  // },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      guest: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      guest: true,
    },
  },
  {
    path: "/google/callback",
    name: "google_callback",
    component: GoogleCallback,
    meta: {
      guest: true,
    },
  },
  {
    path: "/facebook/callback",
    name: "facebook_callback",
    component: FacebookCallback,
    meta: {
      guest: true,
    },
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: ForgetPasswordView,
    meta: {
      guest: true,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePasswordView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/update-profile",
    name: "update-profile",
    component: UpdateProfileView,
    meta: {
      auth: true,
    },
  },
  {
    path: "/certificate-validation",
    name: "certificate-validation",
    component: CertificateValidation,
  },
];
