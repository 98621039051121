export default {
  authSaveKey: "app_auth",
  setAUthData(data) {
    if (data === null) {
      localStorage.removeItem(this.authSaveKey);
    } else {
      localStorage.setItem(this.authSaveKey, data);
    }
  },
  getAUthData() {
    return localStorage.getItem(this.authSaveKey);
  },
  logOut() {
    localStorage.setItem(this.authSaveKey, "");
    localStorage.removeItem(this.authSaveKey);
  },
  isLogin() {
    return this.getApiToken() !== "";
  },
  getApiToken() {
    let apiToken = this.getAUthData();
    if (apiToken !== undefined && apiToken !== null) {
      return apiToken;
    }
    return "";
  },
};
