import axios from "axios";
export default {
  namespaced: true,
  state: () => ({
    social: {},
    config: {
      pages_back_ground: {
        categories: "",
      },
    },
    active_payment: {},
    loading: false,
  }),

  getters: {
    config: (state) => {
      return state.config;
    },
    social: (state) => {
      return state.social;
    },
    active_payment: (state) => {
      return state.active_payment;
    },
    loadingConfig: (state) => {
      return state.loading;
    },
  },
  mutations: {
    SET_CONFIG(state, payload) {
      state.config = payload;
    },
    SET_SOCIAL(state, payload) {
      state.social = payload;
    },
    SET_ACTIVE_PAYMENT(state, payload) {
      state.active_payment = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async getSiteInfo({ commit }) {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get("/config");
        if (response.status === 200) {
          commit("SET_CONFIG", response.data);
          commit("SET_SOCIAL", response.data.social);
          commit("SET_ACTIVE_PAYMENT", response.data.payment_channels.active);
        }
      } catch (error) {
        // يمكنك إضافة إجراءات للتعامل مع الخطأ هنا
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
};
