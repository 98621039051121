<template>
  <div class="mx-auto col-12 col-lg-3 my-5">
    <reset-password-component />
  </div>
</template>

<script>
import ResetPasswordComponent from "@/components/Auth/ResetPasswordComponent";

export default {
  name: "ResetPasswordView",
  components: { ResetPasswordComponent },
};
</script>

<style scoped></style>
