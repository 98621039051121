<template>
  <div class="scroll-table-mobile mb-5">
    <!-- <small-spring-loading :is-loading="Loading"></small-spring-loading> -->

    <div class="tabs-breadcrumb d-flex align-items-center align-content-center">
      <div
        class="breadcrumb-item me-0 me-md-5 ms-0 py-3 d-flex align-items-center align-content-center"
      >
        <a
          href="#"
          class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
        >
          <h6 class="head fs-5 text-light-dark-mode mb-0">الشهادات</h6>
        </a>
        <img
          src="../../assets/icon/arrow-left.svg"
          alt="arrow-left"
          class="mx-2"
        />
      </div>

      <div
        class="profile-tab flex-fill d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-content-center gap-4">
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myCertificateStatistics' }"
            @click.prevent="activeTabe = 'myCertificateStatistics'"
          >
            المكتملة
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'results' }"
            @click.prevent="activeTabe = 'results'"
          >
            فلترة النتائج
          </a>
          <a
            class="shamel fs-6 py-3 text-decoration-none cursor-pointer text-light-dark-mode"
            :class="{ active: activeTabe === 'myCertificate' }"
            @click.prevent="activeTabe = 'myCertificate'"
          >
            شهاداتى
          </a>
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <div v-if="activeTabe === 'myCertificateStatistics'">
        <p class="text-light-dark-mode">إحصائيات شهاداتى</p>

        <div class="box">
          <div class="row">
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center">
                <div class="img-result">
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>0</span>
                  <p>الشهادات</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center">
                <div class="img-result">
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>0</span>
                  <p>متوسطة الدرجة</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box-result d-flex align-items-center">
                <div class="img-result">
                  <img src="../../assets/icon-profile.svg" alt="" />
                </div>
                <div class="box_info d-flex flex-column">
                  <span>0</span>
                  <p>اختبارات غير مجتازة</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTabe === 'results'" class="mb-4">
        <p class="text-light-dark-mode">فلترةالنتائج</p>
        <form action="#">
          <div class="row align-items-center">
            <div class="col-lg-2">
              <label for="datepicker-sm">من</label>
              <b-form-datepicker
                id="datepicker-sm"
                size="sm"
                locale="ar"
                class="mb-2"
              ></b-form-datepicker>
            </div>
            <div class="col-lg-2">
              <label for="">الى</label>
              <div class="border-1">
                <b-form-datepicker
                  id="datepicker-sm"
                  size="sm"
                  locale="ar"
                  class="mb-2"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-lg-2">
              <label>كورس</label>
              <b-form-select
                v-model="selected"
                :options="options"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-lg-2">
              <label>اختبار</label>
              <b-form-select
                v-model="selected"
                :options="options"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-lg-2">
              <label>الدرجة</label>
              <b-form-select
                v-model="selected"
                :options="options"
                class="mt-3"
              ></b-form-select>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <button class="btn btn-blue">عرض النتائج</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div v-if="activeTabe === 'myCertificate'">
        <p class="text-light-dark-mode">شهاداتى</p>
        <!-- {{ this.certificates }} -->
        <div>
          <!-- {{ certificate.answer_sheet }} -->
          <!-- <li v-for="(answer, index) in certificate.answer_sheet" :key="index">
            {{ answer }}
          </li> -->
          <div class="table-responsive">
            <table class="table text-center">
              <thead class="text-light-dark-mode">
                <tr>
                  <th>شهادة</th>
                  <th class="text-center">معرف الشهادة</th>
                  <!-- <th class="text-center">اقل درجة</th>
                  <th class="text-center">متوسط الدرجة</th> -->
                  <th class="text-center">درجتي</th>
                  <th class="text-center">التاريخ</th>
                </tr>
              </thead>
              <tbody class="text-gray-dark-mode">
                <tr v-for="(certificate, index) in certificates" :key="index">
                  <td class="text-left">
                    {{ certificate.webinar.title }}
                  </td>
                  <td
                    class="align-middle"
                    v-if="certificate.certificate !== null"
                  >
                    {{ certificate.certificate.id }}
                  </td>
                  <td v-else>---</td>
                  <!-- <td class="align-middle">
                    <span class="text-dark-blue font-weight-500">{{
                      certificate.quiz.pass_mark
                    }}</span>
                  </td> -->
                  <!-- <td class="align-middle">
                    <span class="text-dark-blue font-weight-500">100</span>
                  </td> -->
                  <td class="align-middle">{{ certificate.user_grade }}</td>
                  <td class="align-middle">
                    <span class="text-dark-blue font-weight-500">{{
                      certificate.created_at
                    }}</span>
                  </td>
                  <td class="align-middle font-weight-normal">
                    <div
                      class="btn-download"
                      style=""
                      v-if="certificate.certificate !== null"
                    >
                      <a
                        :href="certificate.certificate.file"
                        target="_blank"
                        class="webinar-actions d-block text-decoration-none"
                        >تنزيل / عرض</a
                      >
                      <!-- <a
                        href="https://coursestore.com/panel/quizzes/results/843/downloadCertificate"
                        class="webinar-actions d-block mt-10"
                        >تنزيل</a
                      > -->
                    </div>
                    <div v-else>--</div>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text-left">
                    <span class="d-block text-dark-blue font-weight-500"
                      >للحصول على شهادة الاجتياز</span
                    >
                    <span class="d-block font-12 text-gray mt-5"
                      >احتراف الجداول المحورية في الإكسيل | Pivot Tables</span
                    >
                  </td>
                  <td class="align-middle">403</td>
                  <td class="align-middle">
                    <span class="text-dark-blue font-weight-500">100</span>
                  </td>
                  <td class="align-middle">
                    <span class="text-dark-blue font-weight-500">100</span>
                  </td>
                  <td class="align-middle">100</td>
                  <td class="align-middle">
                    <span class="text-dark-blue font-weight-500"
                      >6/December 2022</span
                    >
                  </td>
                  <td class="align-middle font-weight-normal">
                    <div class="btn-group dropdown table-actions">
                      <button
                        type="button"
                        class="btn-transparent dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-more-vertical"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg>
                      </button>
                      <div class="dropdown-menu" style="">
                        <a
                          href="https://coursestore.com/panel/quizzes/results/843/showCertificate"
                          target="_blank"
                          class="webinar-actions d-block"
                          >متاحة</a
                        >
                        <a
                          href="https://coursestore.com/panel/quizzes/results/843/downloadCertificate"
                          class="webinar-actions d-block mt-10"
                          >تنزيل</a
                        >
                      </div>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
        <!-- <button
          :disabled="disable_load_more"
          v-if="showLoadMore"
          class="btn btn-coursestore mx-auto d-block"
          @click.prevent="loadMore"
        >
          <span class="text-white">المزيد</span>
        </button> -->
      </div>
      <div class="text-center mt-4" v-else>
        <p class="text-light-dark-mode">انت لا تمتلك اى شهادات</p>
        <span class="text-gray-dark-mode">
          تستطيع الحصول على شهادات عن طريق الانضمام الى احد الكورسات
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "profileConsult",
  data() {
    return {
      activeTabe: "myCertificateStatistics",
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
    };
  },
  computed: {
    ...mapGetters("certificates", ["certificates"]),
  },
  created() {
    this.getCertificates();
  },
  methods: {
    ...mapActions("certificates", ["getCertificates"]),
  },
};
</script>

<style scoped>
form {
  padding: 25px 10px;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: var(--bs-border-radius) !important;
}
.dark-theme form {
  box-shadow: rgb(183 180 180 / 31%) 0 5px 15px 0;
}
.btn-blue {
  background: #410099 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  width: 174px;
  height: 40px;
  margin-top: 19px;
}
.btn-blue:hover {
  background-color: #36037d;
  color: #fff;
}
.b-form-datepicker {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #ccc !important;
}
#datepicker-sm {
  box-shadow: none !important;
}
input {
  width: 100%;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.45rem 0.5rem;
  font-size: 0.875rem;
  outline: none !important;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
}
select {
  width: 100%;
  padding: 6px;
  margin: 0 !important;
  outline: none;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #6c757d !important;
}
label {
  color: #36037d;
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}
.btn,
button {
  box-shadow: none !important;
}
.dark-theme label {
  color: #fff;
}
.title-content-tab {
  color: #36037d;
  font-weight: 600;
}
.box {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: 8px;
  padding: 30px;
}
.dark-theme .box {
  background-color: #1c1c1c;
}
.box_info span,
.box_info p {
  font-size: 16px;
  font-weight: bold;
  color: #36037d;
}
.dark-theme .box_info span {
  color: gray;
}
.dark-theme .box_info p {
  color: #fff;
}
.img-result {
  width: 100px;
  text-align: end;
}
.webinar-actions {
  background-color: #410099;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
}
@media (max-width: 768px) {
  table {
    width: 150%;
    overflow-x: auto;
  }
  .btn-download {
    width: 100px;
    padding: 3px;
  }
  .box {
    padding: 10px;
  }
  .box-result {
    margin-bottom: 10px;
  }
  .box_info span,
  .box_info p {
    font-size: 12px;
  }
  .img-result {
    width: auto !important;
  }
}
</style>
