import axios from "axios";

export default {
  async Search(keyword) {
    return await axios.get("/search/by-term", {
      params: {
        term: keyword,
        return: "json",
      },
    });
  },
};
