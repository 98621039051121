import course_request from "@/api/course_request";
import offerRequest from "@/api/offers_request";

export default {
  namespaced: true,
  state: () => ({
    courses_list: [],
    courses_list_persons: [],
    courses_list_offers: [],
    current_video: {},
    progress: 0,
    offset: 0,
    course: {},
    haveDate: false,
    isLoadData: true,
    isLoadDataPerson: true,
    onlineVideoUrl: "",
    videDuration: 0,
    comments: [],
    reviews: [],
    haveMoreComment: true,
    haveMoreViews: true,
    loadingComments: false,
    loadingReviews: false,
    otpActive: "",
    video_opt: { otp: "", playbackInfo: "" },
    prerequisites: [],
  }),
  getters: {
    course: (state) => {
      return state.course;
    },
    current_video: (state) => {
      return state.current_video;
    },
    progress: (state) => {
      return state.progress;
    },
    comments: (state) => {
      return [].concat.apply([], state.comments);
    },
    reviews: (state) => {
      return [].concat.apply([], state.reviews);
    },
    onlineVideoUrl: (state) => {
      return state.onlineVideoUrl;
    },
    videDuration: (state) => {
      return state.videDuration;
    },
    courses_list: (state) => {
      return [].concat.apply([], state.courses_list);
    },
    courses_list_persons: (state) => {
      return [].concat.apply([], state.courses_list_persons);
    },
    courses_list_offers: (state) => {
      return [].concat.apply([], state.courses_list_offers);
    },
    offset: (state) => {
      return state.offset;
    },
    haveDate: (state) => {
      return state.haveDate;
    },
    loadingComments: (state) => {
      return state.loadingComments;
    },
    haveMoreComment: (state) => {
      return state.haveMoreComment;
    },
    loadingReviews: (state) => {
      return state.loadingReviews;
    },
    haveMoreViews: (state) => {
      return state.haveMoreViews;
    },
    isLoadData: (state) => {
      return state.isLoadData;
    },
    isLoadDataPerson: (state) => {
      return state.isLoadDataPerson;
    },
    otpActive: (state) => {
      return state.otpActive;
    },
    video_opt: (state) => {
      return state.video_opt;
    },

    prerequisites: (state) => {
      return state.prerequisites;
    },
  },
  mutations: {
    SET_LIST: (state, payload) => {
      state.courses_list.push(payload);
    },
    SET_LIST_IN_PERSON_LESSON: (state, payload) => {
      state.courses_list_persons = [];
      state.courses_list_persons.push(payload);
    },
    SET_LIST_OFFERS: (state, payload) => {
      state.courses_list_offers.push(payload);
    },
    SET_CURRENT_VIDEO_DATA: (state, payload) => {
      state.current_video = payload;
    },
    SET_PROGRESS: (state, payload) => {
      state.progress = payload;
    },
    SET_COURSE_DETAILS: (state, payload) => {
      state.course = payload;
    },
    RESET_STATE_COURSE: (state) => {
      state.courses_list = [];
    },
    RESET_OFFSET: (state) => {
      state.offset = 0;
    },
    SET_OFFSET: (state) => {
      state.offset += 10;
    },
    SET_OFFSET_OFFERS: (state) => {
      state.offset += 10;
    },
    SET_ISLOADING: (state, payload) => {
      state.isLoadData = payload;
    },
    SET_IS_LOADING: (state, payload) => {
      state.isLoadDataPerson = payload;
    },
    SET_HAVEDATA: (state, payload) => {
      state.haveDate = payload;
    },
    SET_ONLINE_VIDEO_URL: (state, payload) => {
      state.onlineVideoUrl = payload;
    },
    SET_VIDE_DUR: (state, payload) => {
      state.videDuration = payload;
    },
    SET_COMMENTS: (state, payload) => {
      state.comments.push(payload);
    },
    SET_REVIEWS: (state, payload) => {
      state.reviews.push(payload);
    },
    SetLoadingComments: (state, payload) => {
      state.loadingComments = payload;
    },
    SetHaveMoreComment: (state, payload) => {
      state.haveMoreComment = payload;
    },
    SetLoadingReview: (state, payload) => {
      state.loadingReviews = payload;
    },
    SetHaveMoreReview: (state, payload) => {
      state.haveMoreViews = payload;
    },
    SET_ACTIVE_OTP: (state, payload) => {
      state.otpActive = payload;
    },
    SET_VIDEO_OTP: (state, payload) => {
      state.video_opt = payload;
    },
    SET_PREREQUISITES: (state, payload) => {
      state.prerequisites = payload;
    },
  },
  actions: {
    async getAllCourses({ commit }, payload) {
      commit("SET_ISLOADING", true);
      commit("SET_HAVEDATA", false);
      if (payload.resetState) {
        commit("RESET_STATE_COURSE", true);
        commit("RESET_OFFSET");
      }
      await course_request
        .fetchAll(
          payload.offset,
          payload.cat,
          payload.is_less_data,
          payload.limit,
          payload.type,
          payload.without_attendance
        )
        .then((response) => {
          console.log("response.status", response);
          if (response.status === 200 && response.data.success === true) {
            commit("SET_LIST", response.data.data);
            if (response.data.data.length > 0) {
              commit("SET_HAVEDATA", true);
              commit("SET_OFFSET_OFFERS");
            } else {
              commit("SET_HAVEDATA", false);
            }
            commit("SET_ISLOADING", false);
          }
        });
    },
    async getAllPersonLessons({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      commit("SET_HAVEDATA", false);
      if (payload.resetState) {
        commit("RESET_STATE_COURSE", true);
        commit("RESET_OFFSET");
      }
      await course_request
        .fetchAll(
          payload.offset,
          payload.cat,
          payload.is_less_data,
          payload.limit,
          payload.type
        )
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_LIST_IN_PERSON_LESSON", response.data.data);
            if (response.data.data.length > 0) {
              commit("SET_HAVEDATA", true);
              commit("SET_OFFSET_OFFERS");
            } else {
              commit("SET_HAVEDATA", false);
            }
            commit("SET_IS_LOADING", false);
          }
        });
    },
    async getAllOffersCourses({ commit }, payload) {
      // console.log(payload);
      commit("SET_ISLOADING", true);
      commit("SET_HAVEDATA", false);

      await offerRequest
        .getOffers(payload.limit, payload.offset)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_LIST_OFFERS", response.data.data);
            if (response.data.data.length > 0) {
              commit("SET_HAVEDATA", true);
              commit("SET_OFFSET");
            } else {
              commit("SET_HAVEDATA", false);
            }
            commit("SET_ISLOADING", false);
          }
        });
    },
    async getCourseByFilter({ commit }, payload) {
      commit("SET_ISLOADING", true);
      commit("SET_HAVEDATA", false);
      if (payload.reset) {
        commit("RESET_STATE_COURSE", true);
        commit("RESET_OFFSET");
      }
      await course_request
        .getCourseByFilter(payload.filter)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            commit("SET_LIST", response.data.data);
            if (response.data.data.length) {
              commit("SET_HAVEDATA", true);
            } else {
              commit("SET_HAVEDATA", false);
            }
            commit("SET_ISLOADING", false);
          }
        });
    },
    async getCourseById({ commit }, slug) {
      commit("SET_ISLOADING", true);
      await course_request.getCourseById(slug).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          commit("SET_COURSE_DETAILS", response.data.data);
        }
        commit("SET_ISLOADING", false);
      });
    },
    async playVideo({ commit }, take) {
      commit("SET_ONLINE_VIDEO_URL", "");
      if (window.innerWidth < 768) {
        window.scrollTo(0, 0);
      }
      try {
        let response;
        if (take.item.storage === "online") {
          //console.log("take", take.item.file);
          commit("SET_ONLINE_VIDEO_URL", take.item.file);
        } else if (take.type === "promo" || take.type === "") {
          response = await course_request.getPromoVideoById({
            id: take.course.id,
          });
        } else {
          response = await course_request.getVideoByFileId({
            id: take.item.id,
          });
        }
        if (response?.status === 200 && response?.data?.success) {
          commit("SET_VIDEO_OTP", response.data.data);
          commit("SET_CURRENT_VIDEO_DATA", take);
        } else {
          this.$swal.fire({
            icon: "error",
            title: response?.data?.data?.message || "Unknown error occurred",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // playVideo: function ({ commit }, take) {
    //   let responce =
    //     take.type === "promo" || take.type === ""
    //       ? course_request.getPromoVideoById({ id: take.course.id })
    //       : course_request.getVideoByFileId({ id: take.item.id });
    //   responce.then((response) => {
    //     if (response.status === 200) {
    //       if (response.data.success === true) {
    //         commit("SET_VIDEO_OTP", response.data.data);
    //         commit("SET_CURRENT_VIDEO_DATA", take);
    //         if (window.innerWidth < 768) {
    //           // execute only on mobile screens
    //           window.scrollTo(0, 0); // scroll up window
    //         }
    //       } else {
    //         this.$swal.fire({
    //           icon: "error",
    //           title: response.data.data.message,
    //         });
    //       }
    //     }
    //   });
    // },
    async getComments({ commit }, data) {
      commit("SetLoadingComments", true);
      commit("SetHaveMoreComment", false);
      await course_request.getComment(data).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          if (response.data.data.length) {
            commit("SetHaveMoreComment", true);
          }
          commit("SET_COMMENTS", response.data.data);
          commit("SetLoadingComments", false);
        }
      });
    },
    async getReviews({ commit }, payload) {
      commit("SetLoadingReview", true);
      commit("SetHaveMoreReview", false);
      await course_request.getReviews(payload).then((response) => {
        if (response.status === 200 && response.data.success === true) {
          if (response.data.data.length) {
            commit("SetHaveMoreReview", true);
          }
          commit("SET_REVIEWS", response.data.data);
          commit("SetLoadingReview", false);
        }
      });
    },
    async getPrerequisites({ commit }, payload) {
      commit("SET_PREREQUISITES", []);
      await course_request
        .getPrerequisitesByCourse(payload)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.webinar.length > 0) {
              commit("SET_PREREQUISITES", response.data.data.webinar);
            } else if (!response.data.data.webinar.length) {
              commit("SET_PREREQUISITES", []);
            }
          }
        });
    },
  },
};
