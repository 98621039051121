<template>
  <div>
    <page-component :link="link"></page-component>
  </div>
</template>

<script>
import PageComponent from "@/components/Page/PageComponent";

export default {
  name: "PageView",
  components: { PageComponent },
  data() {
    return {
      link: "",
    };
  },

  created() {
    this.link = this.$route.params.link;
  },
  watch: {
    $route(newVal) {
      this.link = newVal.params.link;
    },
  },
};
</script>

<style scoped></style>
