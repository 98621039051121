import axios from "axios";

export default {
  async geBundles(limit, offset, instructor_id, category_id) {
    return await axios.get("/bundles", {
      params: {
        limit: limit,
        offset: offset,
        sort: "newest",
        instructor_id: instructor_id,
        category_id: category_id,
      },
    });
  },
  async getDemoVideo(id) {
    return await axios.get(`/bundles/demo-video/${id}`);
  },
  async getCategories() {
    return await axios.get("/bundle-categories");
  },
  async getBundleDetails(id) {
    return await axios.get(`/bundles/${id}`);
  },

  async getBundleComments(payload) {
    return await axios.get(`/bundles/comments/${payload.bundle_id}`, {
      params: {
        limit: payload.limit,
        offset: payload.offset,
      },
    });
  },

  async getMyBundle() {
    return await axios.get(`/panel/bundle/my-bundles`);
  },
  async getBundleSuggests(id, payload) {
    return await axios.get(`/bundles/${id}/suggests`, payload);
  },
};
