import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueCardFormat from "vue-credit-card-validation";
import VueAwesomeSwiper from "vue-awesome-swiper";
import InfiniteLoading from "vue-infinite-loading";
import "swiper/css/swiper.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VCalendar from "v-calendar";
import DatePicker from "v-calendar";
import jQuery from "jquery";
import VueSweetalert2 from "vue-sweetalert2";
import VueSocialSharing from "vue-social-sharing";
import appConfig from "./config";
import "./import";
import auth_helper from "@/helpers/auth_helper";

Vue.use(InfiniteLoading, {
  /* options */
});

//***** start define VCalendar *****/

Vue.component("v-date-picker", DatePicker);
Vue.use(VCalendar);
//***** end define VCalendar *****/

global.$ = jQuery;

Vue.use(VueTelInput);
Vue.use(VueSweetalert2);
Vue.use(VueCardFormat);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

store.subscribe((mutation) => {
  switch (mutation.type) {
    case "auth/SET_TOKEN": {
      let api_token = mutation.payload;

      if (typeof api_token === "string" && api_token.length > 0) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Basic ${appConfig.HttpToken} ,Bearer ${api_token}`;
        auth_helper.setAUthData(api_token);
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Basic ${appConfig.HttpToken}`;
        auth_helper.setAUthData(null);
      }
    }
  }
});
let filter = function (text, length, clamp) {
  clamp = clamp || "...";
  let node = document.createElement("div");
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
let truncateRate = function (text, length, clamp) {
  clamp = clamp || "";
  let node = document.createElement("div");
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);
Vue.filter("truncate", truncateRate);
/* add header x-api-key token */
axios.defaults.headers.common["x-api-key"] = "coursestore123456";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

axios.defaults.baseURL = appConfig.API_URL;

axios.mode = "no-cors";

/*axios.interceptors.request.use((config) => {
  console.log(
    "axios config",
    config.method,
    config.headers.common.Authorization
  );
  //app.$Progress.start(); // for every request start the progress
  return config;
});

axios.interceptors.response.use((response) => {
  app.$Progress.finish(); // finish when a response is received
  return response;
});*/

Vue.config.performance = process.env.NODE_ENV !== "production";
store.dispatch("auth/getUser").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
