<template>
  <div
    id="navbar-categories"
    class="nav-item position-relative dropdown align-items-center d-flex"
  >
    <a
      class="nav-link-cat fs-sm-12"
      id="dropdownMenuButton2"
      aria-expanded="false"
      data-bs-toggle="dropdown"
      aria-current="page"
      href="#"
    >
      <div
        class="d-flex align-items-center justify-content-between gap-1"
        style="display: inline"
      >
        <img
          src="../../assets/js/icon-basicui-grid.svg"
          class="img-grid-icon"
          alt="img-grid-icon"
        />
        <img
          src="../../assets/js/grid-dark.svg"
          class="img-grid-icon-dark"
          alt="img-grid-icon"
        />
        <span class="hide-mobile">المجالات</span>
        <img
          src="../../assets/js/icon-arrow-chevron-down.svg"
          class="icon-dwon px-1 hide-mobile"
          alt="icon-dwon"
        />
      </div>
    </a>
    <div
      aria-labelledby="dropdownMenuButton2"
      @click.stop
      class="dropdown-menu mgalat position-absolute w-100 cat-dropdown-menu-main"
    >
      <ul
        class="cat-dropdown-menu"
        style="overflow: auto; max-height: 345px"
        v-if="Object.keys(categories).length > 0 && showCourseCategoryList"
      >
        <li class="py-3" v-for="(category, index) in categories" :key="index">
          <a @click.prevent="showCoursesByCategory(category)">
            <div class="d-flex align-items-center">
              <img
                :src="category.icon"
                class="cat-dropdown-menu-icon mr-10"
                :alt="category.title"
              />
              <span class="text-grey ms-2"> {{ category.title }} </span>
            </div>
          </a>
        </li>
      </ul>
      <div
        v-if="!showCourseCategoryList"
        @click.prevent="closeSubCourseCategory()"
      >
        <div
          class="d-flex justify-content-between align-items-center m-2 rounded bg-cs-primary"
        >
          <h1 class="fs-12 m-2 text-white">
            {{ selectCategoryTitle }}
          </h1>
          <span>
            <img
              class="cursor-pointer w-100"
              @click.prevent="closeSubCourseCategory()"
              src="../../assets/icon/cs-exit.svg"
            />
          </span>
        </div>
        <div class="text-center">
          <small-spring-loading :is-loading="isLoading"> </small-spring-loading>
        </div>
        <ul
          style="overflow: auto; max-height: 300px"
          class="p-0 my-0 mx-0"
          v-if="
            courseCategoryList[this.selectCategoryId] !== undefined &&
            courseCategoryList[this.selectCategoryId].length > 0
          "
        >
          <li
            class="rounded bg-white shadow-sm m-2"
            v-for="(course, course_index) in courseCategoryList[
              this.selectCategoryId
            ]"
            :key="course_index"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex justify-content-between align-items-center gap-2"
              >
                <router-link
                  :to="{ name: 'course', params: { slug: course.slug } }"
                >
                  <div
                    class="image-list rounded"
                    :style="{ 'background-image': `url(${course.image})` }"
                  ></div>
                </router-link>
                <router-link
                  class="fw-bold text-start mx-1 text-truncate"
                  :to="{ name: 'course', params: { slug: course.slug } }"
                >
                  <h2 class="fs-12">
                    {{ course.title | truncate(15, " ...") }}
                  </h2>
                  <h2 class="fs-10">
                    {{ course.teacher.full_name }}
                  </h2>
                </router-link>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="color: #818894"
              >
                <path
                  d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                ></path>
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import course_request from "@/api/course_request";

export default {
  name: "NavbarCategories",
  data() {
    return {
      isLoading: true,
      showCourseCategoryList: true,
      selectCategoryId: 0,
      selectCategoryTitle: "",
      courseCategoryList: {},
    };
  },
  components: { SmallSpringLoading },
  computed: {
    ...mapGetters("home", ["categories"]),
  },
  methods: {
    closeSubCourseCategory() {
      this.showCourseCategoryList = true;
    },
    showCoursesByCategory(category) {
      this.selectCategoryTitle = category.title;
      this.selectCategoryId = category.id;
      this.showCourseCategoryList = false;
      this.isLoading = true;
      if (!this.courseCategoryList[category.id]) {
        this.courseCategoryList[category.id] = new Array();
      }

      course_request
        .fetchAll(0, category.id, 10, true)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            this.courseCategoryList[category.id] = response.data.data;
            /*response.data.data.forEach((element) => {
              this.courseCategoryList[category.id].push(element);
            });*/
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    let elem = document.getElementById("navbar-categories");
    let nav_search = document.getElementById("navbar-search");
    if (typeof elem !== "undefined" && elem !== null) {
      elem.addEventListener("click", function (e) {
        e.stopPropagation();

        nav_search.classList.remove("show");
      });
    }
  },
  watch: {
    categories(newVal) {
      if (Object.keys(newVal).length > 0) {
        this.isLoading = false;
      }
    },
    watch: {
      $route() {
        document.getElementById("navbar-categories").classList.remove("show");
      },
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  #navbar-categories .hide-mobile {
    display: none !important;
  }
}
.nav-item {
  margin-inline: 5px;
}
.nav-link-cat {
  text-align: center;
  text-decoration: none;
  max-width: 150px;
  background: #f1f1f1;
  padding: 5px;
  border-radius: 5px;
  color: #1c1d1f;
  font-weight: bold;
  /* transition: all 0.3s ease-in-out; */
}
.img-grid-icon {
  width: 15px;
}
.dark-theme .nav-link-cat {
  background-color: #000;
  color: #fff;
}
.icon-dwon {
  width: 20px;
}
.dropdown-menu.mgalat {
  width: 300px !important;
  cursor: pointer;
  background-color: #fff;
  z-index: 9999;
}
.dark-theme .dropdown-menu.mgalat {
  background-color: #1c1d1f;
}
.dropdown-menu.mgalat li::marker {
  content: none !important;
}
.dropdown-menu.mgalat li a {
  text-decoration: none !important;
}
.dropdown-menu.mgalat li a span {
  color: gray !important;
}
.dropdown-menu.mgalat li a span:hover {
  color: var(--nav-active-1) !important;
}
.dropdown-menu.mgalat img {
  width: 9%;
}
.dropdown-menu.mgalat:after {
  left: 90%;
}

.bg-cs-primary {
  background-color: var(--nav-active) !important;
}
.image-list {
  width: 90px !important;
  height: 75px !important;
  background-position: center !important;
  background-size: cover !important;
}
svg {
  min-width: 24px !important;
}
.dropdown-menu::after {
  background-color: var(--nav-dropdown-bg);
}
.dark-theme .cat-dropdown-menu-icon {
  display: none;
}
.dark-theme .text-white,
.dark-theme a h1 {
  color: #1c1d1f !important;
}

@media only screen and (max-width: 600px) {
  .fs-sm-12 {
    font-size: 15px !important;
  }

  .dropdown-menu.mgalat {
    max-width: 100%;
    top: 95% !important;
    width: 100% !important;
  }
  .cat-dropdown-menu-main::after {
    right: 55%;
  }
  .position-relative,
  .nav-item .nav-link {
    position: inherit !important;
  }
}
.img-grid-icon-dark {
  display: none;
}
.dark-theme .img-grid-icon-dark {
  display: block;
  width: 15px;
  margin: 0 3px;
  background-color: transparent;
}
.dark-theme .img-grid-icon {
  display: none;
}
.dark-theme .bg-white {
  background-color: #1c1d1f !important;
  color: #fff !important;
}
</style>
