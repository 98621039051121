<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="breadcrumb-item me-0 me-md-5 ms-0 py-3">
        <a
          href="#"
          class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
          >إعدادات الحساب</a
        >
        <img
          src="../../assets/icon/arrow-left.svg"
          alt="arrow-left"
          class="mx-2"
        />
      </div>
      <div class="delete-account" v-if="user && user.role_name === 'user'">
        <button
          @click.prevent="removeAccount"
          :disabled="btn_disabled"
          type="button"
          class="btn btn-danger"
        >
          <img
            src="../../assets/icon/cs-trash-white.svg"
            alt="delete account icon"
          />
          <span class="text-light">حذف الحساب</span>
        </button>
      </div>
    </div>
    <b-tabs card>
      <div class="container mt-3">
        <div class="cart p-3 rounded-3">
          <div v-if="errors.length > 0" class="alert alert-danger">
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
      <b-tab no-body title="معلومات أساسية">
        <b-card-footer>
          <div class="col-12 col-lg-6 mb-1">
            <div class="form-group">
              <GlobalInput
                type="text"
                label=" الاسم"
                v-model="updateUser.full_name"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <div class="form-group">
              <GlobalInput
                type="text"
                label=" اللغة"
                v-model="updateUser.language"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <div class="form-group">
              <GlobalInput
                type="email"
                label="البريد الإلكترونى"
                v-model="updateUser.email"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <div class="form-group">
              <GlobalInput
                type="email"
                label="كلمة المرور"
                v-model="updateUser.password"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <div class="form-group">
              <GlobalInput
                type="tel"
                label="رقم الهاتف"
                v-model="updateUser.mobile"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-1">
            <div class="form-group text-light-dark-mode">
              <input
                type="checkbox"
                id="public_message"
                :disabled="input_disabled"
                checked="checked"
                v-model="updateUser.newsletter"
              />
              <label for="public_message" class="form-label"
                >انصم للقائمة البريدية</label
              >
            </div>
          </div>
        </b-card-footer>
      </b-tab>
      <b-tab no-body title="عن ">
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <label for="about" class="form-label text-light-dark-mode"
              >عن</label
            >
            <textarea
              class="form-control px-1"
              placeholder="عن"
              id="about"
              :disabled="input_disabled"
              v-model="updateUser.about"
            >
            </textarea>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <label for="bio" class="form-label text-light-dark-mode"
              >نبذه عنك</label
            >
            <textarea
              class="form-control px-1"
              placeholder="نبذه عنك"
              id="bio"
              :disabled="input_disabled"
              v-model="updateUser.bio"
            >
            </textarea>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex align-items-end form-check-info">
            <div class="form-check">
              <p>النوع</p>
              <label class="form-check-label button-checkbox">
                <input
                  type="radio"
                  class="form-check-input visually-hidden"
                  name="gender"
                  id="male"
                  value="male"
                  v-model="updateUser.gender"
                />
                <span class="button-label">ذكر</span>
              </label>
              <span class="mx-2"></span>
              <label class="form-check-label button-checkbox">
                <input
                  type="radio"
                  class="form-check-input visually-hidden"
                  name="gender"
                  id="female"
                  value="female"
                  v-model="updateUser.gender"
                />
                <span class="button-label">انثى</span>
              </label>
            </div>
            <div class="form-check">
              <div class="day">
                <div class="form-group">
                  <p>تاريخ الميلاد</p>
                  <b-form-select
                    class="form-control day"
                    v-model="birthday.selectedDay"
                  >
                    <option v-for="day in days" :key="day" :value="day">
                      {{ day }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </div>
            <div class="form-check">
              <div class="day">
                <div class="form-group">
                  <b-form-select
                    class="form-control day"
                    v-model="birthday.selectedMonth"
                  >
                    <option
                      v-for="(month, index) in months"
                      :key="index"
                      :value="index + 1"
                    >
                      {{ month }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </div>
            <div class="form-check">
              <div class="day">
                <div class="form-group">
                  <!-- year -->
                  <b-form-select
                    class="form-control day"
                    v-model="birthday.selectedYear"
                  >
                    <option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 text-center">
            <input type="text" v-model="birthDate" hidden />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <label for="public_message" class="form-label text-light-dark-mode"
              >تفعيل رسائل الملف الشخصي</label
            >

            <input
              type="checkbox"
              id="public_message"
              :disabled="input_disabled"
              checked="checked"
              v-model="updateUser.public_message"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="العنوان">
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="العنوان"
              v-model="updateUser.address"
            />
          </div>
        </div>

        <div class="col-12 col-lg-6 mb-4">
          <div class="form-group">
            <label for="country">الدولة</label>
            <!-- <v-select
              v-model="updateUser.country_id"
              :options="countryOptions"
              :label="name"
              dir="rtl"
            ></v-select> -->
            <select
              class="form-control day"
              name=""
              id="country"
              placeholder="الدولة"
              v-model="updateUser.country_id"
            >
              <option
                v-for="country in countryOptions"
                :key="country.id"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="المحافظة"
              v-model="updateUser.province_id"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="المدينة"
              v-model="updateUser.city_id"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="المالية">
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="اسم البنك"
              v-model="updateUser.account_type"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="اسم الفرع"
              v-model="updateUser.address"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="رقم الايبان"
              v-model="updateUser.iban"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="السويفت كود"
              v-model="updateUser.swift"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <div class="form-group">
            <GlobalInput
              type="text"
              label="رقم الحساب"
              v-model="updateUser.account_id"
            />
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div class="col-12 mt-1">
      <div class="form-group">
        <button
          @click.prevent="updateInformation()"
          type="submit"
          :disabled="input_disabled"
          class="btn btn-coursestore"
        >
          <img src="../../assets/icon/cs-save.svg" alt="save" />
          <span class="text-white">حفظ</span>
        </button>
        <small-spring-loading :is-loading="isLoading"></small-spring-loading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import profile_request from "@/api/profile_request";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";

export default {
  name: "user-profile-update",
  components: {
    SmallSpringLoading,
    GlobalInput,
  },
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const endYear = currentYear + 10;
    const years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    const months = [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    return {
      years,
      months,
      days,
      btn_disabled: false,
      input_disabled: true,
      isLoading: false,
      errors: [],
      client: {},
      countryOptions: [],
      updateUser: {},
      birthday: {
        selectedDay: null,
        selectedMonth: null,
        selectedYear: null,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    birthDate() {
      const year = this.birthday.selectedYear?.toString().padStart(4, "0");
      const month = this.birthday.selectedMonth?.toString().padStart(2, "0");
      const day = this.birthday.selectedDay?.toString().padStart(2, "0");
      if (year && month && day) {
        return `${year}-${month}-${day}`;
      }
      return null;
    },
  },
  created() {
    this.getSettings();
    this.getCountry();
  },
  methods: {
    ...mapActions("auth", ["logOut", "updateProfile"]),
    async removeAccount() {
      this.btn_disabled = true;
      this.$swal
        .fire({
          title: "هل انت متاكد من اجراء عملية الحذف",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "حذف",
          denyButtonText: `الغاء`,
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await profile_request
              .removeAccount()
              .then((response) => {
                this.$swal.fire({
                  icon:
                    response.data.status === "success" ? "success" : "error",
                  title: response.data.message,
                });
                if (response.data.status === "success") {
                  this.logOut();
                }
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  title: error.getMessage(),
                });
              })
              .finally(() => {
                this.btn_disabled = false;
              });
          } else if (result.isDenied) {
            this.btn_disabled = false;
          }
        });
    },
    async updateInformation() {
      this.updateUser.birthdate = this.birthDate;
      this.isLoading = true;
      await this.updateProfile(this.updateUser)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === false) {
              if (response.data.status === "validation_error") {
                let _errors = response.data.data.errors;
                Object.values(_errors).forEach((val) => {
                  val.forEach((error) => {
                    this.errors.push(error);
                  });
                });
              }
            } else if (response.data.success === true) {
              this.$swal.fire(response.data.message);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    doLogOut() {
      this.logOut();
      this.isLogin = false;
      this.$router.push({ path: "/" }).catch(() => {});
    },

    async getSettings() {
      this.isLoading = true;
      await profile_request
        .getAccountSetting()
        .then((response) => {
          if (response.status === 200) {
            this.input_disabled = false;
            this.updateUser = response.data.data.user;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getCountry() {
      this.isLoading = true;
      await profile_request
        .getCountry()
        .then((response) => {
          if (response.status === 200) {
            this.countryOptions = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check-info {
  .day {
    margin-inline: 10px;
  }
}
.form-check {
  margin-top: 10px;
  padding-right: 0;
  .custom-select {
    margin-right: 10px;
  }
  .button-label {
    padding: 8px !important;
    border-radius: 4px;
    cursor: pointer;
  }
  .button-checkbox input:checked + .button-label {
    background-color: #1ecad3;
    color: #fff;
  }

  .button-checkbox input:not(:checked) + .button-label {
    background-color: #fff;
    color: #000;
  }
}
</style>
