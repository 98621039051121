<template>
  <div class="scroll-table-mobile mb-5">
    <!-- <small-spring-loading :is-loading="Loading"></small-spring-loading> -->

    <div
      class="breadcrumb-item me-0 me-md-5 ms-0 py-3 d-flex align-items-center align-content-center"
    >
      <a
        href="#"
        class="shamel fw-bold fs-5 text-decoration-none text-light-dark-mode"
      >
        <h6 class="head fs-5 text-light-dark-mode mb-0">حجوزاتى</h6>
      </a>
      <img
        src="../../assets/icon/arrow-left.svg"
        alt="arrow-left"
        class="mx-2"
      />
    </div>
    <b-tabs card>
      <b-tab title="إحصائيات" active>
        <div>
          <!-- <p class="text-light-dark-mode">إحصائيات الاستشارة</p> -->

          <div class="box">
            <div class="row">
              <div class="col-md-4">
                <div class="box-result d-flex align-items-center gap-2">
                  <div>
                    <img src="../../assets/icon-profile.svg" alt="" />
                  </div>
                  <div class="box_info d-flex flex-column">
                    <span>0</span>
                    <p>استشارات فعالة</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box-result d-flex align-items-center gap-2">
                  <img src="../../assets/icon-profile.svg" alt="" />
                  <div class="box_info d-flex flex-column">
                    <span>0</span>
                    <p>مجموع الاستشارات</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="box-result d-flex align-items-center gap-2">
                  <img src="../../assets/icon-profile.svg" alt="" />
                  <div class="box_info d-flex flex-column">
                    <span>0</span>
                    <p>الساعات النشطة</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="فرز الاستشارات" class="filter-meeting">
        <FilterReservationMeeting />
      </b-tab>
      <b-tab
        title="قائمة الاستشارات"
        :active="$route.query.tab === 'consultation'"
      >
        <ReservationTab></ReservationTab>
      </b-tab>
      <b-tab
        title="قائمةالطلبات"
        v-if="this.user !== null && user.role_name === 'teacher'"
      >
        <GlobalTable
          :data.sync="meetingRequests"
          :fields="requestsFields"
          :show-button="false"
        >
          <template #cell(user)="data">
            <img
              :src="data.item.user.avatar"
              class="user-avatar"
              alt="avatar"
            />
            <span>{{ data.item.user.full_name }}</span>
          </template>
          <template #cell(date)="data">
            <span>{{ convertDate(data.item.date) }}</span>
          </template>
          <template #cell(time)="data">
            <span>{{ data.item.time.start }}</span>
            <span>{{ data.item.time.end }}</span>
          </template>

          <template v-slot:endMeeting="objItem">
            <button
              v-if="objItem.itemObj.item.session_type === 'link'"
              class="links-mode-color"
              @click="showMeetingLinkModal(objItem.itemObj.item.id)"
            >
              إرسال
            </button>
            <button
              v-if="objItem.itemObj.item.session_type !== 'link'"
              class="links-mode-color"
              @click="JoinToSession(objItem.itemObj.item.id)"
            >
              انضم
            </button>

            <!-- add to calendar google -->
            <button style="background-color: transparent">
              <a
                href="https://calendar.google.com/calendar/render?action=TEMPLATE&amp;dates=20220315T103000Z/20220315T113000Z&amp;ctz=UTC&amp;text=Meeting"
                target="_blank"
                class="webinar-actions d-block mt-10 font-weight-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-calendar"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                  />
                </svg>
              </a>
            </button>
            <!-- Show User Details -->
            <button
              style="background-color: transparent"
              @click="showInstructorInfo(objItem.itemObj)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                />
                <path
                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                />
              </svg>
            </button>

            <button
              v-if="user !== null && user.role_name === 'teacher'"
              class="links-mode-color"
              @click="finishMeeting(objItem.itemObj.item.id)"
            >
              إنهاء
            </button>
          </template>
        </GlobalTable>
        <VueModal
          v-model="showInstructorModal"
          modalStyle="max-width:450px;min-height: 50px; padding: 20px; border-radius: 10px"
          modalClass="play_course_popup d"
          title="معلومات الإتصال"
        >
          <div v-if="selectedCellInfo">
            <div class="text-center">
              <img
                :src="selectedCellInfo.item.user.avatar"
                class="student-avatar"
              />
              <span class="student-name">{{
                selectedCellInfo.item.user.full_name
              }}</span>
            </div>
            <div class="w-75 m-auto">
              <div
                class="d-flex align-items-center justify-content-between mt-4"
              >
                <span class="text-left mr-15 flex-grow-1">الإيميل :</span>
                <span class="text-right">{{
                  selectedCellInfo.item.instructor.email
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mt-4"
              >
                <span class="text-left mr-15 flex-grow-1">الهاتف :</span>
                <span class="text-right">{{
                  selectedCellInfo.item.instructor.mobile
                }}</span>
              </div>
            </div>
          </div>
        </VueModal>
        <VueModal
          v-model="showSendLinkMeetingModal"
          modalStyle="max-width:550px;min-height: 50px; padding: 20px; border-radius: 10px"
          modalClass="play_course_popup"
          title=" "
        >
          <div class="send-link">
            <div v-if="errors.length > 0" class="alert alert-danger">
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="d-flex align-content-center gap-3">
              <GlobalInput
                type="text"
                label="ارسل رابط الإجتماع"
                v-model="linkMeeting"
                class="w-100 mb-0"
              />
              <button
                @click.prevent="sendLink()"
                class="btn btn-coursestore-primary text-white py-2 px-3"
              >
                إرسال
              </button>
            </div>
            <p class="text-center mx-2">أو</p>
            <div class="d-flex justify-content-center align-items-center">
              <!-- <button
                @click.prevent="sendLink()"
                class="btn btn-coursestore-primary btn-padding text-white py-2 px-3"
              >
                إرسال
              </button> -->
              <!--<button
                class="btn btn-coursestore-primary btn-padding text-white py-2 px-3"
                @click.prevent="createSession(item_id, 'agora')"
              >
                <small-spring-loading
                  :is-loading="loading"
                ></small-spring-loading>
                <span>إنشاء أجورا لايف </span>
              </button>-->
              <button
                class="btn btn-coursestore-primary btn-padding text-white py-2 px-3"
                @click.prevent="createSession(item_id, 'jitsi')"
              >
                <small-spring-loading
                  :is-loading="loading"
                ></small-spring-loading>
                <span>إنشاء جيتسي لايف </span>
              </button>
            </div>
          </div>
        </VueModal>
        <VueModal
          v-model="showSendAgoraMeetingModal"
          modalStyle="max-width:550px;min-height: 50px; padding: 20px; border-radius: 10px"
          modalClass="play_course_popup"
          title=" "
        >
          <div v-show="sendAgoraMeetingModalMessage !== ''">
            <div class="alert alert-warning">
              {{ sendAgoraMeetingModalMessage }}
            </div>
          </div>
          <div
            v-if="
              agoraData &&
              agoraData.isHost === true &&
              sendAgoraMeetingModalMessage === ''
            "
          >
            <router-link
              class="text-decoration-none"
              :to="{ name: 'AgoraLive', params: { id: agoraData.authUserId } }"
              >انضم الآن
            </router-link>
          </div>
        </VueModal>
      </b-tab>
      <b-tab
        title="الإعدادات"
        v-if="this.user !== null && user.role_name === 'teacher'"
        class="consult-settings"
      >
        <ConsultingTimeMeetings></ConsultingTimeMeetings>
        <div class="row mt-5">
          <div class="col-md-4">
            <div class="d-flex gap-3">
              <v-date-picker
                v-model="dayTime"
                :available-dates="{ start: new Date(), end: null }"
                :model-config="modelConfig"
                mode="date"
                :timezone="timezone"
                locale="ar"
                @dayclick="onDayClick"
              />
              <v-date-picker
                v-model="range"
                mode="time"
                :is24hr="false"
                is-range
                locale="ar"
              />
            </div>
          </div>
          <div class="col-md-8">
            <!-- <ConsultingTimeMeetings></ConsultingTimeMeetings> -->
          </div>
        </div>

        <button @click="addReserveTime" class="submit">إضافة الوقت</button>
        <div class="update-settings">
          <div class="update-settings__hourly-charge">
            <h6>الرسوم بالساعة</h6>
            <div class="row">
              <div class="col-md-6">
                <label class="font-weight-500 font-14 text-dark-blue d-block">
                  المبلغ د.ك
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-white font-16">
                      د.ك</span
                    >
                  </div>
                  <input
                    v-model="form.amount"
                    type="number"
                    name="amount"
                    value="100"
                    class="form-control"
                    placeholder="فقط ارقام..."
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label class="font-weight-500 font-14 text-dark-blue d-block"
                  >نسبة الخصم (%)</label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-white font-16">%</span>
                  </div>
                  <input
                    v-model="form.discount"
                    type="number"
                    name="discount"
                    value=""
                    class="form-control"
                    placeholder="فقط ارقام..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="in-person-meetings">
            <h6>الاستشارات الحضورية الفردية</h6>
            <div class="row">
              <div class="col-md-6 mt-3">
                <div class="d-flex align-items-center gap-3">
                  <label
                    class="cursor-pointer input-label"
                    for="inPersonMeetingSwitch"
                    >متاح للإستشارات الحضورية الفردية</label
                  >
                  <div class="panel-body" style="direction: rtl">
                    <!--Only code you need is this label-->
                    <label class="switch">
                      <input
                        v-model="form.in_person"
                        type="checkbox"
                        id="inPersonMeetingSwitch"
                        @click="showPersonMeetings"
                      />
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="form.in_person" class="col-md-6">
                <label class="font-weight-500 font-14 text-dark-blue d-block"
                  >سعر الساعة
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-white font-16"> $ </span>
                  </div>

                  <input
                    v-model="form.in_person_amount"
                    type="number"
                    name="in_person_amount"
                    value="250"
                    class="form-control"
                    placeholder="فقط ارقام..."
                  />
                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- Group Meeting -->
          <div class="group-Meeting mt-3">
            <div class="d-flex align-items-center gap-3">
              <label
                class="cursor-pointer input-label"
                for="inGroupMeetingSwitch"
              >
                خيارات الإستشارة لمجموعة عبر الانترنت</label
              >
              <div class="panel-body">
                <!--Only code you need is this label-->
                <label class="switchGroup">
                  <input
                    v-model="form.group_meeting"
                    type="checkbox"
                    id="inGroupMeetingSwitch"
                    @click="showGroupMeetings"
                  />
                  <div class="sliderGroup roundGroup"></div>
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-15" v-if="form.group_meeting">
            <div class="col-md-4">
              <label class="font-weight-500 font-14 text-dark-blue d-block"
                >الحد الأدنى من الطلاب</label
              >
              <input
                v-model="form.online_group_min_student"
                type="number"
                min="2"
                name="online_group_min_student"
                value="20"
                class="form-control"
                placeholder="فقط ارقام..."
              />
              <div class="invalid-feedback"></div>
            </div>

            <div class="col-md-4">
              <label class="font-weight-500 font-14 text-dark-blue d-block"
                >الحد الأقصى من الطلاب</label
              >
              <input
                v-model="form.online_group_max_student"
                type="number"
                name="online_group_max_student"
                value="200"
                class="form-control"
                placeholder="فقط ارقام..."
              />
              <div class="invalid-feedback"></div>
            </div>

            <div class="col-md-4">
              <label class="font-weight-500 font-14 text-dark-blue d-block"
                >السعر بالساعة</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text text-white font-16">د.ك </span>
                </div>

                <input
                  v-model="form.online_group_amount"
                  type="text"
                  name="online_group_amount"
                  value="50"
                  class="form-control"
                  placeholder="فقط ارقام..."
                />
                <div class="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <!-- End Group Meeting -->
          <!-- In-person Group Meeting Option -->
          <div class="in-person-group-meeting-option">
            <h6>خيارات الإستشارة لمجموعة حضورياً</h6>
            <div class="row mt-15">
              <div class="col-md-4">
                <label class="font-weight-500 font-14 text-dark-blue d-block"
                  >الحد الأدنى من الطلاب</label
                >
                <input
                  v-model="form.in_person_group_min_student"
                  type="number"
                  min="2"
                  name="in_person_group_min_student"
                  value="5"
                  class="form-control"
                  placeholder="فقط ارقام..."
                />
                <div class="invalid-feedback"></div>
              </div>

              <div class="col-md-4">
                <label class="font-weight-500 font-14 text-dark-blue d-block"
                  >الحد الأقصى من الطلاب</label
                >
                <input
                  v-model="form.in_person_group_max_student"
                  type="number"
                  name="in_person_group_max_student"
                  value="30"
                  class="form-control"
                  placeholder="فقط ارقام..."
                />
                <div class="invalid-feedback"></div>
              </div>

              <div class="col-md-4">
                <label class="font-weight-500 font-14 text-dark-blue d-block"
                  >السعر بالساعة</label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text text-white font-16">
                      د.ك
                    </span>
                  </div>

                  <input
                    v-model="form.in_person_group_amount"
                    type="text"
                    name="in_person_group_amount"
                    value="150"
                    class="form-control"
                    placeholder="فقط ارقام..."
                  />
                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- In-person Group Meeting Option -->
        </div>
        <button @click="saveUpdates" class="submit">حفظ التعديلات</button>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import meeting_request from "@/api/meeting_request";
import GlobalTable from "@/components/Helper/GlobalTable.vue";
import VueModal from "@kouts/vue-modal";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import FilterReservationMeeting from "@/components/Profile/ConsultProfile/filterReservtionMeeting.vue";
import ConsultingTimeMeetings from "@/components/Profile/ConsultProfile/ConsultingTimeMeetings.vue";
import ReservationTab from "@/components/Profile/ConsultProfile/ReservationTab.vue";
import moment from "moment";
export default {
  name: "profileConsult",
  components: {
    GlobalTable,
    VueModal,
    GlobalInput,
    FilterReservationMeeting,
    ConsultingTimeMeetings,
    SmallSpringLoading,
    ReservationTab,
  },
  data() {
    return {
      requestsFields: [
        {
          key: "user",
          label: "الطالب",
          sortable: true,
        },
        {
          key: "meeting_type",
          label: "نوع الإستشارة",
          sortable: true,
        },
        {
          key: "day",
          label: "اليوم",
          sortable: true,
        },
        {
          key: "time",
          label: "الوقت",
          sortable: true,
        },
        {
          key: "date",
          label: "التاريخ",
          sortable: true,
        },
        {
          key: "user_paid_amount",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "student_count",
          label: "عدد الطلاب",
          sortable: true,
        },
        {
          key: "status",
          label: "الحالة",
          sortable: true,
        },

        {
          key: "endMeeting",
          label: "",
          sortable: true,
        },
      ],
      selectedStatus: null,
      selectedCellInfo: null,
      showInstructorModal: false,
      statusOption: ["finished", "open"],
      selectedInstructor: "",
      loading: false,
      meetingRequests: [],
      days: [],
      date: new Date(),
      dayTime: "",
      day: "",
      time: "",
      timezone: "Asia/Kuwait",
      modelConfig: {
        timeAdjust: "12:00",
        masks: {
          input: "h:mm A",
        },
      },
      myTime: null,
      range: {
        start: null,
        end: null,
      },
      form: {
        amount: "",
        discount: "",
        in_person: true,
        in_person_amount: "",
        group_meeting: true,
        online_group_min_student: "",
        online_group_max_student: "",
        online_group_amount: "",
        in_person_group_min_student: "",
        in_person_group_max_student: "",
        in_person_group_amount: "",
      },
      item_id: undefined,
      showSendLinkMeetingModal: false,
      showSendAgoraMeetingModal: false,
      sendAgoraMeetingModalMessage: "",
      linkMeeting: "",
      agoraData: null,
      errors: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("instructors", ["instructor_details", "isLoadData"]),
    formattedDateStart() {
      return moment(this.range.start).format("h:mmA");
    },
    formattedDateEnd() {
      return moment(this.range.end).format("h:mmA");
    },
  },

  methods: {
    ...mapActions("auth", ["getUser"]),
    ...mapActions("instructors", ["GetInstructor"]),
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        const formattedTime = `${this.modelConfig.timeAdjust}-${
          parseInt(this.modelConfig.timeAdjust.slice(0, 2)) + 1
        }:00AM`;
        const formattedDay = day.date.toLocaleString("en-us", {
          weekday: "long",
        });
        this.days.push({
          id: day.id,
          date: day.date,
          time: formattedTime,
          day: formattedDay,
        });
        this.day = formattedDay;
        this.time = formattedTime;
      }
    },

    async finishMeeting(id) {
      await meeting_request.endMeeting(id).then((res) => {
        if (res.status === 200 && res.data.success === true) {
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          location.reload();
        } else if (res.status === 200 && res.data.success === false) {
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "error",
            confirmButtonText: "موافق",
          });
        }
      });
    },
    showMeetingLinkModal(itemId) {
      this.item_id = itemId;
      this.linkMeeting = "";
      this.showSendLinkMeetingModal = true;
    },
    getInstructorIdFromInfo(info) {
      // Assuming the instructor ID is stored in `info.item.user.id`
      return info.item.user.id;
    },
    async showInstructorInfo(info) {
      this.selectedCellInfo = info;
      this.showInstructorModal = true;

      const instructorId = this.getInstructorIdFromInfo(info);

      try {
        await meeting_request.getInstructorMeetingList(instructorId);
        // You can do something with the result if needed
      } catch (error) {
        console.error("Error fetching instructor meeting list:", error);
      }
    },
    async sendLink() {
      if (this.linkMeeting === "") {
        // Input is empty, handle the error
        this.errors.push("من فضلك ادخل رابط الإجتماع");
        return; // Stop execution
      }
      const payload = {
        item_id: this.item_id,
        link: this.linkMeeting,
      };
      await meeting_request.sendMeetingLink(payload).then((res) => {
        this.$swal.fire({
          title: `${res.data.message}`,
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.showSendLinkMeetingModal = false;
      });
    },
    async createSession(id, type = "agora") {
      this.loading = true;
      await meeting_request.instructorCreateSessions(id, type).then((res) => {
        if (res.status === 200 && res.data.success === true) {
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.showSendLinkMeetingModal = false;
        } else if (res.status === 200 && res.data.success === false) {
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "error",
          });
        }
        this.loading = false;
      });
    },
    async JoinToSession(id) {
      this.sendAgoraMeetingModalMessage = "";
      this.loading = true;
      await meeting_request.instructorJoinSessions(id).then((res) => {
        if (res.status === 200 && res.data.success === true) {
          //this.$router.push({ name: "AgoraLive", params: { id: id } });
          this.agoraData = res.data.data;
          if (
            this.agoraData.isStarted === false &&
            this.agoraData.isEnded === false
          ) {
            this.showSendAgoraMeetingModal = true;
            this.sendAgoraMeetingModalMessage =
              "لم تبدا بعد ستبدا فى تاريخ  " +
              this.agoraData.streamStartAtAsHuman;
          } else if (this.agoraData.isEnded === true) {
            this.sendAgoraMeetingModalMessage =
              "عذراً لقت تاخزت الجلسة انتهت فى  " +
              this.agoraData.streamEndAtDate;
            this.showSendAgoraMeetingModal = true;
          } else if (this.agoraData.isStarted === true) {
            if (this.agoraData.type === "jitsi") {
              this.$router.push({
                name: "live",
                params: { id: id, type: "meeting" },
              });
            } else if (this.agoraData.type === "agora") {
              this.$router.push({ name: "AgoraLive", params: { id: id } });
            }
          }
        } else if (res.status === 200 && res.data.success === false) {
          this.$swal.fire({
            title: `${res.data.message}`,
            text: `${res.data.data}`,
            icon: "error",
          });
        }
        this.loading = false;
      });
    },
    convertDate(createdAt) {
      let date = new Date(createdAt * 1000);
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      // Format as "YYYY-MM-DD"
      return year + "-" + month + "-" + day;
    },
    async getRequests() {
      this.loading = true;

      await meeting_request.getMeetingRequests().then((res) => {
        this.meetingRequests = res.data.data;
      });
      this.loading = false;
    },
    async addReserveTime() {
      const payload = {
        day: this.day.toLowerCase(),
        time: this.formattedDateStart + "-" + this.formattedDateEnd,
      };
      await meeting_request.addTime(payload).then((res) => {
        if (res.status === 200 && res.data.success === true) {
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else if (res.status === 200 && res.data.success === false) {
          this.$swal.fire({
            title: `${res.data.message}`,
            icon: "error",
          });
        }
      });
    },
    async saveUpdates() {
      const payload = {
        amount: this.form.amount,
        discount: this.form.discount,
        in_person: this.form.in_person,
        in_person_amount: this.form.in_person_amount,
        group_meeting: this.form.group_meeting,
        online_group_min_student: this.form.online_group_min_student,
        online_group_max_student: this.form.online_group_max_student,
        online_group_amount: this.form.online_group_amount,
        in_person_group_min_student: this.form.in_person_group_min_student,
        in_person_group_max_student: this.form.in_person_group_max_student,
        in_person_group_amount: this.form.in_person_group_amount,
      };

      await meeting_request
        .saveUpdate(this.instructor_details.meeting.id, payload)
        .then((res) => {
          if (res.data.success === true) {
            this.$swal.fire({
              title: `${res.data.message}`,
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          } else {
            this.$swal.fire({
              title: `${res.data.message}`,
              icon: "error",
            });
          }
        });
    },
    showPersonMeetings() {
      this.form.in_person = !this.form.in_person;
      this.$emit("setCheckboxVal", this.from.in_person);
    },
    showGroupMeetings() {
      this.form.group_meeting = !this.form.group_meeting;
      this.$emit("setCheckboxVal", this.form.group_meeting);
    },
  },
  async created() {
    await this.getUser();
    this.GetInstructor(this.user.id);
    this.getRequests();
  },
};
</script>

<style lang="scss" scoped>
.student-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 5px;
}

.student-name {
  color: #171347;
  font-weight: 700;
  font-size: 1rem;
  display: block;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
}

form {
  padding: 25px 10px;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: var(--bs-border-radius) !important;
}

.dark-theme form {
  box-shadow: rgb(183 180 180 / 31%) 0 5px 15px 0;
}

.btn-blue {
  background: #410099 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  width: 174px;
  height: 40px;
  margin-top: 19px;
}

.btn-blue:hover {
  background-color: #36037d;
  color: #fff;
}

.b-form-datepicker {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #ccc !important;
}

#datepicker-sm {
  box-shadow: none !important;
}

input {
  width: 100%;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.45rem 0.5rem;
  font-size: 0.875rem;
  outline: none !important;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  direction: inherit;
}

select {
  width: 100%;
  padding: 6px;
  margin: 0 !important;
  outline: none;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #6c757d !important;
}

label {
  color: #36037d;
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}

.btn,
button {
  box-shadow: none !important;
}

.dark-theme label {
  color: #fff;
}

.title-content-tab {
  color: #36037d;
  font-weight: 600;
}

.box {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: 8px;
  padding: 30px;
}

.dark-theme .box {
  background-color: #1c1c1c;
}

.box_info span,
.box_info p {
  font-size: 16px;
  font-weight: bold;
  color: #36037d;
}

.dark-theme .box_info span {
  color: gray;
}

.dark-theme .box_info p {
  color: #fff;
}

@media (max-width: 768px) {
  .box {
    padding: 10px;
  }
  .box-result {
    margin-bottom: 10px;
  }
  .box_info span,
  .box_info p {
    font-size: 12px;
  }
}

.update-settings {
  max-width: 800px;
  margin-top: 40px;

  .input-group-text {
    background-color: #410099;
  }
}

.switchGroup {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.sliderGroup {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderGroup:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  right: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderGroup {
  background-color: green;
}

input:focus + .sliderGroup {
  box-shadow: 0 0 1px #101010;
}

input:checked + .sliderGroup:before {
  -webkit-transform: translateX(-26px);
  -ms-transform: translateX(-26px);
  transform: translateX(-26px);
}

.sliderGroup.round {
  border-radius: 34px;
}

.sliderGroup.round:before {
  border-radius: 50%;
}

.in-person-meetings {
  margin-top: 40px;
  margin-bottom: 10px;

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    right: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: green;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before,
  input:checked + .sliderGroup:before {
    -webkit-transform: translateX(-26px);
    -ms-transform: translateX(-26px);
    transform: translateX(-26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.slider.round:before {
  border-radius: 50%;
}

.sliderGroup.roundGroup {
  border-radius: 34px;
}

.sliderGroup.roundGroup:before {
  border-radius: 50%;
}

.in-person-group-meeting-option {
  margin-top: 40px;
}

.instructor-calendar {
  display: flex;
}

.submit {
  background-color: #36037d;
  color: #fff;
  padding: 8px 30px;
  border-radius: 4px;
  margin-top: 20px;
}

.join-link {
  font-weight: bold;
  text-decoration: none;
  color: #36037d;
}

.links-mode-color {
  background: #410099;
  color: #fff;
  padding: 0px 10px;
  border-radius: 4px;
}
// .dark-theme .links-mode-color {
//   background-color: #2f2f2f;
// }

.dark-theme .links-mode-color {
  color: #fff;
}
</style>
