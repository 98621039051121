import { render, staticRenderFns } from "./SpacialMarketer.vue?vue&type=template&id=0ae78dca&scoped=true"
import script from "./SpacialMarketer.vue?vue&type=script&lang=js"
export * from "./SpacialMarketer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae78dca",
  null
  
)

export default component.exports