<template>
  <div v-if="timing_group_by_day">
    <div
      class="row"
      v-for="(day, dayLabel) in timing_group_by_day"
      :key="dayLabel"
    >
      <div>
        <div>
          <h5>السبت :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(saturday, index) in day.meeting.timing_group_by_day
                .saturday"
              :key="index"
              class="position-relative items-time"
            >
              {{ saturday.time }}
              <span class="remove" @click="deleteTimeMeeting(saturday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
        <div>
          <h5>الأحد :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(sunday, index) in day.meeting.timing_group_by_day.sunday"
              :key="index"
              class="position-relative items-time"
            >
              {{ sunday.time }}
              <span class="remove" @click="deleteTimeMeeting(sunday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
        <div>
          <h5>الأثنين :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(monday, index) in day.meeting.timing_group_by_day.monday"
              :key="index"
              class="position-relative items-time"
            >
              {{ monday.time }}
              <span class="remove" @click="deleteTimeMeeting(monday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
        <div>
          <h5>الثلاثاء :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(tuesday, index) in day.meeting.timing_group_by_day
                .tuesday"
              :key="index"
              class="position-relative items-time"
            >
              {{ tuesday.time }}
              <span class="remove" @click="deleteTimeMeeting(tuesday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
        <div>
          <h5>الأربعاء :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(wednesday, index) in day.meeting.timing_group_by_day
                .wednesday"
              :key="index"
              class="position-relative items-time"
            >
              {{ wednesday.time }}
              <span class="remove" @click="deleteTimeMeeting(wednesday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
        <div>
          <h5>الخميس :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(thursday, index) in day.meeting.timing_group_by_day
                .thursday"
              :key="index"
              class="position-relative items-time"
            >
              {{ thursday.time }}
              <span class="remove" @click="deleteTimeMeeting(thursday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
        <div>
          <h5>الجمعة :</h5>
          <ul class="list-unstyled d-flex gap-3 flex-wrap">
            <li
              v-for="(friday, index) in day.meeting.timing_group_by_day.friday"
              :key="index"
              class="position-relative items-time"
            >
              {{ friday.time }}
              <span class="remove" @click="deleteTimeMeeting(friday.id)"
                >x</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import meeting_request from "@/api/meeting_request";

export default {
  name: "ConsultingTimeMeetings",
  data() {
    return {
      timing_group_by_day: [],
    };
  },
  created() {
    this.instructorsPreviewTimes();
  },
  // beforeDestroy() {
  //   this.resetData();
  // },
  methods: {
    // resetData() {
    //   this.timing_group_by_day = [];
    // },
    async instructorsPreviewTimes() {
      try {
        this.isLoadingData = true;
        const res = await meeting_request.instructorTimePreview();
        // this.timing_group_by_day = [];
        this.timing_group_by_day = res.data.data.reservations.meetings;
      } finally {
        this.isLoadingData = false;
      }
    },
    async deleteTimeMeeting(time_id) {
      const payload = {
        time_id: [time_id],
      };
      this.isLoadingData = true;
      const res = await meeting_request.sendDeleteTimeRequest(payload);
      this.showSuccessMessage(res.data.message);
      this.isLoadingData = false;
    },
    showSuccessMessage(message) {
      this.$swal.fire({
        title: message,
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.items-time {
  background-color: #ddd;
  border-radius: 8px;
  padding: 3px 10px;
  margin-top: 15px;
}
.remove {
  position: absolute;
  top: -12px;
  width: 20px;
  height: 20px;
  left: -5px;
  align-items: center;
  display: flex;
  background-color: #f84343;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
}
</style>
