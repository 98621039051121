<template>
  <div>
    <small-spring-loading :is-loading="loading_sales"></small-spring-loading>
    <div
      v-if="sales_details.sales_status != undefined"
      class="cart shadow-sm rounded-3 align-middle my-3"
    >
      <div class="row">
        <div
          v-for="(status, index) in sales_details.sales_status"
          :key="index"
          class="col-6 col-md-3 mb-5 d-flex align-items-center justify-content-center"
        >
          <div class="d-flex flex-column align-items-center text-center">
            <img :src="status.icon" alt="" width="64" height="64" />
            <strong
              class="font-30 font-weight-bold mt-5 text-dark-blue text-light-dark-mode"
              >{{ status.value }} {{ status.currency }}</strong
            >
            <span class="font-16 font-weight-500 text-gray text-gray-dark-mode">
              {{ status.label }}</span
            >
            <span
              class="font-16 font-weight-500 text-gray text-light-dark-mode"
            >
              {{ status.count_label }} : {{ status.count }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">لا توجد مبيعات الآن</div>
    <div v-if="sales_list">
      <table class="table">
        <tbody>
          <tr
            v-for="(item, index) in sales_list"
            :key="index"
            class="cart shadow-sm rounded-3 align-middle"
          >
            <td>
              <div
                class="d-flex justify-content-start gap-3 align-items-center"
              >
                <div>
                  <img
                    width="40"
                    class="rounded-circle"
                    :src="item.buyer.avatar"
                  />
                </div>
                <div class="align-items-center">
                  <h1 class="h6 text-light-dark-mode">
                    <a href="#" class="text-decoration-none">{{
                      item.buyer.full_name
                    }}</a>
                  </h1>
                </div>
              </div>
            </td>
            <td>
              <h6 class="fs-12 text-light-dark-mode">المحتوى المرئي</h6>
              <p class="text-muted fs-10">{{ item.webinar.title }}</p>
            </td>
            <td>
              <h6 class="fs-12 text-light-dark-mode">السعر</h6>
              <p class="text-muted fs-10">
                {{ item.amount }} {{ item.currency.code }}
              </p>
            </td>
            <td>
              <h6 class="fs-12 text-light-dark-mode">خصم</h6>
              <p class="text-muted fs-10">
                {{ item.discount }} {{ item.currency.code }}
              </p>
            </td>
            <td>
              <h6 class="fs-12 text-light-dark-mode">النوع</h6>
              <p class="text-muted fs-10">{{ item.type }}</p>
            </td>
            <td>
              <h6 class="fs-12 text-light-dark-mode">تاريخ الشراء</h6>
              <p class="text-muted fs-10">{{ item.created_date }}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex align-items-center justify-content-center">
        <button
          :disabled="btn_disabled"
          class="btn btn-coursestore-primary"
          @click.prevent="_getAllSales"
        >
          المزيد
        </button>
        <small-spring-loading
          :is-loading="loading_sales"
        ></small-spring-loading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";

export default {
  name: "SalesListComponent",
  components: { SmallSpringLoading },
  data() {
    return {
      btn_disabled: false,
    };
  },
  computed: {
    ...mapGetters("financial", [
      "sales_list",
      "loading_sales",
      "sales_offset",
      "sales_details",
    ]),
  },
  methods: {
    ...mapActions("financial", ["getAllSales"]),
    async _getAllSales() {
      let _this = this;
      _this.btn_disabled = true;
      await this.getAllSales({}).finally(() => {
        _this.btn_disabled = false;
      });
    },
  },
  created() {
    if (this.sales_list.length <= 0) {
      this._getAllSales();
    }
  },
};
</script>

<style scoped></style>
