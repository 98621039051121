<template>
  <div class="content mt-5 mb-5">
    <!-- Tabs with card integration -->
    <b-card no-body>
      <b-tabs v-model="tabIndex" small card ref="tabs">
        <!-- Tab 1 -->
        <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/file.svg" alt="" />
            معلومات اساسية
          </template>
          <div class="p-3 tab tab-one">
            <div class="input-group">
              <p class="title text-light-dark-mode">اللغة</p>
              <b-form-select
                v-model="FormGroup.locale"
                :options="lang"
              ></b-form-select>
            </div>
            <div class="input-group">
              <p class="title text-light-dark-mode">نوع الفصل</p>
              <b-form-select
                v-model="FormGroup.type"
                :options="typeCourse"
              ></b-form-select>
            </div>

            <GlobalInput
              type="text"
              label=" الصورة "
              v-model="FormGroup.thumbnail"
            />
            <GlobalInput
              type="text"
              label=" غلاف "
              v-model="FormGroup.image_cover"
            />
            <div class="input-group">
              <GlobalInput
                type="text"
                label="محركات البحث "
                v-model="FormGroup.seo_description"
              />
            </div>
            <GlobalInput
              type="text"
              label="العنوان "
              v-model="FormGroup.title"
            />
            <GlobalInput
              type="text"
              label="الوصف "
              v-model="FormGroup.description"
            />
            <GlobalInput
              type="text"
              label="رابط الفديو "
              v-model="FormGroup.video_demo"
            />
          </div>
        </b-tab>
        <!-- Tab 2 extra information-->
        <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/AddFile.svg" alt="" />

            معلومات إضافية
          </template>
          <div class="p-3 tab">
            <GlobalInput
              type="number"
              label=" capacity "
              v-model="FormGroup.capacity"
            />
            <GlobalInput
              type="number"
              label="المدة"
              v-model="FormGroup.duration"
            />

            <div class="tags-box">
              <label for="tags-separators text-light-dark-mode"
                >الكلامات الدلالية</label
              >
              <b-form-tags
                input-id="tags-remove-on-delete"
                :input-attrs="{
                  'aria-describedby': 'tags-remove-on-delete-help',
                }"
                v-model="FormGroup.tags"
                separator=" "
                placeholder="Enter new tags separated by space"
                remove-on-delete
                no-add-on-enter
              ></b-form-tags>
            </div>
            <p class="text-light-dark-mode">تاريخ البدء</p>
            <v-date-picker
              v-model="FormGroup.start_date"
              mode="dateTime"
              is24hr
              :format="dateTimeFormat"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
            <div class="d-flex mt-4 justify-content-between">
              <p class="text-light-dark-mode">قابل للتنزيل</p>
              <label class="custom-switch-container">
                <input
                  v-model="FormGroup.downloadable"
                  type="checkbox"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :unchecked-value="0"
                  :value="1"
                />
                <span class="custom-switch-slider"> </span>
              </label>
            </div>
            <div class="d-flex mt-4 justify-content-between">
              <p class="text-light-dark-mode">مدرس شريك</p>
              <label class="custom-switch-container">
                <input
                  v-model="FormGroup.partner_instructor"
                  type="checkbox"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :unchecked-value="0"
                  :value="1"
                />
                <span class="custom-switch-slider"> </span>
              </label>
            </div>
            <div class="d-flex mt-4 justify-content-between">
              <p class="text-light-dark-mode">الدعم</p>
              <label class="custom-switch-container">
                <input
                  v-model="FormGroup.support"
                  type="checkbox"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :unchecked-value="0"
                  :value="1"
                />
                <span class="custom-switch-slider"> </span>
              </label>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-light-dark-mode">اختر المجال</p>
              <select
                class="form-control day"
                name=""
                id="country"
                placeholder="المجالات"
                v-model="FormGroup.categories"
              >
                <option
                  v-for="(category, index) in categories"
                  :key="index"
                  :value="category.id"
                >
                  {{ category.title }}
                </option>
              </select>
            </div>
          </div>
        </b-tab>
        <!-- Tab 3 Pricing-->
        <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/Price.svg" alt="" />
            السعر
          </template>
          <div class="p-3 price-tab">
            <GlobalInput
              type="number"
              label=" السعر  "
              v-model="FormGroup.price"
            />
            <pricing-plan></pricing-plan>
          </div>
        </b-tab>
        <!-- Tab 4 content -->
        <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/AddFile.svg" alt="" />
            محتوى
          </template>
          <div class="p-3">
            <create-content :webinar_id="webinar_id"></create-content>
          </div>
        </b-tab>
        <!-- Tab 5 prerequisites -->
        <!-- <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/Video.svg" alt="" />
            المتطلبات المسبقة
          </template>
          <p class="p-3">معلومات إضافية</p>
        </b-tab> -->
        <!-- Tab 6 questions -->
        <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/Question.svg" alt="" />

            الأسئلة الشائعة
          </template>
          <div class="p-3">
            <chapter-faq :webinar_id="webinar_id"></chapter-faq>
          </div>
        </b-tab>
        <!-- Tab 7 Quiz-->
        <!-- <b-tab>
          <template #title>
            <img src="../../assets/images/AddCourseLive/quiz.svg" alt="" />

            الإختبارات
          </template>
          <div class="p-3">
            <new-quiz :webinar_id="webinar_id"></new-quiz>
          </div>
        </b-tab> -->
      </b-tabs>
    </b-card>

    <!-- Control buttons -->
    <div class="text-center d-flex align-items-center justify-content-between">
      <div>
        <b-button-group class="mt-2">
          <b-button
            class="btn-style"
            @click="tabIndex--"
            :disabled="tabIndex === 0"
          >
            السابق
          </b-button>
          <b-button
            class="btn-style"
            @click="tabIndex++"
            :disabled="webinar_id === null"
          >
            التالى
          </b-button>
        </b-button-group>
      </div>
      <b-button
        class="btn-style"
        @click="webinar_id === null ? createCourse() : updateCourse()"
      >
        <small-spring-loading
          :is-loading="uploadCourseLoading"
        ></small-spring-loading
        >حقظ
      </b-button>
      <b-button class="btn-style" @click="saveCourseAssADraft()">
        <small-spring-loading
          :is-loading="uploadCourseLoading"
        ></small-spring-loading
        >حفظ كامسودة
      </b-button>
      <div class="text-muted">Current Tab: {{ tabIndex }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GlobalInput from "@/components/Helper/GlobalInput.vue";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import upload_courses_request from "@/api/upload_courses_request";
import CreateContent from "@/components/Profile/UploadCourses/CreateContent.vue";
import ChapterFaq from "@/components/Profile/UploadCourses/FAQ/ChapterFaq.vue";
import PricingPlan from "@/components/Profile/UploadCourses/PricingPlans/PricingPlans.vue";
import Vue from "vue";
export default {
  name: "upload-courses",
  components: {
    GlobalInput,
    SmallSpringLoading,
    CreateContent,
    ChapterFaq,
    PricingPlan,
    // NewQuiz,
  },
  data() {
    return {
      tabIndex: 0,
      totalTabs: 0,
      lang: ["ar", "en"],
      typeCourse: ["webinar"],
      image_cover: "",
      thumbnail: "",
      uploadCourseLoading: false,
      currentStatus: "",
      FormGroup: {
        type: "",
        locale: "",
        seo_description: "",
        description: "",
        title: "",
        thumbnail: "",
        image_cover: "",
        video_demo: "",
        start_date: new Date(),
        capacity: null,
        duration: null,
        price: 0,
        downloadable: 0,
        partner_instructor: 0,
        support: 0,
        tags: [],
        categories: null,
      },
      webinar_id: null,
    };
  },
  methods: {
    ...mapActions("home", ["getCategories"]),
    updateTotalTabs() {
      this.totalTabs = this.$refs.tabs.$children.length;
    },
    getWebinarId() {
      if (
        this.webinar_id === null &&
        this.$route.query.webinar_id != undefined
      ) {
        this.webinar_id = this.$route.query.webinar_id;
      }
    },
    handleFileChange(e) {
      this.FormGroup.image_cover = e.target.files[0];
      this.image_cover = e.target.files[0].name;
    },
    updateImageThumbnail(e) {
      this.FormGroup.thumbnail = e.target.files[0];
      this.thumbnail = e.target.files[0].name;
    },
    formData(draft) {
      const formData = new FormData();

      formData.append("type", this.FormGroup.type);
      formData.append("locale", this.FormGroup.locale);
      formData.append("title", this.FormGroup.title);
      formData.append("seo_description", this.FormGroup.seo_description);
      formData.append("description", this.FormGroup.description);
      formData.append("video_demo", this.FormGroup.video_demo);
      formData.append("thumbnail", this.FormGroup.thumbnail);
      formData.append("image_cover", this.FormGroup.image_cover);
      formData.append("get_next", "");
      formData.append("partners", []);
      formData.append("get_step", null);
      formData.append("current_step", 1);

      if (this.tabIndex === 1) {
        formData.append("capacity", this.FormGroup.capacity);
        formData.append("current_step", 2);
        formData.append("downloadable", this.FormGroup.downloadable ? 1 : 0);
        formData.append("support ", this.FormGroup.support ? 1 : 0);
        // formData.append(
        //   "partner_instructor",
        //   this.FormGroup.partner_instructor ? 1 : 0
        // );
        formData.append("duration", this.FormGroup.duration);
        formData.append("start_date", this.dateTimeFormat);
        formData.append("category_id", this.FormGroup.categories);
      }
      if (this.tabIndex === 2) {
        formData.append("price", this.FormGroup.price);
        formData.append("current_step", 3);
      }
      formData.append("draft", draft);
      formData.append("private", 0);

      return formData;
    },

    // Upload Course
    async createCourse() {
      this.uploadCourseLoading = true;
      await upload_courses_request
        .addCourse(this.formData(0))
        .then((response) => {
          if (response.data.success === true) {
            Vue.swal.fire(response.data.message);
            this.webinar_id = response.data.data;
            this.$router.push({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                webinar_id: this.webinar_id,
                tabIndex: this.tabIndex,
              },
            });
            this.tabIndex = 1;
          }
        })
        .catch((error) => {
          Vue.swal.fire(error.message);
        });
      this.uploadCourseLoading = false;
    },
    async saveCourseAssADraft() {
      this.uploadCourseLoading = true;
      await upload_courses_request
        .addCourse(this.formData(1))
        .then((response) => {
          if (response.data.success === true) {
            Vue.swal.fire(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error.message);
          Vue.swal.fire(error.message);
        });

      this.uploadCourseLoading = false;
      // this.tabIndex = ++this.tabIndex;
    },
    async updateCourse() {
      this.uploadCourseLoading = true;
      await upload_courses_request
        .updateCourse(this.webinar_id, this.formData(0))
        .then((response) => {
          if (response.data.success === true) {
            Vue.swal.fire(response.data.message);
          }
        })
        .catch((error) => {
          Vue.swal.fire(error.message);
        });

      this.uploadCourseLoading = false;
    },
  },
  computed: {
    ...mapGetters("home", ["categories"]),
    dateTimeFormat() {
      const year = this.FormGroup.start_date.getFullYear();
      const month = String(this.FormGroup.start_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(this.FormGroup.start_date.getDate()).padStart(2, "0");
      const hours = String(this.FormGroup.start_date.getHours()).padStart(
        2,
        "0"
      );
      const minutes = String(this.FormGroup.start_date.getMinutes()).padStart(
        2,
        "0"
      );

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  },
  created() {
    this.getCategories();
    this.getWebinarId();
  },
  mounted() {
    this.updateTotalTabs();
  },
  watch: {
    // Watch for changes in the query route 'tabIndex' and update the component's 'tabIndex'
    // "$route.query.tabIndex": {
    //   immediate: true,
    //   handler(newValue) {
    //     this.tabIndex = newValue || 0;
    //   },
    // },
    // Watch for changes in the component's 'tabIndex' and update the query route 'tabIndex'
    // tabIndex(newValue) {
    //   this.$router.replace({
    //     path: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       tabIndex: newValue,
    //     },
    //   });
    // },
  },
};
</script>
<style lang="scss">
.btn-style {
  background-color: #410099 !important;
  color: #fff;
  &:hover {
    background-color: #240156 !important;
  }
}
.price-tab {
  width: 70%;
}
.dark-theme .price-tab {
  background-color: #2f2f2f;
  border-radius: 4px;
}
.dark-theme .pricing-plan {
  background-color: #2f2f2f;
  border-radius: 4px;
  border: none;
}
.tab {
  max-width: 40%;
  .input-group {
    margin-bottom: 20px;
    .custom-inputs {
      width: 100%;
      margin-top: 20px;
    }
    .title {
      margin-bottom: 0;
    }
    select {
      width: 100%;
      padding: 0.6rem;
      border-color: #4dbebd;
      outline: none;
      border-radius: 3px;
      border-radius: 10px;
    }
  }
}

.custom-switch-container {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  direction: ltr;
}

.custom-switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background-color: #ccc;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-switch-container input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch-container
  input[type="checkbox"]:checked
  + .custom-switch-slider {
  background-color: #33f321; /* Switch background color when checked */
}

.custom-switch-container .custom-switch-slider:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.custom-switch-container
  input[type="checkbox"]:checked
  + .custom-switch-slider:before {
  transform: translateX(20px); /* Move the slider to the right when checked */
}
// ------------------------------------------------
// Tag
// ------------------------------------------------
// .b-form-tags {
//   &.form-control {
//     background-color: #fff;
//     border-color: #ccc;
//     color: red;
//     box-shadow: none;
//     .b-form-tags-list {
//       .b-form-tag {
//         .b-form-tag-remove {
//           background-color: transparent !important;
//           box-shadow: none !important;
//         }
//       }
//     }
//   }
// }
// .b-form-tags-input {
//   color: #aaa;
// }
</style>
