<template>
  <div class="d-flex justify-content-between align-items-top">
    <div>
      <h5 class="fs-4 text-light-dark-mode" v-if="this.user !== null">
        اهلا ... {{ user.full_name }}
      </h5>
      <h6 class="h6 mt-3">
        <router-link
          class="text-decoration-none text-light-dark-mode"
          :to="{ path: '/panel/notifications' }"
        >
          لديك {{ list.count }} احداث جديدة
        </router-link>
      </h6>
    </div>
    <div>
      <div
        class="profile-alert-count position-relative"
        :data-count="list.count"
      >
        <router-link :to="{ path: '/panel/notifications' }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: var(--bg-cs-primary)"
          >
            <path
              d="m5.705 3.71-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71zm13.999-1.42-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647zM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z"
            ></path>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileWelcomeComponent",
  computed: {
    ...mapGetters("auth", ["isLogin", "user"]),
    ...mapGetters("notification", ["list"]),
  },
  methods: {
    ...mapActions("auth", ["getUser"]),
  },
};
</script>

<style scoped>
.profile-alert-count {
  bottom: -30px;
}
.dark-theme .text-light-dark-mode {
  color: #fff;
}
</style>
