<template>
  <form @submit.prevent="Change">
    <div class="mb-3">
      <input-password-helper
        :label="'New password'"
        @input-data="setPassword"
      />
      <input-password-helper
        :label="'Repeat password'"
        @input-data="setRepeatPassword"
      />
    </div>
    <button class="btn btn-primary d-flex align-items-center" type="submit">
      <box-icon class="ms-2" color="#FFF" name="key"></box-icon>
      <span>Change Password</span>
    </button>
  </form>
</template>

<script>
import InputPasswordHelper from "@/components/Helper/InputPasswordHelper";

export default {
  name: "ChangePasswordComponent",
  components: { InputPasswordHelper },
  data() {
    return {
      form: { password: "", repeat_password: "" },
    };
  },
  methods: {
    setRepeatPassword(value) {
      this.form.repeat_password = value;
    },
    setPassword(value) {
      this.form.password = value;
    },
  },
};
</script>

<style scoped></style>
