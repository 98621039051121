<template>
  <div>
    <div v-if="errorCount > 0 && !input" class="alert" :class="css_class">
      <ul class="m-0" style="list-style: none">
        <li v-for="(error, index) in errors" :key="index">
          <span
            v-if="errorCount == 1"
            class="text-danger"
            v-html="error"
          ></span>
          <div v-if="errorCount > 1">
            <div v-for="(error_items, index_items) in error" :key="index_items">
              <span v-html="error_items" class="text-danger"></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="errors[input]" class="text-danger fs-6">
      <span v-html="errors[input]"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayErrorHelper",
  props: {
    errors: null,
    input: null,
    css_class: String,
  },
  data() {
    return {
      errorCount: null,
    };
  },
  watch: {
    errors: function (val) {
      this.errorCount = Object.keys(val).length;
    },
  },
};
</script>

<style scoped></style>
