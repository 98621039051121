import axios from "axios";
export default {
  async fetchAll() {
    return await axios.get("/panel/cart/list");
  },
  async delete(id) {
    return await axios.delete("/panel/cart/" + id);
  },
  async empty() {
    return await axios.post("/panel/cart/empty_cart");
  },
  async add(payload) {
    /*payload {"webinar_id": 1, "ticket_id":""}*/
    return await axios.post("/panel/cart/store", payload);
  },
  async couponValidate(params) {
    /*payload {"coupon" : "www", "webinar_id" : 1}*/
    //console.log("payload", params);
    return await axios.post("/panel/cart/coupon/validate", params);
  },
  async getInfo(params) {
    /*payload {"webinar_id" : 1}*/
    //console.log("payload", params);
    return await axios.post("/panel/cart/info", params);
  },
  async checkout(params) {
    /*params {"discount_id" : 1,webinar_id:,PaymentMethodId:,gateway_id:}*/
    return await axios.post("/panel/cart/checkout", params);
  },
  async verify(getaway = "", params) {
    console.log("verify params", getaway !== undefined);
    if (getaway !== "") {
      return await axios.post("/panel/payments/verify/" + getaway + params);
    } else {
      const param = Object.fromEntries(new URLSearchParams(params));
      return await axios.post("/panel/payments/direct_verify/" + params, {
        order_id: param.order_id,
        status: "paid",
      });
    }
  },
};
