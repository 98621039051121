import axios from "axios";

export default {
  async removeAccount(payload) {
    return await axios.delete("/panel/profile-setting/remove-account", payload);
  },
  async getAccountSetting() {
    return await axios.get("/panel/profile-setting/user-info");
  },
  async getCountry() {
    return await axios.get("/countries");
  },
  async updateAccountSetting(payload) {
    return await axios.put("/panel/profile-setting", payload);
  },
};
