<template>
  <div v-if="!isShow">
    <button
      v-if="!isShow"
      @click.prevent="handelShowFastPayModal()"
      :class="buttonClass"
    >
      <span class="text-light"> {{ btn_name }} </span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BtnPayNowComponent",
  components: {},
  props: {
    btn_name: {
      type: String,
      default: "اشترك",
    },
    reserve_meeting_id: Number,
    webinar_id: Number,
    bundle_id: Number,
    has_bought: { type: Boolean, default: false },
    css_class: {
      type: String,
      default: "btn  bg-secondary text-white  ms-1",
    },
  },
  data() {
    return {
      isShow: this.has_bought,
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogin"]),
    buttonClass() {
      return this.css_class === "customClass" ? "buyNow customClass" : "buyNow";
    },
  },
  methods: {
    handelShowFastPayModal: function () {
      if (!this.isLogin) {
        this.$root.$emit("showAuthModel", true);
      } else {
        const ids = {
          webinar_id: this.webinar_id,
          bundle_id: this.bundle_id,
          reserve_meeting_id: this.reserve_meeting_id,
        };
        this.$root.$emit("showFastPayModal", ids);
      }
    },
  },
  mounted() {
    const _this = this;
    this.$root.$on("onAddToCartOrPay", function (id) {
      if (id === _this.webinar_id) {
        // _this.isShow = true;
      }
      this.$root.$emit("showAuthModel", false);
    });
    // hidden login modal
    this.$root.$on("onLogin", function () {
      this.$root.$emit("showAuthModel", false);
    });
  },
};
</script>

<style>
.buyNow {
  background-color: #773dbd;
  border-radius: 5px;
  padding: 5px 20px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.buyNow:hover {
  background-color: #410098;
}
.customClass {
  background-color: #410098;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: 400 !important;
}
.dark-theme .customClass {
  background-color: #2f2f2f;
}
</style>
