import axios from "axios";

export default {
  async fetchAll(
    offset = 0,
    cat = 0,
    limit = 10,
    isLessData = false,
    type,
    sort,
    without_attendance
  ) {
    return await axios.get("/courses", {
      headers: {
        withCredentials: false,
      },
      params: {
        paginate: true,
        offset: offset,
        limit: limit,
        cat: cat,
        is_less_data: isLessData,
        type: type,
        sort: sort,
        without_attendance: without_attendance,
      },
    });
  },
  async checkIfCourseFree(id) {
    return await axios.post(`/panel/webinars/${id}/free`);
  },
  async getCourseByFilter(
    filter = {},
    cat = 0,
    limit = 10,
    without_attendance = 1
  ) {
    return await axios.get("/courses", {
      params: {
        paginate: true,
        offset: filter.offset,
        limit: limit,
        price: filter.price,
        upcoming: filter.upcoming,
        downloadable: filter.downloadable,
        free: filter.free,
        discount: filter.discount,
        cat: cat,
        sort: "newest",
        without_attendance: without_attendance,
      },
    });
  },
  async getCourseById(slug) {
    return await axios.get(`courses/${slug}`, {
      params: {
        is_less_data: true,
      },
    });
  },
  async getCourseTicket(id) {
    return await axios.get(`/courses/${id}/tickets`);
  },
  async getCourseByTags(slug, limit = 10, offset = 0) {
    return await axios.get(`/tags/${slug}`, {
      params: { limit: limit, offset: offset },
    });
  },
  async getDemoById(id) {
    return await axios.get(`courses/demo-video/${id}`, {
      params: {
        is_less_data: true,
      },
    });
  },
  async reviewCourse(id, value, new_review) {
    return await axios.post(`panel/reviews`, {
      webinar_id: id,
      content_quality: value,
      instructor_skills: value,
      purchase_worth: value,
      support_quality: value,
      description: new_review,
    });
  },
  async reviewBundle(id, value, new_review) {
    return await axios.post(`panel/reviews`, {
      bundle_id: id,
      content_quality: value,
      instructor_skills: value,
      purchase_worth: value,
      support_quality: value,
      description: new_review,
    });
  },
  async postComment(data) {
    return await axios.post(`panel/comments`, {
      item_id: data.id,
      item_name: data.itemName,
      comment: data.comment,
    });
  },
  async getComment(data) {
    return await axios.post(`courses/comments`, {
      webinar_id: data.webinar_id,
      limit: 10,
      offset: data.offset,
    });
  },
  async addToFavorite(data) {
    return await axios.post(`panel/favorites/toggle/${data}`);
  },
  async updateProgress(id, payload) {
    //{"item" : "file_id", "item_id" : 900, "status" : true}
    return await axios.post(`courses/${id}/toggle`, payload);
  },
  async getChapterByCourse(id, type = "") {
    return await axios.post(`courses/chapters`, {
      webinar_id: id,
      limit: 20,
      offset: 0,
      type: type,
    });
  },
  async getPrerequisitesByCourse(payload) {
    return await axios.post(`courses/prerequisites`, {
      webinar_id: payload.id,
      limit: 20,
      offset: 0,
      type: "",
    });
  },
  /*
   "data": {
        "otp": "20160313versASE3234RFw96o0inXsb3VwXRcnPmgx44HaN8lNgie4KgjwQ5F1s7",
        "playbackInfo": "eyJ2aWRlb0lkIjoiMjhlZGMzYTVmYjczNDJkOTljYjA2ZmJmNzIzNDg4ZTcifQ=="
    }
   */
  async getPromoVideoById(payload) {
    return await axios.get(`courses/demo-video/${payload.id}`);
  },
  async getVideoByFileId(payload) {
    return await axios.get(`chiper-video/${payload.id}`);
  },
  async downloadFile(payload) {
    return await axios.get(`files/${payload.id}/download`, {
      responseType: "blob",
    });
  },
  async getAgoraSessions(_session_id) {
    return await axios.get("/panel/api_sessions/agora", {
      params: {
        session_id: _session_id,
      },
    });
  },
  async getSession(session_id) {
    return await axios.get("/panel/api_sessions/" + session_id);
  },
  async getWebinarLogos(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/company-logo`);
  },

  async getLearningMaterials(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/learning-material`);
  },
  async getRequirement(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/requirement`);
  },
  async getFaq(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/faq`);
  },
  async whatWeLearn(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/learning-material`);
  },
  async CourseRequirement(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/requirement`);
  },
  async tagsCourse(webinar_id) {
    return await axios.get(`/courses/${webinar_id}/tags`);
  },
  async getVideoLiveCourseInfo() {
    return await axios.get(`/image-video-section/live_course`);
  },
  async saveToCalendar(courseId) {
    return await axios.get(`/courses/calendar-link/${courseId}`);
  },
};
