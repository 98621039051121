<template>
  <span @click.prevent="goToPage()" :class="cssClass">{{ title }}</span>
</template>

<script>
export default {
  name: "PageLink",
  props: {
    title: { type: String, default: "" },
    slug: { type: String, default: "" },
    cssClass: { type: String, default: "" },
  },
  methods: {
    goToPage() {
      if (this.$route.path !== "/page/" + this.slug) {
        this.$router.replace({ name: "page", params: { link: this.slug } });
      }
      this.$root.$emit("showAuthModel", false);
    },
  },
};
</script>

<style scoped></style>
