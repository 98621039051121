<template>
  <div>
    <small-spring-loading :is-loading="loading_payout"></small-spring-loading>
    <div
      v-if="payout_status != undefined"
      class="cart p-3 shadow-sm rounded-3 align-middle my-3"
    >
      <div class="row">
        <div class="col-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center text-center">
            <img
              src="../../../assets/icon/financial/36.svg"
              alt=""
              width="64"
              height="64"
            />
            <strong
              class="font-30 text-dark-blue font-weight-bold mt-5 text-gray-dark-mode"
              >{{ payout_status.currency }}
              {{ payout_status.accountCharge }}</strong
            >
            <span class="font-16 text-gray font-weight-500 text-light-dark-mode"
              >تعبئة الرصيد</span
            >
          </div>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center text-center">
            <img
              src="../../../assets/icon/financial/37.svg"
              alt=""
              width="64"
              height="64"
            />
            <strong
              class="font-30 text-dark-blue font-weight-bold mt-5 text-gray-dark-mode"
              >{{ payout_status.currency }}
              {{ payout_status.readyPayout }}</strong
            >
            <span class="font-16 text-gray font-weight-500 text-light-dark-mode"
              >جاهز للسحب</span
            >
          </div>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center text-center">
            <img
              src="../../../assets/icon/financial/37.svg"
              alt=""
              width="64"
              height="64"
            />
            <strong
              class="font-30 text-dark-blue font-weight-bold mt-5 text-gray-dark-mode"
              >{{ payout_status.currency
              }}{{ payout_status.historyPayout }}</strong
            >
            <span class="font-16 text-gray font-weight-500 text-light-dark-mode"
              >اجمالي المحول</span
            >
          </div>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center text-center">
            <img
              src="../../../assets/icon/financial/38.svg"
              alt=""
              width="64"
              height="64"
            />
            <strong
              class="font-30 text-dark-blue font-weight-bold mt-5 text-gray-dark-mode"
              >{{ payout_status.currency }}
              {{ payout_status.totalIncome }}</strong
            >
            <span class="font-16 text-gray font-weight-500 text-light-dark-mode"
              >إجمالي الدخل</span
            >
          </div>
        </div>
      </div>
    </div>
    <b-btn class="mb-3" @click="showModal()"> طلب السحب </b-btn>
    <table class="table">
      <tbody>
        <tr
          v-for="(item, index) in payout_list"
          :key="index"
          class="cart shadow-sm rounded-3 align-middle"
        >
          <td>
            <h6 class="fs-12 text-light-dark-mode">البنك</h6>
            <p class="text-muted fs-10">{{ item.account_bank_name }}</p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">اسم المتفيد</h6>
            <p class="text-muted fs-10">{{ item.account_name }}</p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">المبلغ</h6>
            <p class="text-muted fs-10">
              {{ item.amount }} {{ item.currency.code }}
            </p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">بتاريخ</h6>
            <p class="text-muted fs-10">{{ item.created_date }}</p>
          </td>
          <td>
            <h6 class="fs-12 text-light-dark-mode">الحالة</h6>
            <p class="text-muted fs-10">{{ item.status }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex align-items-center justify-content-center">
      <button
        v-if="btnShowLoadMore"
        :disabled="btn_disabled"
        class="btn btn-coursestore-primary"
        @click.prevent="_getAllPayout"
      >
        المزيد
      </button>
      <small-spring-loading :is-loading="loading_payout"></small-spring-loading>
    </div>
    <VueModal
      v-model="showModalRequest"
      modalStyle="max-width:350px;min-height: 200px; padding: 20px; border-radius: 10px"
      modalClass="play_course_popup amount-pull"
      title="تأكيد السحب "
    >
      <div class="pull-mony">
        <p class="message-popup">
          يرجى التأكد من هذه المعلومات لطلب السحب سوف يتم إرسال الاموال على
          الحساب التالى
        </p>
      </div>
      <div class="amount d-flex align-items-center justify-content-between">
        <h6>جاهز للسحب</h6>
        <p>{{ payout_status.readyPayout }}</p>
      </div>
      <div class="box-input-amount mb-2">
        <label for="amount" class="d-block">المبلغ:</label>
        <input
          v-model="amount"
          type="number"
          id="amount"
          class="w-100"
          required
        />
      </div>

      <div
        class="account-type d-flex align-items-center justify-content-between"
      >
        <h6>نوع الحساب</h6>
        <p>بيت التمويل الكويتى</p>
      </div>
      <div
        class="account-number d-flex align-items-center justify-content-between"
      >
        <h6>رقم الحساب</h6>
        <p>بيبلسب</p>
      </div>
      <div
        class="account-iban d-flex align-items-center justify-content-between"
      >
        <h6>Iban</h6>
        <p>{{ current_payout.iban }}</p>
      </div>

      <div
        class="amout-btn d-flex align-items-center justify-content-between px-4"
      >
        <button class="btn btn-primary" @click="requestPayout()">
          طلب السحب
        </button>
        <button class="btn btn-danger" @click="closeModal()">إغلاق</button>
      </div>
    </VueModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import VueModal from "@kouts/vue-modal";
import axios from "axios";

export default {
  name: "PayoutComponent",
  data() {
    return {
      showModalRequest: false,
      btn_disabled: false,
      amount: "",
    };
  },
  components: { SmallSpringLoading, VueModal },
  computed: {
    ...mapGetters("financial", [
      "payout_list",
      "loading_payout",
      "payout_offset",
      "current_payout",
      "payout_status",
      "btnShowLoadMore",
    ]),
  },
  methods: {
    ...mapActions("financial", ["getAllPayout"]),
    showModal() {
      this.showModalRequest = true;
    },
    closeModal() {
      this.showModalRequest = false;
    },
    async requestPayout() {
      const payload = {
        amount: this.amount,
      };
      this.Loading = true;
      await axios.post("/panel/financial/payout", payload).then((res) => {
        if (this.amount !== "") {
          this.$swal.fire({
            confirmButtonText: "موافق",
            title: res.data.title,
            text: res.data.message,
          });
          this.Loading = false;
          this.showModalRequest = false;
        }
      });
    },
    async _getAllPayout() {
      let _this = this;
      _this.btn_disabled = true;
      await this.getAllPayout({}).finally(() => {
        _this.btn_disabled = false;
      });
    },
  },
  created() {
    if (this.payout_list.length <= 0) {
      this._getAllPayout();
    }
  },
};
</script>

<style>
.amount-pull {
  border-radius: 10px !important;
}
.amount-pull p {
  color: #aaa !important;
  font-size: 12px;
}
.amount-pull h6 {
  color: #929090;
  font-size: 14px;
}
.message-popup {
  font-size: 13px;
}
.box-input-amount input {
  border-radius: 8px;
  border: 1px solid #ddd;
  outline: none;
  padding: 5px 20px 5px 5px;
  direction: rtl;
}
</style>
