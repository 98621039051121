<template>
  <div>
    <form @submit.prevent="filterReservation()">
      <div class="row align-items-center">
        <div class="col-lg-3">
          <label for="datepicker-sm">من</label>
          <b-form-datepicker
            id="selected-from"
            size="sm"
            locale="ar"
            class="mb-2"
            v-model="startDate"
            @input="formatStartDate"
          ></b-form-datepicker>
        </div>
        <div class="col-lg-3">
          <label for="">الى</label>
          <div class="border-1">
            <b-form-datepicker
              id="selected-to"
              size="sm"
              locale="ar"
              class="mb-2"
              v-model="endDate"
              @input="formatEndDate"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="col-lg-2">
          <label>يوم</label>
          <b-form-select class="form-control day" v-model="selectedDay">
            <option
              v-for="(day, index) in daysOnly"
              :key="index"
              :value="index + 1"
            >
              {{ day }}
            </option>
          </b-form-select>
        </div>
        <!-- <div class="col-lg-2">
          <label>المدرب</label>
          <b-form-select
            v-model="selectedInstructor"
            :options="instructorOption"
            class="mt-3"
          ></b-form-select>
        </div> -->
        <div class="col-lg-2">
          <label>الحالة</label>
          <b-form-select
            v-model="selectedStatus"
            :options="statusOption"
            :value="selectedStatus"
            class="mt-3"
          ></b-form-select>
        </div>
        <div class="col-lg-2">
          <div class="form-group">
            <button class="btn btn-blue">
              عرض النتائج
              <small-spring-loading
                :is-loading="loadingFilters"
              ></small-spring-loading>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="mt-4" v-if="reservations.length > 0">
      <GlobalTable
        :data.sync="reservations"
        :fields="reservationFields"
        :show-button="false"
      >
        <template #cell(user)="data">
          <img :src="data.item.user.avatar" class="user-avatar" alt="avatar" />
          <span>{{ data.item.user.full_name }}</span>
        </template>
        <template #cell(date)="data">
          <span>{{ convertDate(data.item.date) }}</span>
        </template>
        <template #cell(time)="data">
          <span>{{ data.item.time.start }}</span>
          <span>{{ data.item.time.end }}</span>
        </template>
      </GlobalTable>
      <VueModal
        v-model="showInstructorModal"
        modalStyle="max-width:550px;min-height: 50px; padding: 20px; border-radius: 10px"
        modalClass="play_course_popup d"
        title=" "
      >
        <div v-if="selectedCellInfo">
          <div class="text-center">
            <img :src="selectedCellInfo.item.user.avatar" class="user-avatar" />
          </div>
          <span>{{ selectedCellInfo.item.user.full_name }}</span>
          <p>{{ selectedCellInfo.item.id }}</p>
        </div>
      </VueModal>
    </div>
  </div>
</template>

<script>
import GlobalTable from "@/components/Helper/GlobalTable.vue";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import moment from "moment";
import meeting_request from "@/api/meeting_request";
import VueModal from "@kouts/vue-modal";
export default {
  components: {
    GlobalTable,
    VueModal,
    SmallSpringLoading,
  },
  data() {
    return {
      daysOnly: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      reservationFields: [
        {
          key: "user",
          label: "المدرب",
          sortable: true,
        },
        {
          key: "meeting_type",
          label: "نوع الإستشارة",
          sortable: true,
        },
        {
          key: "day",
          label: "اليوم",
          sortable: true,
        },
        {
          key: "time",
          label: "الوقت",
          sortable: true,
        },
        {
          key: "date",
          label: "التاريخ",
          sortable: true,
        },
        {
          key: "user_paid_amount",
          label: "المبلغ",
          sortable: true,
        },
        {
          key: "student_count",
          label: "عدد الطلاب",
          sortable: true,
        },
        {
          key: "status",
          label: "الحالة",
          sortable: true,
        },
      ],
      selectedStatus: null,
      statusOption: ["finished", "open", "pending"],
      selectedDay: "",
      startDate: null,
      endDate: null,
      selectedCellInfo: null,
      showInstructorModal: false,
      reservations: [],
      loadingFilters: false,
    };
  },
  computed: {
    selectedDayName() {
      if (this.selectedDay && this.daysOnly.length >= this.selectedDay) {
        return this.daysOnly[this.selectedDay - 1];
      }
      return "";
    },
    getStatus() {
      if (this.selectedStatus !== null) {
        return this.statusOption[this.selectedStatus - 1];
      }
      return "";
    },
    isFormEmpty() {
      return (
        !this.startDate &&
        !this.endDate &&
        !this.selectedDay &&
        !this.selectedStatus
      );
    },
  },
  methods: {
    showInstructorInfo(info) {
      this.selectedCellInfo = info;
      this.showInstructorModal = true;
    },
    convertDate(createdAt) {
      var date = new Date(createdAt * 1000);
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, "0");
      var day = String(date.getDate()).padStart(2, "0");

      // Format as "YYYY-MM-DD"
      var formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    formatStartDate() {
      if (this.startDate) {
        this.startDate = moment(this.startDate).format("YYYY-M-D");
      }
    },
    formatEndDate() {
      if (this.endDate) {
        this.endDate = moment(this.endDate).format("YYYY-M-D");
      }
    },
    async filterReservation() {
      if (this.isFormEmpty) {
        return;
      }
      this.loadingFilters = true;
      await meeting_request
        .getReservations(
          this.startDate,
          this.endDate,
          this.selectedDayName,
          this.selectedStatus
        )
        .then((res) => {
          this.reservations = res.data.data;
        });
      this.loadingFilters = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
}
form {
  padding: 25px 10px;
  box-shadow: 0 4px 12px #0000001a;
  border-radius: var(--bs-border-radius) !important;
}
.dark-theme form {
  box-shadow: rgb(183 180 180 / 31%) 0 5px 15px 0;
}
.btn-blue {
  background: #410099 0 0 no-repeat padding-box;
  border-radius: 8px;
  color: #fff;
  width: 174px;
  height: 40px;
  margin-top: 19px;
}
.btn-blue:hover {
  background-color: #36037d;
  color: #fff;
}
.b-form-datepicker {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #ccc !important;
}
#datepicker-sm {
  box-shadow: none !important;
}
input {
  width: 100%;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.45rem 0.5rem;
  font-size: 0.875rem;
  outline: none !important;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  direction: inherit;
}
select {
  width: 100%;
  padding: 6px;
  margin: 0 !important;
  outline: none;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  color: #6c757d !important;
}
label {
  color: #36037d;
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}
.btn,
button {
  box-shadow: none !important;
}
.dark-theme label {
  color: #fff;
}
.title-content-tab {
  color: #36037d;
  font-weight: 600;
}
</style>
