import advertising_banner from "@/api/advertising_banner";
export default {
  namespaced: true,
  state: {
    banners: {},
    loading: false,
  },
  getters: {
    banners: (state) => {
      return state.banners;
    },
    loading: (state) => {
      return state.loading;
    },
  },
  mutations: {
    BANNERS: (state, payload) => {
      state.banners[payload.position] = payload.data;
    },
    SET_LOADING: (state, value) => {
      state.loading = value;
    },
  },
  actions: {
    async getAllBanners({ commit }, position) {
      commit("SET_LOADING", true);
      try {
        const res = await advertising_banner.getBanners(position);
        commit("BANNERS", { position: position, data: res.data.data });
        commit("SET_LOADING", false);
        return res;
      } catch (error) {
        commit("SET_LOADING", false);
        throw error;
      }
    },
  },
};
