<script>
export default {
  name: "LeaveChatButtonComponent",
  props: {
    isJoin: { type: Boolean, default: false },
  },
  methods: {
    leaveChannel: function () {
      this.$parent.leaveChannel();
    },
  },
};
</script>

<template>
  <button class="btn btn-danger" v-if="isJoin" @click.stop="leaveChannel">
    <img
      src="../../../assets/icon/live/leave-white.svg"
      style="color: #fff"
      alt="send"
    />
    Leave
  </button>
</template>

<style scoped></style>
