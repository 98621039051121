<template>
  <div class="position-relative pl-4 padding-icon">
    <img
      class="toggle-search svg cursor-pointer"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      src="../../assets/icon/cs-search.svg"
      style="width: 21px"
      @click.prevent="show = !show"
    />
    <div class="container">
      <div
        id="navbar-search"
        aria-labelledby="toggle-search"
        class="dropdown-menu mgalat position-absolute w-100 bg-search rounded search-box-result"
      >
        <div id="SearchAndFeatherBox" class="border-1 border-bottom p-2">
          <div
            class="search-box d-flex justify-content-between align-items-center border-color p-1 w-100 rounded"
          >
            <input
              type="search"
              v-model="keyword"
              @keyup="handelSearch"
              class="border-0 bg-transparent w-100 search-input"
              placeholder="بحث"
            />
            <img
              class="svg"
              v-show="!isLoading"
              src="../../assets/icon/cs-search.svg"
            />
            <span
              v-show="isLoading"
              class="spinner-grow-sm spinner-border"
            ></span>
          </div>
          <div
            id="course-list"
            class="my-2"
            v-show="
              (result.webinars === undefined ||
                Object.keys(result.webinars).length <= 0) &&
              popular_courses !== undefined &&
              popular_courses.length > 0
            "
          >
            <router-link
              v-for="(popular_course, popular_course_index) in popular_courses"
              :key="popular_course_index"
              :to="{
                name: 'course',
                params: { slug: popular_course.webinar_slug },
              }"
              class="p-2 m-1 btn fs-12 border-1 rounded grey-text"
            >
              {{ popular_course.title }}
            </router-link>
          </div>
        </div>
        <div @click.stop>
          <ul
            @click.prevent="show = false"
            class="p-0 my-0 mx-2"
            v-if="
              result.webinars !== undefined &&
              Object.keys(result.webinars).length > 0
            "
          >
            <li
              class="p-2 rounded bg-white shadow-sm mb-2"
              v-for="(webinar, webinar_index) in result.webinars"
              :key="webinar_index"
            >
              <router-link
                :to="{ name: 'course', params: { slug: webinar.slug } }"
                class="d-flex justify-content-between align-items-center gap-3"
              >
                <div
                  class="image-list rounded"
                  :style="{ 'background-image': `url(${webinar.poster})` }"
                ></div>
                <div class="fw-bold flex-fill">
                  <p class="text-truncate">
                    {{ webinar.title }}
                  </p>
                  <h1 class="h6 fs-10 text-truncate" style="font-size: 13px">
                    {{ webinar.teacher.full_name }}
                  </h1>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                  ></path>
                </svg>
              </router-link>
            </li>
          </ul>
          <ul
            @click.prevent="show = false"
            class="p-0 my-0 mx-2"
            v-if="
              result.teachers !== undefined &&
              Object.keys(result.teachers).length > 0
            "
          >
            <li
              class="p-2 rounded bg-white shadow-sm my-2"
              v-for="(teacher, teacher_index) in result.teachers"
              :key="teacher_index"
            >
              <router-link
                class="d-flex justify-content-between align-items-center gap-3"
                :to="{ name: 'instructor', params: { id: teacher.id } }"
              >
                <div
                  class="image-list rounded"
                  :style="{ 'background-image': `url(${teacher.avatar})` }"
                ></div>

                <div class="fw-bold flex-fill text-start">
                  <h1 class="text-truncate" style="font-size: 13px">
                    {{ teacher.full_name }}
                  </h1>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                  ></path>
                </svg>
              </router-link>
            </li>
          </ul>

          <ul
            class="p-0 my-0 mx-2"
            id="showCategoryList"
            v-if="!isNotHaveValue()"
          >
            <li
              class="py-3 px-0"
              v-for="(category, category_index) in categories"
              :key="category_index"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="fw-bold cursor-pointer grey-text category-title"
                  @click.prevent="showCoursesByCategory(category)"
                  >{{ category.title }}</span
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                  ></path>
                </svg>
              </div>
            </li>
          </ul>
          <div v-if="showCourseCategoryList">
            <div
              class="d-flex justify-content-between align-items-center rounded my-1 mx-3 p-2"
              style="background-color: var(--nav-active-1)"
            >
              <h1 class="m-0 p-0 h6 text-white text-truncate course-title">
                {{ selectCategoryTitle }}
              </h1>
              <img
                class="cursor-pointer"
                @click.prevent="closeSubCourseCategory()"
                src="../../assets/icon/cs-exit.svg"
              />
            </div>
            <div class="text-center">
              <small-spring-loading
                class="mx-auto py-2"
                :is-loading="loadingGetCourses"
              ></small-spring-loading>

              <ul
                class="p-0 my-0 mx-3"
                v-if="
                  courseCategoryList[this.selectCategoryId] !== undefined &&
                  courseCategoryList[this.selectCategoryId].length > 0
                "
              >
                <li
                  class="p-2 rounded bg-white my-2"
                  v-for="(course, course_index) in courseCategoryList[
                    this.selectCategoryId
                  ]"
                  :key="course_index"
                >
                  <router-link
                    :to="{ name: 'course', params: { slug: course.slug } }"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div
                        class="image-list rounded"
                        :style="{ 'background-image': `url(${course.image})` }"
                      ></div>

                      <div class="fw-bold text-start mx-1">
                        <h1
                          class="text-truncate course-title"
                          style="font-size: 13px"
                        >
                          {{ course.title | truncate(15, "...") }}
                        </h1>
                        <h1
                          class="fs-10 text-truncate course-teacher"
                          style="font-size: 12px"
                        >
                          {{ course.teacher.full_name }}
                        </h1>
                      </div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                      ></path>
                    </svg>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SmallSpringLoading from "@/components/Helper/SmallSpringLoading";
import course_request from "@/api/course_request";

export default {
  name: "NavSearch",
  components: { SmallSpringLoading },
  data() {
    return {
      keyword: "",
      show: false,

      isLoading: false,
      loadingGetCourses: true,
      showCourseCategoryList: false,
      selectCategoryTitle: "",
      courseCategoryList: [],
      selectCategoryId: 0,
    };
  },
  computed: {
    ...mapGetters("search", ["result"]),
    ...mapGetters("home", ["categories", "popular_courses"]),
  },
  methods: {
    ...mapActions("search", ["Search"]),
    ...mapActions("home", ["getPopularCourses", "getCategories"]),

    handelSearch: function () {
      if (this.keyword.length >= 3) {
        this.isLoading = true;
        this.Search(this.keyword).finally(() => {
          this.isLoading = false;
        });
      }
    },
    isNotHaveValue() {
      return (
        (this.result.teachers !== undefined &&
          Object.keys(this.result.teachers).length > 0) ||
        (this.result.webinars !== undefined &&
          Object.keys(this.result.webinars).length > 0) ||
        this.showCourseCategoryList === true
      );
    },
    showCoursesByCategory(category) {
      document.getElementById("showCategoryList").classList.add("hide");
      document.getElementById("SearchAndFeatherBox").classList.add("hide");
      this.selectCategoryTitle = category.title;
      this.showCourseCategoryList = true;
      this.loadingGetCourses = true;
      this.selectCategoryId = category.id;
      course_request
        .fetchAll(0, category.id, 10, true)
        .then((response) => {
          if (response.status === 200 && response.data.success === true) {
            if (!this.courseCategoryList[category.id]) {
              this.courseCategoryList[category.id] = new Array();
            }
            this.courseCategoryList[category.id] = response.data.data;
          }
        })
        .finally(() => {
          this.loadingGetCourses = false;
        });
    },
    closeSubCourseCategory() {
      document.getElementById("showCategoryList").classList.remove("hide");
      document.getElementById("SearchAndFeatherBox").classList.remove("hide");

      this.showCourseCategoryList = false;
      this.loadingGetCourses = false;
      //this.courseCategoryList = [];
    },
  },
  created() {
    if (Object.keys(this.popular_courses).length <= 0) {
      this.getPopularCourses();
    }
    if (Object.keys(this.categories).length <= 0) {
      this.getCategories();
    }
  },
  mounted() {
    document.getElementById("app").addEventListener("click", () => {
      //alert("add div");
    });
    document
      .getElementById("navbar-search")
      .addEventListener("click", function (e) {
        e.stopPropagation();
      });
  },
  watch: {
    $route() {
      document.getElementById("navbar-search").classList.remove("show");
    },
  },
};
</script>

<style scoped>
#navbar-search {
  max-width: 600px;
  width: 335px !important;
  top: 30px !important;
  right: -210px;
}
.category-title {
  font-size: 13px;
}
@media (max-width: 768px) {
  #navbar-search {
    top: 53px !important;
    width: 200px !important;
  }
}
.bg-search {
  background-color: #fff;
}
.dark-theme .bg-search {
  background-color: #1c1d1f;
}
a.btn {
  /*border: var(--nav-border) solid 1px !important;*/
  border: gray solid 1px !important;
}
.svg {
  /*color: var(--nav-border) !important;*/
  color: gray !important;
  text-decoration: none;
}
a,
svg {
  /*color: var(--nav-border) !important;*/
  color: gray !important;
  fill: gray !important;
  text-decoration: none;
}
svg {
  min-width: 24px !important;
}
ul {
  list-style: none;
}
li:hover a,
li:hover svg {
  color: var(--nav-border) !important;
}
.border-color {
  border: gray solid 1px;
}
#course-list a {
  text-align: right !important;
}
#course-list a:hover,
#course-list .active {
  background-color: gray;
  color: #fff !important;
}
.search-box-result {
  max-height: 300px;
  overflow: auto;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.image-list {
  width: 85px !important;
  height: 85px !important;
  background-position: center !important;
  background-size: cover !important;
  margin-left: 5px;
}
.search-input {
  color: grey !important;
  outline: none;
}
.dropdown-menu::after {
  background-color: var(--nav-dropdown-bg);
}
.course-title {
  font-size: 12px;
  color: gray !important;
}
.course-teacher {
  font-size: 11px;
  color: gray !important;
}
.toggle-search {
  width: 30px;
}
.padding-icon {
  padding-left: 5px;
}
@media only screen and (max-width: 600px) {
  .toggle-search {
    width: auto !important;
  }
  .fs-sm-12 {
    font-size: 15px !important;
  }
  #navbar-search {
    max-width: 100%;
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
  .dropdown-menu.mgalat {
    max-width: 100%;
    width: 100% !important;
    z-index: 22222;
  }

  #navbar-search::after {
    right: 67% !important;
  }
  .position-relative,
  .nav-item .nav-link {
    position: inherit !important;
  }
}
.dark-theme .bg-white {
  background-color: #1c1d1f !important;
  color: #fff;
}
.text-truncate {
  overflow: unset !important;
}
</style>
