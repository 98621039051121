import authHelper from "../../helpers/auth_helper";
import authRequest from "@/api/auth_request";
export default {
  namespaced: true,
  state: () => ({
    access_token: null,
    user: null,
    isLogin: false,
    countries: [],
    progress: "",
  }),

  getters: {
    access_token: (state) => {
      return state.access_token;
    },
    user: (state) => {
      return state.user;
    },
    userProgress: (state) => {
      return state.progress;
    },
    isLogin: (state) => {
      return state.isLogin;
    },
    countries: (state) => {
      return state.countries;
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.access_token = payload;
    },
    SET_IS_LOGIN(state, payload) {
      state.isLogin = payload;
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    SET_USER_PROGRESS(state, payload) {
      state.progress = payload;
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
  },
  actions: {
    async doResetPassword(_, payload) {
      //console.log("payload", payload);
      return await authRequest.resetPassword(payload.token, payload.form);
    },
    async getCountries({ commit }) {
      await authRequest.getCountries().then((res) => {
        const countries = res.data.data;
        commit("SET_COUNTRIES", countries);
      });
    },
    getAuthToken({ commit }, payload) {
      payload = authHelper.getAUthData();
      if (payload !== null || payload !== "") {
        commit("SET_TOKEN", payload);
      }
    },
    async sendForgetCode(_, payload) {
      return await authRequest.sendForgetCode(payload);
    },
    async updateProfile(_, payload) {
      return await authRequest.updateProfile(payload);
    },
    async getUser({ commit, state }, payload) {
      if (payload) {
        commit("SET_TOKEN", payload);
      }

      if (!state.access_token) {
        return;
      }
      await authRequest
        .getUser(payload)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_USER", response.data.data.user);
            commit("SET_USER_PROGRESS", response.data.data);
            //commit("SET_TOKEN", response.data.api_token);
            commit("SET_IS_LOGIN", true);
          } else {
            commit("SET_TOKEN", null);
            commit("UPDATE_USER", null);
            commit("SET_IS_LOGIN", false);
          }
          return response;
        })
        .catch((error) => {
          //console.log("error", error);
          return error;
        });
    },
    async changePassword(_, payload) {
      return await authRequest.changePassword(payload);
    },
    async register(_, payload) {
      return await authRequest.register(payload);
    },
    async login({ commit }, payload) {
      try {
        let response = await authRequest.login(payload);
        if (response.status === 200 && response.data.success === true) {
          commit("UPDATE_USER", response.data.data.user);
          commit("SET_TOKEN", response.data.data.token);
          commit("SET_IS_LOGIN", true);
        }
        return response;
      } catch (e) {
        commit("UPDATE_USER", null);
        commit("SET_TOKEN", null);
        commit("SET_IS_LOGIN", false);
        return e;
      }
    },
    logOut({ commit }) {
      authHelper.logOut();
      commit("UPDATE_USER", null);
      commit("SET_TOKEN", "");
      commit("SET_IS_LOGIN", false);
      // window.location.reload();
    },
  },
};
